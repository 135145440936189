import React from 'react';
import PropTypes from 'prop-types';
import classes from './Backdrop.module.scss';

export default function Backdrop({ show, onClickHandler, desktop, ...props }) {
  return show ? (
    <div
      data-testid="backdrop"
      className={`${classes.Backdrop} ${desktop ? classes.desktop : ''}`}
      onClick={onClickHandler}
      onKeyUp={() => {}}
      role="button"
      tabIndex={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : null;
}

Backdrop.propTypes = {
  onClickHandler: PropTypes.func,
  show: PropTypes.bool,
  desktop: PropTypes.bool,
};

Backdrop.defaultProps = {
  onClickHandler: () => {},
  show: false,
  desktop: false,
};
