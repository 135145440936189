import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import classes from './Slide.module.scss';
import { useBodyScrollControl } from '../../utils/hooks';

import arrow from './arrow.png';

const propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onHide: PropTypes.func,
  onSlideShow: PropTypes.func,
  onSlideHide: PropTypes.func,
  renderHeaderContent: PropTypes.func,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
  stopBlockingScroll: PropTypes.bool,
};

const defaultProps = {
  show: false,
  direction: 'right',
  children: '',
  onHide: null,
  renderHeaderContent: () => {},
  onSlideShow: () => {},
  onSlideHide: () => {},
  className: '',
  stopBlockingScroll: false,
};

const SlideContainer = ({
  show,
  children,
  onHide,
  onSlideShow,
  onSlideHide,
  renderHeaderContent,
  className,
  direction,
  stopBlockingScroll,
}) => {
  const [firstRender, setFirstRender] = useState(true);
  const { lockScroll, unlockScroll } = useBodyScrollControl();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    setTouchStart(0);
    setTouchEnd(0);
    if (!onHide) return;
    if (touchStart - touchEnd < -150) {
      onHide();
    }
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    if (show) {
      onSlideShow();
      if (!stopBlockingScroll) lockScroll();
    }

    if (!show) {
      onSlideHide();
      if (!stopBlockingScroll) unlockScroll();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [show]);

  return (
    <div
      data-testid="slide"
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
      className={clsx([
        classes.Slide,
        className,
        show && classes.show,
        direction === 'left' && classes.left,
      ])}
    >
      <div data-testid="slide-header" className={classes.header}>
        {onHide && (
          <button
            type="button"
            onClick={onHide}
            className={`btn btn-icon white ${classes.back}`}
            data-testid="btn-hide"
          >
            <img src={arrow} alt="arrow" />
          </button>
        )}
        {renderHeaderContent()}
      </div>
      <div>{show && children}</div>
    </div>
  );
};

SlideContainer.propTypes = propTypes;
SlideContainer.defaultProps = defaultProps;

export default SlideContainer;
