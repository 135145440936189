import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Education.module.scss';
import {
  Form,
  Select,
  DatePicker,
  Input,
  AutoComplete,
  Button,
  message,
} from 'antd';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_UNIVERSITY } from '../../../../graphql/universities';
import { saveEducation } from '../../Register.thunks';

export default function Education({
  setCurrentStep,
  setStatus,
  isLoading,
  skipStep,
  setSectionStep,
}) {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [result, setResult] = useState('');
  const [getUniversities, { loading, error, data }] =
    useLazyQuery(SEARCH_UNIVERSITY);

  const onSearch = (changeText) => {
    try {
      let res = [];
      if (
        typeof (
          data &&
          data.searchUniversity &&
          data.searchUniversity.universities
        ) !== 'undefined'
      ) {
        setResult(
          (
            data &&
            data.searchUniversity &&
            data.searchUniversity.universities
          ).substring(
            1,
            (
              data &&
              data.searchUniversity &&
              data.searchUniversity.universities
            ).length - 1
          )
        );
        let nose = result.replace(/"/g, '');
        res = nose.split(',');
      }
      setOptions(!changeText ? [] : res);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (data) => {
    setValue(data);
    const searchUniversityInput = {
      name: data,
    };
    getUniversities({ variables: { input: searchUniversityInput } });
  };

  const onFinish = async (values) => {
    try {
      setStatus('pending');

      await dispatch(saveEducation(values)).unwrap();

      setCurrentStep((prev) => prev + 1);
      setSectionStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  if (loading) {
    console.log('loading');
  }

  if (error) {
    console.log('error: ', error.message);
  }

  return (
    <div className={styles.Education}>
      <h1 className="stepTitle">
        {registerData?.givenName}, become a verified entertainment industry
        professional
      </h1>
      <p className="stepIntro">
        Verifying increases your chances of ranking higher within our system.
        The higher your rank, the more relevant your connectivity,
        collaboration, and funding opportunities become.
      </p>
      <Form
        name="educationForm"
        className={styles.formWrapper}
        onFinish={onFinish}
      >
        <label htmlFor="recentSchool" className="formLabel">
          SCHOOL
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input your school!' }]}
          name="recentSchool"
        >
          <AutoComplete
            value={value}
            style={{ marginBottom: '1rem', width: '100%', height: '20%' }}
            onSearch={onSearch}
            onChange={onChange}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            placeholder="University"
          >
            {options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <label htmlFor="degree" className="formLabel">
          Degree
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input your degree!' }]}
          name="degree"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <label htmlFor="fieldOfStudy" className="formLabel">
          FIELD OF STUDY
        </label>
        <Form.Item
          rules={[
            { required: true, message: 'Please input your field of study!' },
          ]}
          name="fieldOfStudy"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <label htmlFor="educationDates" className="formLabel">
          FROM / TO
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input the date!' }]}
          name="educationDates"
        >
          <RangePicker
            picker="month"
            size="large"
            style={{ marginBottom: '1rem', width: '100%' }}
          />
        </Form.Item>

        <Button
          className="skipBtn"
          disabled={isLoading}
          type="primary"
          shape="round"
          onClick={() => skipStep()}
        >
          Skip
        </Button>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
