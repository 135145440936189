import React from 'react';
import style from './AboutUs.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import BottomCard from './BottomCard/BottomCard';
import CorpNav from '../CorpNav/CorpNav';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';

import ProfileDG1 from './img/DG1.png';
import ProfileDG2 from './img/DG2.jpg';
import ProfileJV from './img/JV.png';
import ProfileCH from './img/CH.png';
import ProfileEM from './img/EM.jpg';
import ProfileCE from './img/CE.jpg';
import ProfileGM from './img/GM.jpg';
import ProfileJM from './img/JM.jpg';
import ProfileJM2 from './img/JM2.jpg';

import PlaceHolder from './img/Placeholder.png';

import couchConversation from './img/couchConversation.png';
import couchConversation2 from './img/couchConversation2.png';
import { Link } from 'react-router-dom';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';

const AboutUs = () => {
  const { t } = useTranslation('about');
  return (
    <>
      <div className={style.aboutContainer}>
        <CorpNav title="Why Linkrease?" />
        <Row className={style.initialContainer} justify="center">
          <Col span={18}>
            <div className={style.titleContainer}>
              <p className={style.mainText}>
                {t(
                  'Where aspirational and professional talent team up to bring entertainment projects to life.'
                )}
              </p>
              <p className={style.subHeader}>
                {t(
                  'We’re on a mission to help every creator in the world monetize their creativity by giving them access to a relevant network of entertainment professionals, collaboration tools, and funding solutions.'
                )}
              </p>
            </div>
            <Link to="/register-vertical">
              <Button
                className={style.button}
                block
                type="primary"
                shape="round"
                size="large"
              >
                {t('Get Started')}
              </Button>
            </Link>
          </Col>
        </Row>

        <Row justify="space-around">
          <Col span={6}>
            <div className={style.center}>
              <div className={style.circle}>
                <p className={style.subHeader}>$16.4 bn</p>
                <p className={style.innerText}>{t('OTT video revenue')}</p>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className={style.center}>
              <div className={style.circle}>
                <p className={style.subHeader}>$11.1 bn</p>
                <p className={style.innerText}>
                  {t('Cinema box office revenue')}
                </p>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className={style.center}>
              <div className={style.circle}>
                <p className={style.subHeader}>+43.3%</p>
                <p className={style.innerText}>
                  {t('YOY increase of VR video revenue')}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className={style.circleContainer}>
          <Row justify="center">
            <Col span={5} pull={2}>
              <div className={style.center}>
                <div className={style.circle}>
                  <p className={style.subHeader}>$281 m</p>
                  <p className={style.innerText}>
                    {t('eSports market revenue')}
                  </p>
                </div>
              </div>
            </Col>
            <Col span={5} push={2}>
              <div className={style.center}>
                <div className={style.circle}>
                  <p className={style.subHeader}>$627.trn</p>
                  <p className={style.innerText}>
                    {t('Megabytes of data consumed')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Row className={style.rowSeparation} justify="center">
          <Col span={12}>
            <div className={style.textColumn}>
              <p className={`${style.contentHeader} ${style.left}`}>
                {t('Break into the entertainment industry')}
              </p>
              <p className={`${style.contentText} ${style.left}`}>
                {t(
                  'For those who aspire to break into any vertical of the entertainment industry, Linkrease increases their chances of success by providing exposure to an engaged and relevant audience of vetted entertainment professionals and influencers who are looking to bring their good name, industry cred and know-how to an entertainment project deemed as having good potential to become viable. The synergy of creativity and know-how allows the newly formed team to collaborate and fine-tune the project to access our funding marketplace, where eligible campaigns can crowd-fund pre-production funds, access alternative funding sources, reach a distribution or licensing deal or a combination of all three.'
                )}
              </p>
            </div>
          </Col>
          <Col span={10}>
            <img
              className={style.imgFloatRight}
              src={couchConversation}
              alt=""
            />
          </Col>
        </Row>

        <Row className={style.rowSeparation} justify="center">
          <Col span={10}>
            <img
              className={style.imgFloatLeft}
              src={couchConversation2}
              alt=""
            />
          </Col>
          <Col span={12}>
            <div className={style.textColumn}>
              <p className={`${style.contentHeader} ${style.left}`}>
                {t('For entertainment professionals and Influencers')}
              </p>
              <p className={`${style.contentText} ${style.left}`}>
                {t(
                  'For entertainment professionals or industry influencers, Linkrease increase their deal-flow by providing exposure to “raw projects”, many of which have the potential to become successful if the “right” players or talent comes along. By leveraging your name, reputation and know-how you have the ability to impact the viability of the project you decide to attach your name to and better position it to access our funding marketplace, where eligible campaigns can crowd-fund pre-production funds, access alternative funding sources, reach a distribution or licensing deal or a combination of all three.'
                )}
              </p>
            </div>
          </Col>
        </Row>
        <div className={style.teamContainer}>
          <Row id="leadership" className={style.sectionDivider}>
            <Col span={24}>
              <p className={style.mainText}>{t('Leadership Team')}</p>
              <p className={style.subHeader}>
                {t('Meet the leadership team behind Linkrease')}
              </p>
            </Col>
          </Row>

          <Row className={style.teamRow} justify="center">
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Dante Grasso"
                  title={t('Co-Founder & CEO')}
                  link="https://www.linkedin.com/in/dantegrasso/"
                  image={ProfileDG2}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Diego García"
                  title={t('Co-Founder & COO')}
                  link="https://www.linkedin.com/in/dgarcia2004/"
                  image={ProfileDG1}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Jairo Velásquez"
                  title={t('Software Engineer & CTO')}
                  link="https://www.linkedin.com/in/jairodavidvelasquez/"
                  image={ProfileJV}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Raul Argueta"
                  title={t('Software Engineer')}
                  link="https://www.linkedin.com/in/raul-argueta-a33ab3124/"
                  image={PlaceHolder}
                />
              </div>
            </Col>
          </Row>

          <Row className={style.teamRow} justify="center">
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Carlos Escobar"
                  title={t('Software Engineer')}
                  link=""
                  image={ProfileCE}
                />
              </div>
            </Col>

            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Chris Hernandez"
                  title={t('Head of Front End Development')}
                  link="https://www.linkedin.com/in/christian-hern%C3%A1ndez-372a22129/"
                  image={ProfileCH}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Gilberto Medina"
                  title={t('Front End Engineer')}
                  link="https://www.linkedin.com/in/gilbertormedina/"
                  image={ProfileGM}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Jose Martinez"
                  title={t('Front End Engineer')}
                  link=""
                  image={ProfileJM2}
                />
              </div>
            </Col>
          </Row>

          <Row className={style.teamRow} justify="center">
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Eduardo Mejía"
                  title={t('Front End Engineer')}
                  link=""
                  image={ProfileEM}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className={style.centerBottomCard}>
                <BottomCard
                  name="Junaiz Mohammed"
                  title={t('UX/UI Designer')}
                  link="https://www.linkedin.com/in/junaizmohammed/"
                  image={ProfileJM}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className={style.landingFooterContainer}>
          <LandingFooter />
          <PrivacyTermsFooter />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
