import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, Button } from 'antd';
import SquareElementsWithTitle from '../../components/SquareElementsWithTitle/SquareElementsWithTitle';
import styles from './VerticalSelector.module.scss';
import { setVertical } from '../../Register.slice';
import RegisterHeader from '../../components/RegisterOuterWrapper/RegisterHeader/RegisterHeader';
import CompactFooter from '../../../../components/PrivacyTermsFooter/PrivacyTermsFooter';

import guitarSVG from '../../../../img/vertical/guitar.svg';
import controlSVG from '../../../../img/vertical/game-control.svg';
import filmSVG from '../../../../img/vertical/film-roll.svg';
import ballSVG from '../../../../img/vertical/ball-brazilian.svg';

const data = [
  { value: 'film', text: 'Film', iconType: filmSVG },
  { value: 'sports', text: 'Sports', iconType: ballSVG },
  { value: 'music', text: 'Music', iconType: guitarSVG },
  { value: 'gaming', text: 'Gaming', iconType: controlSVG },
];

const CheckBoxComp = () => {
  const { vertical } = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(setVertical(e.target.value));
  };
  return (
    <>
      <RegisterHeader />
      <div className={styles.container}>
        <div className={styles.intro}>
          <h1 className="stepTitle">Your entertainment career starts here</h1>
          <p className="stepIntro">What’s your entertainment vertical?</p>
        </div>

        <Radio.Group
          className={styles.radioGroup}
          onChange={onChange}
          defaultValue="a"
        >
          {data.map((radioElement, index) => {
            return (
              <Radio.Button
                key={index}
                className={styles.button}
                value={radioElement.value}
              >
                <SquareElementsWithTitle text={radioElement.text}>
                  {/* {React.createElement(IconAntd[radioElement.iconType], {
                  className: styles.icon,
                })} */}
                  <img
                    src={radioElement.iconType}
                    alt=""
                    className={styles.icon}
                  />
                </SquareElementsWithTitle>
              </Radio.Button>
            );
          })}
        </Radio.Group>
        <div className={styles.continueContainer}>
          <Link to="/register-path">
            <Button
              disabled={!vertical}
              className="continueBtn"
              htmlType="submit"
              type="primary"
              shape="round"
            >
              Continue
            </Button>
          </Link>
        </div>
      </div>
      <CompactFooter />
    </>
  );
};

export default CheckBoxComp;
