import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Backdrop from '../../Backdrop/Backdrop';
import classes from './CropImageModal.module.scss';
import { useBodyScrollControl } from '../../../utils/hooks';
import CropImage from '../Crop';

const propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  image: PropTypes.string,
  cropSettings: PropTypes.shape(),
};

const defaultProps = {
  show: false,
  onCancel: () => {},
  onAccept: () => {},
  image: null,
  cropSettings: {},
};

const CropModal = ({ show, onCancel, onAccept, image, cropSettings }) => {
  const { lockScroll, unlockScroll } = useBodyScrollControl();
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    if (show) {
      lockScroll();
    } else {
      unlockScroll();
    }
   //eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [show]);

  const handleAccept = () => {
    onAccept(croppedImage || image);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleCrop = (img) => {
    setCroppedImage(img);
  };

  return show ? (
    <div data-testid="cropModal" className={clsx([classes.ModalWrapper])}>
      <Backdrop
        show
        desktop
        onClickHandler={handleCancel}
        style={{ position: 'absolute' }}
        data-testid="unlock-content-backdrop"
      />
      <div className={clsx([classes.ModalContainer])}>
        <h3 className={clsx([classes.ModalTitle])}>Crop photo</h3>
        <CropImage
          src={image}
          onCrop={handleCrop}
          ratio={cropSettings.ratio}
          rotatable={cropSettings.rotatable}
          cropBoxResizable={cropSettings.cropBoxResizable}
        />
        <div className={clsx([classes.ModalActions])}>
          <button
            data-testid="cropModalAccept"
            type="button"
            onClick={handleAccept}
            className={clsx([
              'btn btn-orange',
              classes.AcceptBtn,
              classes.ModalBtn,
            ])}
          >
            Accept
          </button>
          <button
            data-testid="cropModalCancel"
            type="button"
            onClick={handleCancel}
            className={clsx(['btn', classes.CancelBtn, classes.ModalBtn])}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

CropModal.propTypes = propTypes;
CropModal.defaultProps = defaultProps;

export default CropModal;
