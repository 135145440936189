import { createSlice } from '@reduxjs/toolkit';
import { getLoggedUser, signIn, signUp } from './Session.thunks';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    status: 'idle',
    error: null,
    undoStack: {},
    userData: {},
    isAuthenticated: null,
  },
  reducers: {
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getLoggedUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getLoggedUser.fulfilled, (state, { payload }) => {
        state.status = 'done';

        state.userData = payload;
        state.isAuthenticated = true;
      })
      .addCase(getLoggedUser.rejected, (state) => {
        state.status = 'rejected';
        state.isAuthenticated = false;
      })

      .addCase(signIn.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(signIn.fulfilled, (state) => {
        state.status = 'pending';

        state.isAuthenticated = true;
      })
      .addCase(signIn.rejected, (state) => {
        state.status = 'rejected';
        state.isAuthenticated = false;
      })

      .addCase(signUp.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(signUp.fulfilled, (state) => {
        state.status = 'done';

        state.isAuthenticated = true;
      })
      .addCase(signUp.rejected, (state) => {
        state.status = 'rejected';
        state.isAuthenticated = false;
      }),
});

export const { setAuthenticated } = sessionSlice.actions;

export default sessionSlice.reducer;
