import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import classes from './LastNotifications.module.scss';
import NotificationList from '../NotificationList/NotificationList';

export default function LastNotifications({
  className,
  data,
  setDrawerVisible,
}) {
  const [todayList, setTodayList] = useState([]);
  const [weekList, setWeekList] = useState([]);

  const filterNotificationsByDate = (notifications) => {
    const today = [];
    const week = [];

    notifications.filter((group) => {
      const testDateUtc = moment.utc(group.updated_at);
      const localDate = moment(testDateUtc).local();
      const isToday = moment().isSame(localDate, 'day');

      return isToday ? today.push(group) : week.push(group);
    });
    setTodayList(today);
    setWeekList(week);
  };

  useEffect(() => {
    filterNotificationsByDate(data);
  }, [data]);

  return (
    <div className={clsx([classes.LastNotifications, className])}>
       <NotificationList
        data={todayList}
        badgeText="New"
        setDrawerVisible={setDrawerVisible}
      />
      {weekList.length ? (
        <NotificationList
          data={weekList}
          badgeText="Last Week"
          setDrawerVisible={setDrawerVisible}
        />
      ) : null} 
    </div>
  );
}

LastNotifications.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  setDrawerVisible: PropTypes.func,
};

LastNotifications.defaultProps = {
  className: '',
  data: null,
  setDrawerVisible: () => {},
};
