import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './NotFound.module.scss';
import Logo from '../../img/newLogo.svg';
import { Button } from 'antd';

const NotFound = ({ errorType = '404' }) => {
  const { t } = useTranslation('errors');
  return (
    <div className={`${Styles.container}`}>
      <img src={Logo} className={`${Styles.logo}`} alt="logo" />
      <h1 className={`${Styles.errorHeader}`}>{errorType}</h1>
      <p className={`${Styles.errorText}`}>{t(errorType)}</p>
      <Button className={`${Styles.homeButton}`} href="/" shape="round">
        GO HOME
      </Button>
      <hr className={`${Styles.bottomDivider}`} />
      <p className={`${Styles.copyright}`}>COPYRIGHT © 2020 LINKREASE</p>
    </div>
  );
};

export default NotFound;
