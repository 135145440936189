import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import styles from './RegisterHeader.module.scss';
import WhiteLogoSVG from '../../../../../img/newLogo.svg';


const RegisterHeader = () => (
  <div className={styles.container}>
    <Row className={styles.rowContainer} align="middle">
      <Col span={4}>
        <Link to="/">
          <img
            className={`${styles.headerLogo}`}
            src={WhiteLogoSVG}
            alt="Linkrease logo"
          />
        </Link>
      </Col>
    </Row>
  </div>
);

export default RegisterHeader;
