import React from 'react';
import style from "./ContentComponents.module.scss";


const SettingHeader= ({ mainText, subText }) => {
	return (
    <div className={style.panelHeader}>
      <p className={style.panelHeading}>{mainText}</p>
      <p className={style.panelContent}>{subText}</p>
    </div>
	)
};

export default SettingHeader;