import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Dashboard.module.scss';
import LandingFooter from '../../components/Landing/Footer/Footer';
import PrivacyTermsFooter from '../../components/PrivacyTermsFooter/PrivacyTermsFooter';
import { Row, Col } from 'antd';
import Profile from '../../components/Dashboard/Profile/Profile';
import Feed from '../../components/Dashboard/Feed/Feed';
import Connections from '../../components/Dashboard/Connections/Connections';
import ContentCreation from '../../components/Dashboard/ContentCreation/ContentCreation';
import DisplayCategory from '../../components/Dashboard/DisplayCategory/DisplayCategory';
import TrendingCampaign from '../../components/Dashboard/TrendingCampaign/TrendingCampaign';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import SearchBar from '../../components/Dashboard/SearchBar/SearchBar';
import Ads from '../../components/Dashboard/Ads/Ads';
import CreatePanel from '../../components/Dashboard/CreatePanel/CreatePanel';
import CustomSkeleton from '../../components/Skeleton/Skeleton';

const renderDashboard = () => {
  return (
    <Row justify="center" gutter={[24, 24]}>
      <Col xs={24} sm={24} md={8} lg={5}>
        <div className={styles.leftColumn}>
          <p>
            <Profile />
          </p>
          <Connections />
          <DisplayCategory title="My teams" />
          <DisplayCategory title="My events" />
        </div>
      </Col>

      <Col xs={20} sm={20} md={20} lg={12}>
        <ContentCreation />
        <Feed />
      </Col>

      <Col xs={24} sm={12} md={8} lg={5}>
        {/* <Button
          block
          className={styles.premiumButton}
          type="primary"
          shape="round"
        >
          GO PREMIUM
        </Button> */}
        <div className={styles.rightColumn}>
          <TrendingCampaign />
          {/* <Messages /> */}
          <Ads />
        </div>
      </Col>
    </Row>
  );
};

export default function Dashboard() {
  const history = useHistory();
  const { status: sessionStatus, isAuthenticated } = useSelector(
    (state) => state.session
  );

  if (!isAuthenticated && sessionStatus === 'rejected') {
    history.push('/login');
  }
  if (sessionStatus === 'loading') {
    return <CustomSkeleton />;
  }

  return (
    <div className={styles.Dashboard}>
      <header>
        <LoggedInHeader />
        <SearchBar />
      </header>
      {/* <div className={styles.ruler}>Ruler</div> */}
      <main>
        <Switch>
          <Route path="/dashboard/create" component={CreatePanel} />
          <Route path="/" component={renderDashboard} />
        </Switch>
      </main>
      <footer className={styles.landingFooterContainer}>
        <LandingFooter />
        <div className={styles.footer}>
          <PrivacyTermsFooter />
        </div>
      </footer>
    </div>
  );
}
