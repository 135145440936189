import React, { useState } from 'react';
import Styles from './Register.module.scss';
import { Steps } from 'antd';

import {
  SetupAccount,
  Greeting,
  Location,
  JoiningReasons,
  DescriberModal,
  LinkedInVerification,
  Background,
  Education,
  Award,
  Portfolio,
  ProfilePicture,
  EmailVerification,
  Confirmation,
} from './steps';

import ReCAPTCHA from 'react-google-recaptcha';
import CircularSpinner from '../../components/Common/Spinners/CircularSpinner';

const { Step } = Steps;

const steps = [
  {
    title: 'Profile',
    content: 'First-content',
  },
  {
    title: 'Vertical',
    content: 'Second-content',
  },
  {
    title: 'Portfolio',
    content: 'Third-content',
  },
  {
    title: 'Rank',
    content: 'Fourth-content',
  },
  {
    title: 'Complete',
    content: 'Last-content',
  },
];

const recaptchaRef = React.createRef();

const Register = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [status, setStatus] = useState('idle');
  const [currentSectionStep, setSectionStep] = useState(0);

  const skipStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  let pages = [
    <SetupAccount
      setCurrentStep={setCurrentStep}
      isLoading={status === 'pending'}
    />,
    <Greeting
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,
    <Location
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,
    <EmailVerification
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,
    <JoiningReasons
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,
    <LinkedInVerification
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,
    <Background
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
    />,

    <Education
      setSectionStep={setSectionStep}
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
      skipStep={skipStep}
    />,

    <Award
      setSectionStep={setSectionStep}
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
      skipStep={skipStep}
    />,

    <DescriberModal
      setSectionStep={setSectionStep}
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
      skipStep={skipStep}
    />,
    <ProfilePicture
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
      skipStep={skipStep}
    />,
    <Portfolio
      setCurrentStep={setCurrentStep}
      setStatus={setStatus}
      isLoading={status === 'pending'}
      skipStep={skipStep}
    />,
    <Confirmation />,
  ];

  return (
    <div className={Styles.container}>
      <div className="container">
        <Steps current={currentSectionStep} className={Styles.steps}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {pages[currentStep]}
      </div>
      <span className={Styles.spinnerContainer}>
        <CircularSpinner isShowing={status === 'pending'} />
      </span>
    </div>
  );
};

export default Register;
