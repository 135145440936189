import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, message } from 'antd';
import Styles from './Portfolio.module.scss';
import Icon from '@ant-design/icons';
import { savePortfolio } from '../../Register.thunks';
import { ReactComponent as WebsiteIcon } from './img/website.svg';
import { ReactComponent as ImdbIcon } from './img/imdb.svg';
import { ReactComponent as YoutubeIcon } from './img/youtube.svg';
import closeIcon from '../../../../img/close-x.svg';

const Portfolio = ({
  setCurrentStep,
  setStatus,
  isLoading,
  skipStep,
  setSectionStep,
}) => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const [values, setValues] = useState('');
  const [links, setLinks] = useState([]);
  const [count, setCount] = useState(0);
  const [invisibleW, setInvisibleW] = useState(false);
  const [invisibleI, setInvisibleI] = useState(false);
  const [invisibleY, setInvisibleY] = useState(false);

  const onDeleteClick = (key, type) => {
    const newList = links.filter((item) => {
      return item.id != key;
    });
    if (type == 'website') {
      setInvisibleW(false);
    } else if (type === 'Imdb') {
      setInvisibleI(false);
    } else {
      setInvisibleY(false);
    }
    setLinks(newList);
    setCount(count - 1);
  };

  const generateWebsiteItem = () => {
    setLinks([...links, { id: count, url: values, type: 'website' }]);
    setCount(count + 1);
    setInvisibleW(true);
  };

  const generateImdbItem = () => {
    setLinks([...links, { id: count, url: values, type: 'Imdb' }]);
    setCount(count + 1);
    setInvisibleI(true);
  };

  const generateYoutubeItem = () => {
    setLinks([...links, { id: count, url: values, type: 'Youtube' }]);
    setCount(count + 1);
    setInvisibleY(true);
  };

  const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
  };

  const onFinish = async (values) => {
    const { imdb, website, youtube } = values;
    try {
      setStatus('pending');

      await dispatch(
        savePortfolio({ portofolios: [imdb, website, youtube] })
      ).unwrap();

      setCurrentStep((prev) => prev + 1);
      setSectionStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  return (
    <div className={Styles.Portfolio}>
      <div className={Styles.intro}>
        <h1 className="stepTitle">
          {registerData?.givenName}, your entertainment portfolio <br /> helps
          you showcase your best work
        </h1>
      </div>
      <Form {...layout} name="portfolios" layout="vertical" onFinish={onFinish}>
        <Row gutter={8} align="middle">
          <Col span={1} offset={6}>
            <Icon
              className={`${Styles.logo} ${Styles.websiteColor}`}
              component={WebsiteIcon}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              className="formLabel"
              label="ADD WEBSITE"
              name="website"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Please input a website!',
                },
              ]}
            >
              <Input
                value={values}
                onChange={(e) => setValues(e.target.value)}
                placeholder="Enter Website URL"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Button
              disabled={invisibleW}
              className={Styles.linkButton}
              shape="round"
              onClick={() => {
                generateWebsiteItem();
              }}
            >
              LINK
            </Button>
          </Col>
        </Row>
        <Row gutter={8} align="middle">
          <Col span={12}>
            {links.map((item) =>
              item.type === 'website' ? (
                <div className={Styles.ResultItem} key={item.id}>
                  <div className={Styles.shape}></div>
                  <span>{item.url}</span>
                  <img
                    onClick={() => onDeleteClick(item.id, item.type)}
                    className={Styles.closeIcon}
                    src={closeIcon}
                    alt=""
                  />
                </div>
              ) : (
                <div key={item.id}></div>
              )
            )}
          </Col>
        </Row>

        <Row gutter={8} align="middle">
          <Col span={1} offset={6}>
            <Icon
              className={`${Styles.logo} ${Styles.imdbColor}`}
              component={ImdbIcon}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              className="formLabel"
              label="ADD Link"
              name="imdb"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Please input an imdb link!',
                },
              ]}
            >
              <Input
                value={values}
                onChange={(e) => setValues(e.target.value)}
                placeholder="Enter IMDB URL"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Button
              disabled={invisibleI}
              className={Styles.linkButton}
              shape="round"
              onClick={() => {
                generateImdbItem();
              }}
            >
              LINK
            </Button>
          </Col>
          <Col span={5}>
            {links.map((item) =>
              item.type === 'Imdb' ? (
                <div className={Styles.ResultItem} key={item.id}>
                  <div className={Styles.shape}></div>
                  <span>{item.url}</span>
                  <img
                    onClick={() => onDeleteClick(item.id, item.type)}
                    className={Styles.closeIcon}
                    src={closeIcon}
                    alt=""
                  />
                </div>
              ) : (
                <div key={item.id}></div>
              )
            )}
          </Col>
        </Row>

        <Row gutter={8} align="middle">
          <Col span={1} offset={6}>
            <Icon
              className={`${Styles.logo} ${Styles.youtubeColor}`}
              component={YoutubeIcon}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              className="formLabel"
              label="ADD Link"
              name="youtube"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Please input a youtube link!',
                },
              ]}
            >
              <Input
                value={values}
                onChange={(e) => setValues(e.target.value)}
                placeholder="Enter Username"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Button
              disabled={invisibleY}
              className={Styles.linkButton}
              shape="round"
              onClick={() => {
                generateYoutubeItem();
              }}
            >
              LINK
            </Button>
          </Col>
          <Col span={5}>
            {links.map((item) =>
              item.type === 'Youtube' ? (
                <div className={Styles.ResultItem} key={item.id}>
                  <div className={Styles.shape}></div>
                  <span>{item.url}</span>
                  <img
                    onClick={() => onDeleteClick(item.id, item.type)}
                    className={Styles.closeIcon}
                    src={closeIcon}
                    alt=""
                  />
                </div>
              ) : (
                <div key={item.id}></div>
              )
            )}
          </Col>
        </Row>

        <Button
          className="skipBtn"
          disabled={isLoading}
          type="primary"
          shape="round"
          onClick={() => skipStep()}
        >
          Skip
        </Button>
        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>

        {/* <Form.Item {...verifyBtnLayout}>
          <Button className={Styles.verifyButton} type="link">
            I will add later
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default Portfolio;
