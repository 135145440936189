import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'register',
  initialState: {
    status: 'idle',
    error: null,
    accountDetails: {
      email: '',
      password: '',
      givenName: '',
      familyName: '',
    },
    undoStack: {},
  },
  reducers: {
    setVertical: (state, { payload }) => {
      state.vertical = payload;
    },
    setCareerPath: (state, { payload }) => {
      state.careerPath = payload;
    },
    setAccountDetails: (state, { payload }) => {
      const { data } = payload;

      state.accountDetails = { ...state.accountDetails, ...data };
    },
    setBackground: (state, { payload }) => {
      state.background = payload;
    },
  },
});

export const { setVertical, setCareerPath, setAccountDetails, setBackground } =
  registerSlice.actions;

export default registerSlice.reducer;
