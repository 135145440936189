import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reasons } from './data';
import styles from './JoiningReasons.module.scss';
import { Form, Checkbox, Button, message } from 'antd';
import { saveUserJoiningReasons } from '../../Register.thunks';

export default function JoiningReasons({
  setCurrentStep,
  setStatus,
  isLoading,
}) {
  const dispatch = useDispatch();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const registerData = useSelector((state) => state.register?.accountDetails);

  function onChange(checkedValues) {
    setSelectedReasons(checkedValues);
  }

  const onFinish = async () => {
    try {
      setStatus('pending');

      await dispatch(
        saveUserJoiningReasons({ reasons: selectedReasons })
      ).unwrap();

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
      message.success('Saved!');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  return (
    <div className={styles.Reasons}>
      <div className={styles.intro}>
        <h1 className={styles.stepTitle}>
          {`Now, tell us why you you’re joining ${registerData?.givenName}.`}
        </h1>
        <p className={styles.stepIntro}>
          This information will help us tailor your opportunities for monetizing
          content.
        </p>
      </div>
      <Form
        name="joiningReasons"
        className={styles.formWrapper}
        onFinish={onFinish}
      >
        <Form.Item
          validateTrigger="onBlur"
          name="reasons"
          rules={[{ required: true, message: 'Make at least one selection' }]}
        >
          <Checkbox.Group
            options={reasons}
            defaultValue={['']}
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
