import React from 'react';
import SettingHeader from './SettingHeader';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { notifications, visibility } from '../accountSettingsValues';

let queryData = {};

export const handleCollapse = (params) => {
	const iconProperty = {
		style: {
			fontSize: "3rem",
			color: "#0438FA",
			marginRight: "2%",
		},
	};

	return !params.isActive ? (
		<PlusCircleFilled {...iconProperty} />
	) : (
		<MinusCircleFilled {...iconProperty} />
	);
};

export const headerRenderer = (params) => {
	const { mainText, subText } = params;
	return (<SettingHeader mainText={mainText} subText={subText}/>)
}

export function generateMemberAccountHeaders(data) {
	try {
		return [
			{
				mainText: 'Password',
				subText: '••••••••••••',
			}
			,{
				mainText: 'Email',
				subText: data.getUser.email,
			},{
				mainText: 'Mobile number​',
				subText: data.getUser.phones === null ? 'You currently don’t have a mobile number on file. ' : data.getUser.phones.number,
			},{
				mainText: 'Language',
				subText: data.getUser.settings.language==='en-US' ? 'English' : 'No saved language',
			},{
				mainText: 'Deactivate Profile',
				subText: 'Just need a little time away from Linkrease?',
			},{
				mainText: 'Delete Profile​',
				subText: 'Learn about options or close your account​',
			}
		];
	} catch (error) {
		return [
			{
				mainText: 'Password',
				subText: '••••••••••••',
			}
			,{
				mainText: 'Email',
				subText: '',
			},{
				mainText: 'Mobile number​',
				subText: '',
			},{
				mainText: 'Language',
				subText: '',
			},{
				mainText: 'Deactivate Profile',
				subText: 'Just need a little time away from Linkrease?',
			},{
				mainText: 'Delete Profile​',
				subText: 'Learn about options or close your account​',
			}
		];
	}
}

export function generatePrivacyHeaders() {
	return [
		{
			mainText: 'Searchability',
			subText: 'Your profile is public and available in search engines​',
		},{
			mainText: 'Activity',
			subText: `Visible to: ​${visibility.activity}`,
		},{
			mainText: 'Portfolio',
			subText: `Visible to: ${visibility.portfolio}`,
		},{
			mainText: 'Campaigns',
			subText: `Visible to: ${visibility.campaigns}​`,
		},{
			mainText: 'Pledges',
			subText: `Visible to:​ ${visibility.pledges}`,
		},
	];
}

export function generateNotificationsHeaders() {
	return [
		{
			mainText: 'Chats​',
			subText: `${notifications.chats} of 3 emails active`,
		},{
			mainText: 'Reactions to your posts',
			subText: `${notifications.reactions} of 3 notifications active`,
		},{
			mainText: 'Jobs and Casting calls​​',
			subText: `${notifications.jobs} of 3 emails active​`,
		},{
			mainText: 'Campaigns',
			subText: `${notifications.campaigns} of 3 emails active​`,
		},{
			mainText: 'Pledges',
			subText: `${notifications.campaigns} of 3 emails active​`,
		},{
			mainText: 'Events',
			subText: `${notifications.events} of 3 emails active​`,
		},	
	];
}

export function generatePurchasesHeaders() {
	return [
		{
			mainText: 'Invoices',
			subText: 'All your invoices will appear here​',
		},{
			mainText: 'Billing Details​',
			subText: 'Update or Change your billing details',
		},
	];
}







