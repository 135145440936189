import React, { useState } from 'react';
import { Form, Button, Row, Col, Switch, Checkbox, Modal } from 'antd';
import Styles from './InvestorInformation.module.scss';

const InvestorInformation = () => {

  const [modalVisible, setModalVisible] = useState(false);

  const onSubmit = (values) => {
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
  };

  const verifyBtnLayout = {
    wrapperCol: { offset: 15, span: 5 },
  };

  return (
    <div className={Styles.investorInformation}>
      <h2 className={Styles.stepTitle}>
        Access multiple early stage investment opportunities with your Investor profile <br />
      </h2>
      <p className={Styles.stepIntro}>
        In order to view financial information and receive alerts about entertainment project investments on Linkrease, you must fill out the following <br/> information for our records. This will not be visible to anyone.
      </p>
      <Form
        {...layout}
        name="basic"
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
      >

        <Row gutter={8} align="middle">
          <Col span={12} offset={7}>
            <Form.Item
              label="Are you a U.S citizen?"
              name="usCitizen"
              labelAlign="left"
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 7 }}
              colon={false}
              className={Styles.boldLabel}
            >
              <Switch className={Styles.switchLabel} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} align="middle">
          <Col span={12} offset={7}>
            <Form.Item
              label="Are you an Accredited Investor?"
              name="accreditedInvestor"
              labelAlign="left"
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 7 }}
              colon={false}
              className={`${Styles.boldLabel} ${Styles.noMarginBottom}`}
            >
              <Switch className={Styles.switchLabel} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} align="middle">
          <Col span={12} offset={7}>
            <Button
              type="link"
              onClick={() => setModalVisible(true)}
              className={Styles.modalButton}
            >
              WHAT DOES THIS MEAN?
            </Button>
          </Col>
        </Row>

        <Modal
          visible={ modalVisible }
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <div className={Styles.modalContentWrapper}>
            <h3 className={Styles.modalTitle}>What is an Accredited Investor?</h3>
            <p className={Styles.modalContent}>
              Under the Securities Act of 1933, a company that offers or sells its securities must register the securities with the SEC or find an exemption from the registration requirements. The Act provides companies with a number of exemptions. For some of the exemptions, such as rules 505 and 506 of Regulation D, a company may sell its securities to what are known as "accredited investors."
              <br/>
              <br/>
              The federal securities laws define the term accredited investor in Rule 501 of Regulation D as: bank, insurance company, registered investment company, business development company, or small business investment company; an employee benefit plan, within the meaning of the Employee Retirement Income Security Act, if a bank, insurance company, or registered investment adviser makes the investment decisions, or if the plan has total assets in excess of $5 million; charitable organization, corporation, or partnership with assets exceeding $5 million; director, executive officer, or general partner of the company selling the securities; business in which all the equity owners are accredited investors; natural person who has individual net worth, or joint net worth with the person’s spouse, that exceeds $1 million at the time of the purchase, excluding the value of the primary residence of such person; natural person with income exceeding $200,000 in each of the two most recent years or joint income with a spouse exceeding $300,000 for those years and a reasonable expectation of the same income level in the current year; or trust with assets in excess of $5 million, not formed to acquire the securities offered, whose purchases a sophisticated person makes.
              <br/>
              <br/>
              For more information about the SEC’s registration requirements and common exemptions, read our brochure, Q&A: Small Business & the SEC. This information is provided by the SEC, and the original copy can be found here.
            </p>
            <Row gutter={8} align="right">
              <Col span={14} offset={18}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className={Styles.modalOkButton}
                  onClick={() => setModalVisible(false)}
                >
                  OKAY
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>

        <Row gutter={8} align="middle">
          <Col span={12} offset={7}>
            <Form.Item
              label="What makes you an Accredited Investor?"
              labelAlign="left"
              className={`${Styles.boldLabel} ${Styles.accreditedLabel}`}
            >
              <Checkbox className={Styles.roundedCheckbox}>I invest on behalf of a company or fund with assets exceeding $5 million.</Checkbox>
              <br/>
              <Checkbox className={Styles.roundedCheckbox}>I have a net worth of at least $1 million (excluding my primary residence).</Checkbox>
              <br/>
              <Checkbox className={Styles.roundedCheckbox}>I have an individual income of at least $200,000, or a joint income of at least $300,000.</Checkbox>
              <br/>
              <Checkbox className={Styles.roundedCheckbox}>Other</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...verifyBtnLayout}>
          <Button
            className={Styles.verifyButton}
            type="link"
          >
            I will verify later
          </Button>
        </Form.Item>

        <Row gutter={8} align="middle">
          <Col span={14} offset={4}>
            <hr/>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InvestorInformation;
