import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import styles from './awards.module.scss';
import Edit from './img/edit.svg';
import Plus from './img/plus.png';
// import Certificate from './img/certificate2.jpg';
// import Certificate2 from './img/certificate3.jpg';
import { Row, Col, Input, DatePicker, Button, Space } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_USER_AWARD } from '../../../graphql/users';
import { UPDATE_USER_AWARD } from '../../../graphql/users';
import { DELETE_USER_AWARD } from '../../../graphql/users';
import moment from 'moment';

const Awards = ({ awards = [{}], refetch }) => {
  // function onTodoChange(value) {
  //   this.setState({
  //     name: value,
  //   });
  // }
  const [editAward, setVisible3] = useState(false);
  const [newAward, setVisible5] = useState(false);
  const [title, settitle] = useState('');
  const [description, setdescription] = useState('');
  const [awardDate, setdate] = useState('');
  const [website, setwebsite] = useState('');
  const [userAwardId, setuserAwardId] = useState('');
  const [userAwardMutation] = useMutation(CREATE_USER_AWARD);
  const [updateUserAwardMutation] = useMutation(UPDATE_USER_AWARD);
  const [deleteUserAwardMutation] = useMutation(DELETE_USER_AWARD);
  const dateFormat = 'YYYY/MM/DD';

  const createAward = async () => {
    try {
      const result = await userAwardMutation({
        variables: {
          input: {
            date: awardDate.format('YYYY-MM-DDThh:mm:ss.sssZ'),
            description: description,
            website: website,
            title: title,
          },
        },
      });
      setVisible5(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateAward = async () => {
    try {
      const result = await updateUserAwardMutation({
        variables: {
          input: {
            userAwardId: userAwardId,
            date: awardDate.format('YYYY-MM-DDThh:mm:ss.sssZ'),
            description: description,
            website: website,
            title: title,
          },
        },
      });
      setVisible3(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteAward = async () => {
    try {
      const result = await deleteUserAwardMutation({
        variables: {
          input: {
            userAwardId: userAwardId,
          },
        },
      });
      setVisible3(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onClickEdit = (awardEdit) => {
    awards.map((award) => {
      if (award.userAwardId === awardEdit) {
        settitle(award.title);
        setdescription(award.description);
        setuserAwardId(awardEdit);
        setdate(award.date);
      }
    });
    setVisible3(true);
  };

  return (
    <div className={styles.Basic}>
      <img
        src={Plus}
        className={styles.plus}
        onClick={() => setVisible5(true)}
        alt=""
      />
      <div className={styles.title}>
        <p>Awards</p>
      </div>
      <div>
        {awards.map((award) => (
          <Row key={award.userAwardId}>
            <Col xs={8} sm={12} md={12} lg={12} className={styles.description}>
              <h3>
                {award.title} - {award.description}
              </h3>
              <h5>{moment(award.date).format('YYYY')}</h5>
            </Col>
            <div className={styles.editContainer}>
              <img
                src={Edit}
                className={styles.edit}
                onClick={() => onClickEdit(award.userAwardId)}
                alt=""
              />
            </div>
          </Row>
        ))}
      </div>
      <Modal
        title="New Award"
        centered
        visible={newAward}
        onOk={() => setVisible5(false)}
        onCancel={() => setVisible5(false)}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={() => createAward()}>
            Save
          </Button>,
        ]}
      >
        <h4 style={{ marginBottom: '1rem' }}>Award Organization</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          placeholder="Organization Name"
          onChange={(e) => settitle(e.target.value)}
          required
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Website</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setwebsite(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Award</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          placeholder="Ex: International Design Awards"
          onChange={(e) => setdescription(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Date Received</h4>
        <Space direction="vertical" size={12}>
          <DatePicker
            style={{ marginBottom: '1rem', width: '32rem' }}
            size="large"
            onChange={(e) => setdate(e)}
          ></DatePicker>
        </Space>
      </Modal>
      <Modal
        title="Edit education"
        centered
        className="educationModal"
        visible={editAward}
        onOk={() => setVisible3(false)}
        onCancel={() => setVisible3(false)}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={() => updateAward()}>
            Update
          </Button>,
          <Button key="back" type="primary" onClick={() => deleteAward()}>
            Delete
          </Button>,
        ]}
      >
        <h4 style={{ marginBottom: '1rem' }}>Award Organization</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          placeholder="Organization Name"
          onChange={(e) => settitle(e.target.value)}
          value={title}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Website</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setwebsite(e.target.value)}
          value={website}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Award</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          placeholder="Ex: International Design Awards"
          onChange={(e) => setdescription(e.target.value)}
          value={description}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Date Received</h4>
        <Space direction="vertical" size={12}>
          <DatePicker
            style={{ marginBottom: '1rem', width: '32rem' }}
            size="large"
            defaultValue={moment(awardDate, dateFormat)}
            onChange={(e) => setdate(e)}
          ></DatePicker>
        </Space>
      </Modal>
    </div>
  );
};

export default Awards;
