import React, { useState } from 'react';
import { Layout, Divider } from 'antd';
import style from './AccountSettings.module.scss';

import MainSettings from '../MainSettings/MainSettings';
import LeftNav from '../LeftNav/LeftNav';
import NavLinks from '../LeftNav/NavLinks';
import LandingFooter from '../../../components/Landing/Footer/Footer';

import { useQuery } from "@apollo/client";
import { GET_LOGGED_USER } from "../../../graphql/users";

const {
  Header, Footer, Sider, Content,
} = Layout;

const AccountSettings = () => {

  const { loading, error, data } = useQuery(
		GET_LOGGED_USER
	);
	if (loading) {
		console.log("loading");
	 }
	
	if (error) {
		console.log("error: ", error.message);
	}


  const [area, setArea] = useState('memberAccount');

  return (
    <div className={style.accountSettingsContainer}>
      <Layout>
        <Header>Header</Header>
        <Layout className={style.contentLayoutContainer}>
          <Sider
            width={290}
            className={style.sider}
            breakpoint="md"
            collapsedWidth="0"
          >
            <LeftNav />
            <NavLinks areaState={[area, setArea]} />
          </Sider>
          <Content className={style.settingsContent}>
            <MainSettings settingsArea={area}/>
          </Content>
        </Layout>
        <LandingFooter />
        <Footer>
          <div className={style.logoContainer}>
            <div style={{ width: '18rem', height: '11rem' }} className="logo" />
          </div>
          <div className={style.logoContainer}>
            <div>COPYRIGHT © 2020 LINKREASE</div>
          </div>
        </Footer>
      </Layout>
    </div>
  )
};

export default AccountSettings;
