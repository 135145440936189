export const fields = [
    {
      category: 'Talent',
      items: [
        {
          label: 'Actor',
          value: 'actor',
        },
        {
          label: 'Actress',
          value: 'actress',
        },
      ],
    },
    {
      category: 'FilmMaker',
      items: [
        {
          label: 'Cinematographer',
          value: 'cinematographer',
        },
        {
          label: 'Director',
          value: 'director',
        },
        {
          label: 'Producer',
          value: 'producer',
        },
      ],
    },
    {
      category: 'Crew',
      items: [
        {
          label: 'Animation Department',
          value: 'animationDepartment',
        },
        {
          label: 'Electrical Department',
          value: 'electricalDepartment',
        },
        {
          label: 'Sound Department',
          value: 'soundDepartment',
        },
        {
          label: 'Art Department',
          value: 'artDepartment',
        },
        {
          label: 'Location Management',
          value: 'locationManagement',
        },
        {
          label: 'Soundtrack',
          value: 'soundtrack',
        },
        {
          label: 'Art Director',
          value: 'artDirector',
        },
        {
          label: 'Makeup Department',
          value: 'makeupDepartment',
        },
        {
          label: 'Special Effects',
          value: 'specialEffects',
        },
        {
          label: 'Camera & Electrical Department',
          value: 'cameraElectricalDepartment',
        },
        {
          label: 'Miscellaneous Crew',
          value: 'miscellaneousCrew',
        },
        {
          label: 'Stunts',
          value: 'stunts',
        },
        {
          label: 'Composer',
          value: 'composer',
        },
        {
          label: 'Music Department',
          value: 'musicDepartment',
        },
        {
          label: 'Transportation Department',
          value: 'transportationDepartment',
        },
        {
          label: 'Custome Designer',
          value: 'customeDesigner',
        },
        {
          label: 'Production designer',
          value: 'productionDesigner',
        },
        {
          label: 'Visual Effects',
          value: 'visualEffects',
        },
      ],
    },
    {
      category: 'Casting',
      items: [
        {
          label: 'Casting Agent',
          value: 'castingAgent',
        },
        {
          label: 'Casting Director',
          value: 'castingDirector',
        },
        {
          label: 'Casting Department',
          value: 'castingDepartment',
        },
      ],
    },
    {
      category: 'Representation',
      items: [
        {
          label: 'Legal',
          value: 'legal',
        },
        {
          label: 'Manager',
          value: 'manager',
        },
        {
          label: 'Publicist',
          value: 'publicist',
        },
      ],
    },
    {
      category: 'Companies and Studios',
      items: [
        {
          label: 'Studio Executive',
          value: 'studioExecutive',
        },
        {
          label: 'Company Owner',
          value: 'companyOwner',
        },
        {
          label: 'Marketing Director',
          value: 'marketingDirector',
        },
      ]
    },
    
    
    {
      category: 'Executive',
      items: [
        {
          label: 'C-Level, Operations',
          value: 'cLevelOperations',
        },
        {
          label: 'D-Level, Director',
          value: 'dLevelDirector',
        },
        {
          label: 'Vice-President, VP',
          value: 'vp',
        },
      ]
    },
    {
      category: 'Other',
      items: [
        {
          label: 'Advertising',
          value: 'advertising',
        },
        {
          label: 'Sales',
          value: 'sales',
        },
        {
          label: 'Banking, Finance',
          value: 'finance',
        },
      ],
    },
  ];