import React, { useState } from 'react';
import { Row, Col, Button, Input, DatePicker, Radio } from 'antd';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

// Styles
import Styles from '../CreatePanel.module.scss';

const { TextArea } = Input;

const EventContentPanel = (props) => {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [eventTypeState, setEventTypeState] = useState('location');

  const onChangeEventType = (e) => {
    e.preventDefault();

    setEventTypeState(e.target.value);
  }

  return (
    <>
      <Col span={24}>
        <label className={Styles.campaignThumbnailLabel} htmlFor="event-thumbnail">EVENT THUMBNAIL</label>
        <Input className={Styles.campaignThumbnailInput} id="event-thumbnail"/>
        <Button className={Styles.campaignThumbnailButton} type="primary" shape="round">UPLOAD IMAGE</Button>
      </Col>
      <Col span={24}>
        <label htmlFor="event-title">EVENT TITLE</label>
        <Input className={Styles.styleTextInput} id="event-title"/>
      </Col>
      <Col span={24}>
        <label htmlFor="event-description">DESCRIPTION</label>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={Styles.editorWrapper}
          onEditorStateChange={setEditorState}
        />
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col span={5}>
            <label htmlFor="">EVENT STARTS</label>
            <DatePicker className={Styles.styledDatepicker} format="YYYY-MM-DD HH:mm A" showTime={{ format: 'HH:mm A' }} />
          </Col>
          <Col offset={1} span={5}>
            <label htmlFor="">EVENT ENDS</label>
            <DatePicker className={Styles.styledDatepicker} format="YYYY-MM-DD HH:mm A" showTime={{ format: 'HH:mm A' }} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Radio.Group className={Styles.radioGroupWrapper} onChange={onChangeEventType} value={eventTypeState}>
          <Radio value="location">EVENT LOCATION</Radio>
          <Radio value="online">ONLINE EVENT</Radio>
        </Radio.Group>
      </Col>
      {
        (eventTypeState === 'location') && <Col span={24}>
          <label htmlFor="event-venue">VENUE</label>
          <Input className={Styles.styleTextInput} id="event-venue"/>
          <label htmlFor="event-directions">DIRECTIONS</label>
          <TextArea rows={6} className={Styles.styledTextArea} id="event-directions"/>
        </Col>
      }
      {
        (eventTypeState === 'online') && <Col span={24}>
          <label htmlFor="event-venue">EVENT LINK</label>
          <Input className={Styles.styleTextInput} id="event-link"/>
        </Col>
      }
      <Col span={3}>
        <Button ghost block type="primary" shape="round">CANCEL</Button>
      </Col>
      <Col offset={14} span={3}>
        <Button ghost block type="primary" shape="round">SAVE AS DRAFT</Button>
      </Col>
      <Col offset={1} span={3}>
        <Button block type="primary" shape="round">PUBLISH</Button>
      </Col>
    </>
  );
}

export default EventContentPanel;
