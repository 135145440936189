import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Notification.module.scss';
import threeDots from '../img/threeDots.png';
import blueline from '../img/blueLine.png';
import guillermoMazonni from '../img/guillermo.jpg';
import jairoVelasquez from '../img/gilberto.jpg';
export default function Notification({
  keyProp,
  aggregatedNotifications,
  type,
  setDrawerVisible,
}) {
  return (
    <div>
      <div key={keyProp} className={Styles.notification}>
        <img src={blueline} className={Styles.blueline} alt=""/>
        <div className={Styles.align}>
          <img src={guillermoMazonni} className={Styles.profile} alt=""/>
          <p className={Styles.notificationData}>
            Guillermo Mazzoni shared a post: Connect Your Voice to the World with a  <a href="www.audiobookssolution.com">www.audiobookssolution.com </a> a Podcast about amateur talent ….
          </p>
          <img src={threeDots} className={Styles.threeDots} alt=""/>
        </div>
      </div>
      <div key={keyProp} className={Styles.notification}>
        <div className={Styles.align}>
          <img src={jairoVelasquez} className={Styles.profile} alt=""/>
          <p className={Styles.notificationData}>
          Jairo Velasquez has shared a document: Diego your signature is required. View document at   <a href="www.hellosign.com">www.hellosign.com </a>
          </p>
          <img src={threeDots} className={Styles.threeDots} alt=""/>
        </div>     
      </div>       
    </div>
  );
}

Notification.propTypes = {
  keyProp: PropTypes.number,
  aggregatedNotifications: PropTypes.array,
  type: PropTypes.string,
  setDrawerVisible: PropTypes.func,
};

Notification.defaultProps = {
  keyProp: null,
  aggregatedNotifications:[],
  type: '',
  setDrawerVisible: () => {},
};
