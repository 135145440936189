import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import Icon from '@ant-design/icons';
import Styles from '../Cards.module.scss';

import profile from '../img/profile-sample.jpg';
import { useQuery } from '@apollo/client';
import { LIST_POSTS } from '../../../../graphql/posts';
import { GET_LOGGED_USER } from '../../../../graphql/users';
import InteractionUser from '../../../InteractionUser/InteractionUser';
import { Waypoint } from 'react-waypoint';

// Icons
import { ReactComponent as LikeIcon } from '../img/like-icon.svg';
import { ReactComponent as CommentIcon } from '../img/comment-icon.svg';
import { ReactComponent as ShareIcon } from '../img/share-icon.svg';
import Default from '../../../../img/no-img.png';

const Post = () => {
  const [showInteraction, setshowInteraction] = useState(false);
  const dataP = useQuery(LIST_POSTS, {
    variables: { input: { limit: 5 } },
    notifyOnNetworkStatusChange: true,
  });
  const dataU = useQuery(GET_LOGGED_USER);
  const profilePic =
    dataU?.data?.getUser?.profileImage == null
      ? Default
      : dataU?.data?.getUser?.profileImage?.split(', ');
  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');
  const [jobTitle, setjobTitle] = useState('');
  const [jobCompany, setjobCompany] = useState('');
  const [profilePics, setprofilePics] = useState('');
  const [given_name, setgiven_name] = useState('');
  const [family_name, setfamily_name] = useState('');

  useEffect(() => {
    setfamily_name(dataU?.data?.getUser?.family_name);
    console.log(smallImage);
    setprofilePics(smallImage);
    setgiven_name(dataU?.data?.getUser?.given_name);
    setjobCompany(dataU?.data?.getUser?.jobs[0]?.company);
    setjobTitle(dataU?.data?.getUser?.jobs[0]?.title);
  }, [dataU]);
  return (
    <div>
      {dataP?.data?.listPosts?.items.map((post, i) => (
        <div className={Styles.cardPostContainer}>
          <React.Fragment key={post.post_id}>
            {!dataP?.data?.listPosts?.nextToken == '' &&
              i === dataP?.data?.listPosts?.items.length - 1 && (
                <Waypoint
                  onEnter={() =>
                    dataP?.fetchMore({
                      variables: {
                        input: {
                          limit: 5,
                          nextToken: dataP?.data?.listPosts?.nextToken,
                        },
                      },
                      updateQuery: (pv, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          return pv;
                        }
                        return {
                          listPosts: {
                            __typename: 'PostConnection',
                            items: [
                              ...pv.listPosts.items,
                              ...fetchMoreResult.listPosts.items,
                            ],
                            nextToken: fetchMoreResult.listPosts.nextToken,
                          },
                        };
                      },
                    })
                  }
                ></Waypoint>
              )}
            <InteractionUser
              showInteraction={showInteraction}
              setshowInteraction={setshowInteraction}
              profilePic={profilePics}
              jobTitle={jobTitle}
              jobCompany={jobCompany}
              given_name={given_name}
              family_name={family_name}
            />
            <Row gutter={[6, 6]}>
              <Col span={3}>
                <div className={Styles.imgContainer}>
                  <img
                    src={profilePics}
                    alt="profile"
                    onMouseEnter={() => setshowInteraction(true)}
                  />
                </div>
              </Col>
              <Col xs={24} sm={8} md={10} lg={16}>
                <Row gutter={[6, 6]}>
                  <Col span={10}>
                    <h5 className={`${Styles.postTitle} ${Styles.borderRight}`}>
                      {given_name} {family_name}
                    </h5>
                  </Col>
                  <Col>
                    <h5 className={`${Styles.postTitle} ${Styles.paddingLeft}`}>
                      Rank
                      <span className={Styles.rankBubble}>2</span>
                    </h5>
                  </Col>
                  <Col span={16}>
                    <h6 className={Styles.jobPosition}>
                      {jobTitle} @ {jobCompany}
                    </h6>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={2}>
                        <img
                          src={profile}
                          className={Styles.backingBubble}
                          alt="profile"
                        />
                      </Col>
                      <Col>
                        <h6 className={Styles.peopleReacting}>
                          GILBERTO MEDINA AND 118 OTHERS ARE REACTING TO THIS
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <p className={Styles.postCopy}>{post.content}</p>
                <Icon className={Styles.postLikes} component={LikeIcon} />
                <span className={Styles.totalLikesComments}>
                  20 - 12 comments
                </span>
              </Col>
              <Col span={24}>
                <hr className={Styles.postDivider} />
              </Col>
              <Col span={5}>
                <button className={Styles.noStyledButton}>
                  <Icon
                    className={`${Styles.postActionIcon} ${Styles.likePostActionButton}`}
                    component={LikeIcon}
                  />
                  Like
                </button>
              </Col>
              <Col span={7}>
                <button className={Styles.noStyledButton}>
                  <Icon
                    className={`${Styles.postActionIcon} ${Styles.commentPostActionButton}`}
                    component={CommentIcon}
                  />
                  Comment
                </button>
              </Col>
              <Col span={5}>
                <button className={Styles.noStyledButton}>
                  <Icon
                    className={`${Styles.postActionIcon} ${Styles.sharePostActionButton}`}
                    component={ShareIcon}
                  />
                  Share
                </button>
              </Col>
            </Row>
          </React.Fragment>
        </div>
      ))}
      {dataP?.networkStatus === 3 && <Spin className={Styles.spin} />}
    </div>
  );
};

export default Post;
