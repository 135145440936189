import React from 'react';
import Icon from '@ant-design/icons';
import LandingHeader from '../Header/LandingHeader';
import { ReactComponent as checkmarkSVG } from './img/checkmark.svg';

const parragraphGenerators = (texts, styles) => {
  return texts.map((text, index) => (
    <p key={index} className={styles.containerText}>
      <Icon component={checkmarkSVG} /> {text}{' '}
    </p>
  ));
};

const ForCards = ({
  number,
  headerText,
  text,
  textsToRender,
  children,
  styles,
}) => (
  <div className={styles.innerContainer}>
    <div className={styles.firstInnerContainer}>
      <span className={styles.numberTitle}>{number}</span>
      <LandingHeader
        headerText={headerText}
        text={text}
        headerStyle={styles.accentText}
      />
    </div>
    <div className={styles.secondInnerContainer}>
      {parragraphGenerators(textsToRender, styles)}
      {children || ''}
    </div>
  </div>
);

export default ForCards;
