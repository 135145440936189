import { createSlice } from '@reduxjs/toolkit';
import { getJobs } from './Jobs.thunks';

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    status: 'idle',
    error: null,
    profile: {},
    undoStack: {},
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getJobs.fulfilled, (state, { payload }) => {
        state.status = 'done';

        state.profile = payload;
      })
      .addCase(getJobs.rejected, (state) => {
        state.status = 'rejected';
      }),
});

export default jobsSlice.reducer;
