import React from 'react';
import Styles from './SearchBar.module.scss';
import { Row, Col, Input, AutoComplete } from 'antd';
import { Link } from 'react-router-dom';

const SearchBar = (props) => {
  return (
    <Row
      className={Styles.searchBarContainer}
      align="middle"
      justify="center"
      gutter={[24, 24]}
    >
      <Col xs={24} sm={24} md={8} lg={4}>
        <Link to="">Creative Dashboard</Link>
      </Col>
      <Col xs={24} sm={24} md={8} lg={4}>
        <Link to="">Funding Dashboard</Link>
      </Col>
      <Col xs={24} sm={24} md={8} lg={4}>
        <Link to= "">Content Marketplace</Link>
      </Col>
      <Col xs={18} sm={18} md={18} lg={6}>
        <AutoComplete className={Styles.searchInput}>
          <Input.Search
            size="large"
            placeholder="Search People, Jobs, Casting Calls, Projects..."
          />
        </AutoComplete>
      </Col>
    </Row>
  );
};

export default SearchBar;
