import React from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Checkbox, AutoComplete, Col, Row, Button } from 'antd'

const  AboutCompanyVert =({goNextStep, formRef})=>{
  const options=[
                {value:'Just 1 job for now'},
                {value:'2 to 3 jobs'},
                {value:'4 to 10 jobs'},
                {value:'more than 10 jobs'},
                ]
  const verticalOpt=[
                      {value:'FILM'},
                      {value:'SPORTS'},
                      {value:'MUSIC'},
                      {value:'GAMING'},
                      {value:'ALL ENTERTAIMENT VERTICALS'},
                    ]
    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
     
    span={20}
    >    
    <Row justify='center'>
        
        <h2 className={styles.title}>Tell us about your company</h2>
          
        </Row>

    <Row justify='center'>
        <Col
        span={20}
        >

    <Form
      name="vertical"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="How many jobs do you need to fill?"
        name="jobs"
        rules={[{ required: true, message: 'Please select an option!' }]}
      >
       <AutoComplete
       options={options}
       >
         </AutoComplete>
      </Form.Item>

      <Form.Item
        label="What is the primary vertical of your company?"
        name="vertical"
        rules={[{ required: true, message: 'Please select a vertical!' }]}
      >
        <AutoComplete
        options={verticalOpt}>
        </AutoComplete>
      </Form.Item>

      

     

      <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        onClick={(e)=>goNextStep(e)}>
         CONTINUE
        </Button>
      </Form.Item>
    </Form>
        </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default AboutCompanyVert