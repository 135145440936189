const es = {
  landing: {
    theProfessionalNetworkPlatformForContentCreation:
      "La plataforma de red profesional para la creación de contenido.",
    collaborativeNetworkText:
      "La plataforma de <strong>colaboración</strong> para la industria del <strong>entretenimiento</strong>",
    whyLinkrease: "PORQUE LINKREASE?",
    signIn: "INGRESA AQUÍ",
    joinNow: "REGÍSTRATE",
    newToLinkrease: "Eres nuevo para Linkrease?",
    joinTheContentCreation: "Únete a la revolución de creación de contenidos",
    myEntertainmentVertical: "MI RAMA DE ENTRETENIMIENTO",
    film: "Película",
    sports: "Deportes",
    music: "Musica",
    gaming: "Video Juegos",
    continue: "CONTINÚE",
    orJoinUs: "O ÚNETE CON",
    whoIsLinkreaseFor: "Para quien es Linkrease?",
    lookingToProduce:
      "Para cualquiera que esta buscando producir o invertir en un proyecto de entretenimiento",
    filmMakers: "Productores de Películas",
    castingAgents: "Agentes de talento",
    videoCreators: "Creadores de Videos",
    managementCompanies: "Compañías de Producción/Administración",
    crew: "Equipo de Producción",
    musicians: "Músicos",
    showMore: "Mostrar mas",
    showLess: "Mostrar menos",
    getStarted: "COMIENZA YA!",
    connectCreate: "Relaciónate, Crea, Colabora y Consigue Financiamiento",
    linkreasePlatformDescription:
      "Linkrease es una plataforma revolucionaria en donde profesionales de la industria del entretenimiento y talento aspiracional pueden Socializar, crear, colaborar, recolección de fondos colectivos e invertir en proyectos de entretenimiento. Finalmente transformando lo que pudiese ser una simple idea, manuscrito o contenido de aficionado en proyectos de contenido viable y monetizable.",
    areYouAnInfluencer: "Eres un influenciador? ",
    learnMore: "Aprende mas",
    forIndustryProfessionals: "Profesionales de la industria",
    monetizeBy: "Monetiza tu status como profesional de la industria al:",
    attachingYourName: "Completar tu nombre y experiencia",
    participatingWithCreators:
      "Conectar con otros creadores y desarrolla nuevos proyectos",
    resultingIn: "Resultar en contenido mejorado que",
    industryDecisionMakers:
      "Conectar con ejecutivos de la industria que pudiese llevarte a",
    fundingDistributionDeals: "Invertir en oportunidades de distribución",
    exponentialIncrease: "Incremento exponencial de tu",
    forAspirationalTalent: "Para talento Aspiracional",
    increaseYourChances: "Incrementa tus posibilidades de éxito al:",
    connectedToVetted: "Conectarte con proyectos de entretenimiento formales",
    collaboratingWithYou: "Ayudarte a incrementar visibilidad en la industria",
    collaboratingWithYourProj: "Colaborar contigo en tu proyecto",
    curatedGradeProjects:
      "Darte visibilidad de proyectos formales que se pueden ver",
    vettedCrowdfundingRounds:
      "Darte visibilidad de rondas de recolección de fondos colectivos formales",
    forInfluencers: "Para Influenciadores",
    monetizeYourInfluence: "Monetiza tu Influencia al:",
    attachingYourNameEntertain: "Relacionar tu nombre con el entretenimiento",
    collaboratingProjectTeams:
      "Colaborar con los equipos de diferentes proyectos",
    sharingProjectsYouSponsor: "Compartir los proyectos que auspicias",
    reachOutToFollowers: "Conectar con tus seguidores para",
    registerForEarlyAccess: "Regístrate para acceso temprano",
    signUp: "Regístrate",
    harnessThePower: "Aprovecha el poder de la red y monetiza tu creatividad",
    linkreaseIsWhere:
      "Linkrease es donde talento aspiracional trae sus ideas para proyectos de entretenimiento y profesionales de la industria verificados se unen para hacerlos realidad",
    pleaseSelectOne: "Por favor selecciona una opción",
    collaborationTools: "Herramientas de colaboración para llevarte al éxito",
    ideaToSubmission:
      "Desde compartir una idea, atraer talento o lanzando una campana de recaudación de fondos colectivos para tu proyecto de entretenimiento - Linkrease te de a ti y a tu equipo las herramientas precisas para manejar el flujo  eficientemente y monetizar tus oportunidades de manera ágil e inteligente",
    learnMoreAboutHowItWorks: "Aprende mas de como funciona",
    crowdFundYourEligibleProjects:
      "Consigue fondos colectivos para tus proyectos",
    leverageNetworkEffect:
      "Ya sea, si eres la estrella o quien inicia una campana de recolección de fondos colectivos, nuestros usuarios pueden aprovechar el efecto de la red de Linkrease para hacer realidad sus proyectos de entretenimiento",
    startLivingYourBestCareer: "Comienza tu mejor carrera ya!",
    weHelpAspirationalTalent:
      "Nosotros ayudamos a talento aspiracional a conseguir las audiciones correctas y a profesionales de la industria a conseguir excelentes trabajos",
    jobs: "Trabajos/Roles",
    castingCalls: "Audiciones",
    searchJobTitlesOrComps: "Buscar Títulos de trabajo o Compañías",
    selectCityStateCountry: "Seleccionar Ciudad, Estado y País",
    findJobs: "Buscar Trabajos",
    areYouAnEmployer: "Eres un empleador o Agencia de Audiciones? ",
    seeHowOurSolutionsHelp:
      "Vea como nuestras soluciones de talento lo pueden ayudar",
    postJob: "Postear un trabajo",
    listCastingCall: "Anuncia una audición",
    company: "Compañía",
    aboutUs: "Quienes Somos",
    leadership: "Liderazgo",
    press: "Prensa",
    careers: "Carreras",
    contactUs: "Contáctanos",
    community: "Comunidad",
    members: "Miembros",
    guidelines: "Pautas",
    support: "Centro de ayuda",
    linkreaseForStudents: "Linkrease para estudiantes",
    product: "Producto",
    howItWorks: "Como Funciona",
    features: "Funcionalidades",
    pricing: "Precios",
    crowdfunding: "Recolección de Fondos Colectivos",
    businessSolutions: "Soluciones de Negocio",
    talent: "Búsqueda de Talento",
    marketing: "Marketing",
    sales: "Ventas",
    partners: "Asociados",
    streaming: "Transmisión",
    filmAndTVStudios: "Estudios de Películas y Televisión",
    distributors: "Distribuidores",
  },
  register: {
    whatsYourEntertainmentVertical:
      "En que area de entretenimiento te desempeñas?",
    makingItInTheEntertainment:
      "Lograrlo en la Industria del Entretenimiento puede tardarte años. Registrarte te tomara solo 2 minutos!",
    iHaventDecidedMy: "Aun no he decidido mi area",
    continue: "CONTINUAR",
    tellUsWhichDefines: "Con que categoria te identificas mas?",
    imAspirationalTalent:
      "Soy talento nuevo. Quiero lanzar mi carrera en la Industria del Entretenimiento.",
    iAlreadyWorkInTheIndustry:
      "Actualmente trabajo en la industria. Soy un professional activo en la Industria del Entretenimiento.",
    greatLetsSetUpYour: "Fantastico, empezemos creando tu cuenta gratuita",
    emailAddress: "Correo electronico",
    password: "Contraseña",
    nOrMoreCharacters: "( 6 o mas caracteres) ",
    repeatPassword: "Repita la Contraseña",
    youAgreeToTheLinkrease:
      "Estoy de acuerdo y aceptas nuestros Terminos y Condiciones, Politicas de Privacidad y Politicas de Cookies.",
    howShouldWeGreet: "Bien, como debemos saludarte?",
    given_name: "Nombre",
    lastName: "Apellido",
    nowLetsDoAQuick: "Ok, ahora un rapido control de seguridad.",
    welcome: "Te damos la bienvenida",
    letsSetUpYourProfile:
      "Ahora personaliza tu perfil para que puedas conectarte, colaborar y ganar dinero creando contenido.",
    countryRegion: "Pais",
    city: "Ciudad / Provincia",
    postalCode: "Codigo postal",
    checkYourEmail: "Revisa tu correo electronico!",
    weveSentA: "Hemos enviado un codigo de confirmacion de 6 digitos a",
    itWillExpireShortly: "Escribe el codigo en breve ya que expirara pronto.",
    keepThisWindowOpen:
      "Manten esta ventana abierta mientras accedes al codigo. No olvides revisar tu archivo de spam!",
    textMeTheCode: "Enviar via mensaje texto",
    sendCode: "Enviar Codigo",
    didntReceiveTheCode: "No recibiste el codigo?",
    sendAgain: "Reenviar",
    oopsYouHaveEntered:
      "oh no, parece que ingresaste el codigo incorrecto. Trata otra vez.",
    nowTellUsWhyYou: "Ahora, cual es la razon principal de unirte",
    sellectAllThatApply: "Elige todas las que apliquen",
    thisInformationWillHelp:
      "Esto nos ayudara a recomendarte las mejores oportunidades de ganar dinero creando contenido",
    connectWithOtherProfessionals:
      "Conectarme con otros profesionales / influencers",
    crowdfundProjectsCampaigns: "Financiamiento colectivo de proyectos",
    listSubmitFinance: "Listar / Anunciar  Proyectos de Entretenimiento",
    searchJobsPostJobs: "Buscar Empleo / Anunciar Empleo",
    joinProjectsAndIncrease:
      "Unirme a Proyectos / Incrementar mi Cartera de Proyectos",
    imAnInvestorApply: "Soy Inversionista / Aplicar a Status de Inversionista",
    other: "Otra Razon",
    accessMultipleEarlyStage:
      "Accede a multiples oportunidades de Inversion con tu perfil de Inversionista",
    inOrderToViewFinancial:
      "Para poder visualizar informacion financiera y recibir alertas de proyectos de inversion en Linkrease, debe completer la siguiente informacion para nuestros archivos. Esta informacion es estrictamente confidencial y nadie tendra acceso a la misma.",
    areYouAUsCitizen: "Eres ciudadano de Estados Unidos?",
    areYouAnAccredited: "Eres un Inversionista Acreditado?",
    yes: "Si",
    no: "No",
    pleaseSelectAtLeast: "Elige al menos una",
    whatMakesYouAn: "Que te califica como Inversionista Acreditado?",
    whatDoesThisMean: "Que significa esto?",
    iInvestOnBehalfOf:
      "Invierto a nombre de una compañia o fondo con activos que exceden los $5 millones de dolares",
    iHaveANetWorth:
      "Tengo un net worth de por lo menos $1 millon de dolares (excluyendo mi residencia primaria)",
    iHaveAnIndividualIncome:
      "Tengo ingresos individuales de por lo menos $200,000 dolares, o ingresos mancomunados de por lo menos $300,000 dolares",
    apply: "Aplicar",
    iWillVerifyLater: "Pienso verificar despues",
    bePartOfMultiple:
      "Se parte de multiples proyectos verificando tus credenciales profesionales",
    attachYourEntertainmentIndustry:
      "Anexa tu perfil de profesional activo en la industria a proyectos y aumenta sus posibilidades de financiamiento.",
    learnMore: "Descubre como",
    verifyWithLinkedin: "Verificar con Linkedin",
    verifyWithWorkEmail: "Verificar usando mi correo profesional",
    linkedinWillOnlyBeUsed:
      "Tu perfil de Linkedin sera utilizado unicamente para verificar tu lugar de empleo y no afectara como ingresas a Linkrease.",
    yourWorkEmailWillOnly:
      "Tu correo profesional sera utilizado unicamente para verificar tu lugar de empleo y no afectara como ingresas a Linkrease.",
    becomeAVerfiedEntertainment:
      "Conviertete en un profesional verificado de la industria.",
    verifyingIncreasesYourChances:
      "Verificarte aumenta tus posibilidades de tener mayor ranking dentro de nuestro sistema. Cuan mas alto tu ranking, las probabilidades de mejores conexiones, colaboracion y oportunidades de financiamiento seran mayores.",
    mostRecentCompany: "Compañia mas reciente*",
    mostRecentTitle: "Posicion mas reciente*",
    fromTo: "Desde / Hasta",
    selectTheFieldThatBest:
      "Selecciona el area que major describe tu carrera en entretenimento",
    iWillAddThisLater: "Agregar despues",
    yourProfessionalSideHas: "Tu lado profesional tiene cara. Haz que cuente.",
    addingAPhotoMakes: "Agregar una foto hace que la gente te reconozca",
    useMyGooglePhoto: "Usar mi foto de Google",
    useMyFacebookPhoto: "Usar mi foto de Facebook",
    yourEntertainmentPortfolioHelps:
      "Tu portafolio en entretenimiento te ayuda  mostrat tu major trabajo",
    addWebsite: "Añadir sitio web",
    addLink: "Añadir enlance",
    viewMore: "Ver mas",
    awardOrganization: "Organizacion que otorgo el premio",
    dateReceived: "Fecha de recepcion",
    addAward: "Añadir Premio",
    cancel: "Cancelar",
    congratulations: "Felicitaciones",
    talent: "Talento",
    filmmaker: "Cineasta",
    executive: "Ejecutivo",
    casting: "Casting",
    companiesstudios: "Compañias & Studios",
    crew: "Equipo de produccion",
    representation: "Representacion",
    actor: "Actor",
    actress: "Actriz",
    producer: "Productor",
    director: "Director",
    cinematographer: "Cinematografo",
    animationDepartment: "Departamento de Animacion",
    artDirector: "Director de Arte",
    editor: "Editor",
    castingAgent: "Agente de Casting",
    castingDirector: "Director de Casting",
    castingDepartment: "Departamento de Casting",
    manager: "Manager",
    publicist: "Publicista",
    studioExecutive: "Ejecutivo de Studio",
    companyOwner: "Dueño de Empresa",
    marketingDirector: "Director de Marketing",
    advertising: "Publicidad",
    sales: "Ventas",
    bankingFinance: "Banca, Finanzas",
    cLevelOperations: "Nivel – C, Operaciones",
    dLevelDirector: "Nivel – D, Director",
    vicePresident: "Vice – Presidente, Vp",
  },
  compactFooter: {
    about: "Acerca de",
    agreement: "Condiciones de uso",
    privacyPolicy: "Política de privacidad",
    cookiePolicy: "Política de cookies",
    copyrightPolicy: "Política de copyright",
    brandPolicy: "Política de marca",
    guestControls: "Controles de invitados",
    communityGuidelines: "Directrices comunitarias",
    language: "Idioma",
  },
  errors: {
    404: "",
    500: "",
    403: "",
  },
  about: {},
  contact: {},
  settings: {},
};

export default es;
