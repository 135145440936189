import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Divider } from 'antd';
import { Icon, CheckOutlined } from '@ant-design/icons';
import Styles from './InteractionUser.module.scss';
import profile from './img/profile-sample.jpg';
import infinite from './img/infinite.PNG';

const InteractionUser = (props) => {
  const [showInteraction, setVisible] = useState(false);
  const [buttonText, setButtonText] = useState('+ Follow');
  const [color, setColor] = useState('#0438fa');
  const [textColor, setTextColor] = useState('white');
  const [borderwidth, setborderwidth] = useState('0rem');
  const follow = (e) => {
    if (buttonText == '+ Follow') {
      setButtonText('Following');
      setTextColor('#0438fa');
      setColor('white');
      setborderwidth('0.1rem');
    } else {
      setButtonText('+ Follow');
      setTextColor('white');
      setColor('#0438fa');
    }
  };
  const message = () => {};
  useEffect(() => {
    setVisible(props.showInteraction);
  }, [props.showInteraction]);
  return (
    <Modal
      visible={showInteraction}
      footer={null}
      closable={false}
      mask={false}
      maskClosable={true}
      keyboard={true}
      onCancel={() => props.setshowInteraction(false)}
      className={Styles.modal}
    >
      <div onMouseLeave={() => props.setshowInteraction(false)}>
        <Row gutter={[6, 6]}>
          <Col span={3}>
            <div className={Styles.imgContainer}>
              <img src={props.profilePic} alt="profile" />
            </div>
          </Col>
          <Col span={21}>
            <Row>
              <Col span={14}>
                <h5 className={`${Styles.postTitle}`}>
                  {props.given_name} {props.family_name}
                </h5>
              </Col>
              <Col span={10}>
                <h5 className={`${Styles.postTitle}`}>
                  <Divider type="vertical" className={Styles.dividerVertical} />
                  Rank
                  <span className={Styles.rankBubble}>2</span>
                </h5>
              </Col>
              <Col span={15}>
                <h6 className={Styles.jobPosition}>
                  {props.jobTitle} @ {props.jobCompany}
                </h6>
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row>
              <Col span={4}>
                <img
                  src={infinite}
                  className={Styles.backingBubble}
                  alt="profile"
                />
              </Col>
              <Col span={20}>
                <h6 className={Styles.peopleReacting}>
                  2 shared connections Diego Garcia and Raul Argueta
                </h6>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <hr className={Styles.postDivider} />
          </Col>
          <Col span={24}>
            <Row>
              <Col>
                <p className={Styles.postCopy}>
                  <h5 className={`${Styles.postTitle}`}>3</h5>
                  Projects
                </p>
              </Col>
              <Col>
                <p className={`${Styles.postCopy} ${Styles.margin}`}>
                  <h5 className={`${Styles.postTitle}`}>39</h5>
                  Posts
                </p>
              </Col>
              <Col>
                <p className={`${Styles.postCopy} ${Styles.margin}`}>
                  <h5 className={`${Styles.postTitle}`}>18256</h5>
                  Followers
                </p>
              </Col>
              <Col>
                <p className={`${Styles.postCopy} ${Styles.margin}`}>
                  <h5 className={`${Styles.postTitle}`}>1256</h5>
                  Following
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              block
              className={Styles.messageBtn}
              type="primary"
              shape="round"
              key="message"
              type="primary"
              onClick={() => message()}
            >
              Message
            </Button>
            <Button
              block
              style={{
                background: color,
                color: textColor,
                borderColor: textColor,
                borderWidth: borderwidth,
              }}
              className={Styles.followBtn}
              type="primary"
              shape="round"
              key="message"
              type="primary"
              onClick={(e) => follow(e)}
            >
              <CheckOutlined className={Styles.check} /> {buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default InteractionUser;
