import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import fetch from 'node-fetch';
import config from '../utils/config';
import { operationName } from '@apollo/client';

const AppSyncConfig = {
  graphqlEndpoint: config.GRAPHQL_API_URL,
  region: config.REGION,
  authenticationType: config.AUTHENTICATION_TYPE,
  apiKey: config.GRAPHQL_API_KEY,
};

const url = AppSyncConfig.graphqlEndpoint;
const { region } = AppSyncConfig;

// API key link

const apiAuthLink = createAuthLink({
  url,
  region,
  auth: {
    type: 'API_KEY',
    apiKey: AppSyncConfig.apiKey,
  },
  fetch,
});

// cognito Connect link
const cognitoUserPoolsAuthLink = createAuthLink({
  url,
  region,
  auth: {
    type: config.AUTHENTICATION_TYPE,
    apiKey: AppSyncConfig.apiKey,
    jwtToken: async () => {
      try {
        return localStorage.getItem('cognito-idToken');
      } catch (error) {
        return '';
      }
    },
  },
  fetch,
});

// decide which the proper link from above to use (directional link)
const awsLink = ApolloLink.split(
  (operation) =>
    operation.operationName === 'SIGN_IN' ||
    operation.operationName === 'SIGN_UP' ||
    operation.operationName === 'RESET_PASSWORD' ||
    operation.operationName === 'CONFIRM_RESET_PASSWORD' ||
    operation.operationName === 'IS_USER_EMAIL_TAKEN' ||
    operation.operationName === 'SEARCH_JOB_TITLE' ||
    operation.operationName === 'PARSE_LINKEDIN',

  apiAuthLink,
  cognitoUserPoolsAuthLink
);

// http link (the terminating link in the chain)
const httpLink = createHttpLink({ uri: url, fetch });

// create ApolloClient with AWS links and cache
export default new ApolloClient({
  link: ApolloLink.from([
    awsLink,
    createSubscriptionHandshakeLink(url, httpLink),
  ]),
  cache: new InMemoryCache({
    freezeResults: true,
  }),
  assumeImmutableResults: true,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
  },
});
