export const setAuthItemsToLocalStorage = (items = {}) => {
  Object.keys(items).forEach((key) => {
    if (typeof items[key] === 'object') {
      localStorage.setItem(`cognito-${key}`, JSON.stringify(items[key]));
    } else {
      localStorage.setItem(`cognito-${key}`, items[key]);
    }
  });
};

export const handleLogout = () => {
  try {
    localStorage.removeItem('cognito-idToken');
    localStorage.removeItem('cognito-accessToken');
    localStorage.removeItem('cognito-refreshToken');
    localStorage.removeItem('linkedinData');
    sessionStorage.clear();
    window.location.href = '/';
  } catch (error) {
    console.log('signout error: ' + error);
  }
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return (...parameters) => {
    const context = this;
    const args = parameters;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

export function shortNumberAbbreviation(value) {
  const valueLength = String(value).length;

  if (valueLength < 4) return value;

  const suffixes = ['K', 'M', 'B', 'T'];
  const suffixIndex = Math.ceil((valueLength - 3) / 3);
  const valueSuffix = suffixes[suffixIndex - 1];
  const stringifiedValue = String(value / Math.pow(1000, suffixIndex));
  const decimalPosition = stringifiedValue.indexOf('.');

  if (decimalPosition === -1) {
    return stringifiedValue + valueSuffix;
  }

  return stringifiedValue.substring(0, decimalPosition + 2) + valueSuffix;
}

export const timeConversion = (millisec = 0) => {
  const seconds = Math.floor(millisec / 1000);
  const minutes = Math.floor(millisec / (1000 * 60));
  const hours = Math.floor(millisec / (1000 * 60 * 60));
  const days = Math.floor(millisec / (1000 * 60 * 60 * 24));

  if (seconds < 60) {
    return 'Just now';
  }
  if (minutes < 60) {
    return `${minutes} mins ago`;
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }
  return `${days} days ago`;
};

export const dateMessageConversion = (dateCreated) => {
  const date = new Date(dateCreated);
  const millisec = Date.now() - date.getTime();

  const days = Math.floor(millisec / (1000 * 60 * 60 * 24));

  if (days === 0) {
    return `${date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  }
  if (days === 1) {
    return 'Yesterday';
  }
  return `${days} days ago`;
};

export const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const createFileFromBlob = (blob, name, type) => {
  const file = new File([blob], name, { type });
  return file;
};
