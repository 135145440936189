import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import styles from './experience.module.scss';
import Edit from './img/edit.svg';
import Plus from './img/plus.png';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SEARCH_JOB_TITLE } from '../../../graphql/jobTitles';
import { CREATE_USER_JOB } from '../../../graphql/users';
import { UPDATE_USER_JOB } from '../../../graphql/users';
import { DELETE_USER_JOB } from '../../../graphql/users';
import moment from 'moment';
import {
  Row,
  Col,
  AutoComplete,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Checkbox,
} from 'antd';

const Experience = ({ jobs = [{}], refetch }) => {
  const [userJobMutation] = useMutation(CREATE_USER_JOB);
  const [updateUserJobMutation] = useMutation(UPDATE_USER_JOB);
  const [deleteUserJobMutation] = useMutation(DELETE_USER_JOB);
  const [editExperience, setVisible4] = useState(false);
  const [newExperience, setVisible5] = useState(false);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const [options, setOptions] = useState([]);
  const [getJobs, { loading, error, data }] = useLazyQuery(SEARCH_JOB_TITLE);
  const [result, setResult] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [recentCompany, setrecentCompany] = useState('');
  const [recentJob, setrecentJob] = useState('');
  const [date_picker, setdate_picker] = useState([]);
  const [employment_type, setemployment_type] = useState('');
  const [location, setlocation] = useState('');
  const [description, setdescription] = useState('');
  const [jobId, setJobId] = useState('');
  const dateFormat = 'YYYY/MM/DD';

  const createJob = async () => {
    if (disabled) {
      try {
        const result = await userJobMutation({
          variables: {
            input: {
              company: recentCompany,
              startDate: date_picker[0].format('YYYY-MM-DDThh:mm:ss.sssZ'),
              title: recentJob,
              description: description,
              location: location,
              employment: employment_type,
            },
          },
        });
        setVisible5(false);
        refetch();
        console.log('result:', result);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      try {
        const result = await userJobMutation({
          variables: {
            input: {
              company: recentCompany,
              endDate: date_picker[1].format('YYYY-MM-DDThh:mm:ss.sssZ'),
              startDate: date_picker[0].format('YYYY-MM-DDThh:mm:ss.sssZ'),
              title: recentJob,
              description: description,
              location: location,
              employment: employment_type,
            },
          },
        });
        setVisible5(false);
        refetch();
        console.log('result:', result);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const updateJob = async () => {
    if (disabled) {
      try {
        const result = await updateUserJobMutation({
          variables: {
            input: {
              userJobId: jobId,
              company: recentCompany,
              startDate: moment(date_picker[0], 'YYYY-MM-DDThh:mm:ss.sssZ'),
              title: recentJob,
              description: description,
              location: location,
              employment: employment_type,
            },
          },
        });
        setVisible4(false);
        refetch();
        console.log('result:', result);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      try {
        const result = await updateUserJobMutation({
          variables: {
            input: {
              userJobId: jobId,
              company: recentCompany,
              endDate: moment(date_picker[1], 'YYYY-MM-DDThh:mm:ss.sssZ'),
              startDate: moment(date_picker[0], 'YYYY-MM-DDThh:mm:ss.sssZ'),
              title: recentJob,
              description: description,
              location: location,
              employment: employment_type,
            },
          },
        });
        setVisible4(false);
        refetch();
        console.log('result:', result);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const deleteJob = async () => {
    try {
      const result = await deleteUserJobMutation({
        variables: {
          input: {
            userJobId: jobId,
          },
        },
      });
      setVisible4(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const onSearch = (changeText) => {
    try {
      let res = [];
      if (
        typeof (
          data &&
          data.searchJobTitle &&
          data.searchJobTitle.jobTitles
        ) !== 'undefined'
      ) {
        setResult(
          (
            data &&
            data.searchJobTitle &&
            data.searchJobTitle.jobTitles
          ).substring(
            1,
            (data && data.searchJobTitle && data.searchJobTitle.jobTitles)
              .length - 1
          )
        );
        let nose = result.replace(/"/g, '');
        res = nose.split(',');
      }
      setOptions(!changeText ? [] : res);
    } catch (error) {
      console.log(error);
    }
  };
  const onChange = (changeText) => {
    setrecentJob(changeText);
    const searchJobTitleInput = {
      name: changeText,
    };

    try {
      getJobs({
        variables: { input: searchJobTitleInput },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onClickEdit = (jobEdit) => {
    jobs.map((job) => {
      if (job.userJobId === jobEdit) {
        setrecentCompany(job.company);
        setrecentJob(job.title);
        setdate_picker([job.startDate, job.endDate]);
        console.log(job.startDate);
        setemployment_type(job.employment);
        setlocation(job.location);
        setdescription(job.description);
        if (job.endDate == undefined) {
          setDisabled(true);
        }
        setJobId(jobEdit);
      }
    });
    setVisible4(true);
  };

  const onClickNew = () => {
    setDisabled(false);
    setVisible5(true);
  };

  const currentlyWorking = (changed) => {
    if (disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    console.log(disabled);
  };

  if (loading) {
    console.log('loading');
  }

  if (error) {
    console.log('error: ', error.message);
  }
  console.log(jobs);

  return (
    <div className={styles.Basic}>
      <img
        src={Plus}
        className={styles.plus}
        onClick={() => onClickNew()}
        alt=""
      />
      <div className={styles.title}>
        <p>Experience</p>
      </div>
      <div>
        {jobs.map((job) => (
          <Row key={job.userJobId}>
            <Col xs={8} sm={12} md={12} lg={12} className={styles.description}>
              <h3>{job.title}</h3>
              <h4>
                {job.company} - {job.employment}
              </h4>
              <h5>
                {moment(job.startDate).format('MMMM Do YYYY')} -{' '}
                {moment(job.endDate).format('MMMM Do YYYY')}
              </h5>
              <h5 className={styles.h5margin}>{job.location}</h5>
            </Col>
            <div className={styles.editContainer}>
              <img
                src={Edit}
                className={styles.edit}
                onClick={() => onClickEdit(job.userJobId)}
                alt=""
              />
            </div>
          </Row>
        ))}
      </div>
      <Modal
        title="New experience"
        centered
        visible={newExperience}
        onOk={() => setVisible5(false)}
        onCancel={() => setVisible5(false)}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={() => createJob()}>
            Save
          </Button>,
        ]}
      >
        <h4 style={{ marginBottom: '1rem' }}>Title</h4>
        <AutoComplete
          value={recentJob}
          style={{ marginBottom: '1rem', width: '32rem', height: '20%' }}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="Job title"
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </AutoComplete>
        <h4 style={{ marginBottom: '1rem' }}>Employment type</h4>
        <Select
          name="type"
          id="type"
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setemployment_type(e)}
        >
          <Option value="Full time">Full time</Option>
          <Option value="Part time">Part time</Option>
          <Option value="Self-employed">Self-employed</Option>
          <Option value="Freelance">Freelance</Option>
          <Option value="Contract">Contract</Option>
          <Option value="Internship">Internship</Option>
          <Option value="Apprenticeship">Apprenticeship</Option>
          <Option value="Seasonal">Seasonal</Option>
        </Select>
        <h4 style={{ marginBottom: '1rem' }}>Company</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setrecentCompany(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Location</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setlocation(e.target.value)}
        />
        <Checkbox onChange={currentlyWorking} className={styles.container}>
          I am currently working in this role
        </Checkbox>
        <h4 style={{ marginBottom: '1rem' }}>Start Date - End Date</h4>
        <Space direction="vertical" size={12}>
          <RangePicker
            size="large"
            disabled={[false, disabled]}
            disabledDate={disabledDate}
            onChange={(e) => setdate_picker(e)}
          />
        </Space>
        <h4 className={styles.descriptionModal}>Description</h4>
        <TextArea
          rows={5}
          className={styles.descriptionTextArea}
          onChange={(e) => setdescription(e.target.value)}
        />
      </Modal>
      <Modal
        title="Edit experience"
        centered
        className="experienceModal"
        visible={editExperience}
        onOk={() => setVisible4(false)}
        onCancel={() => setVisible4(false)}
        width={1000}
        footer={[
          <Button key="update" type="primary" onClick={() => updateJob()}>
            Update
          </Button>,
          <Button key="delete" type="primary" onClick={() => deleteJob()}>
            Delete
          </Button>,
        ]}
      >
        <h4 style={{ marginBottom: '1rem' }}>Title</h4>
        <AutoComplete
          value={recentJob}
          style={{ marginBottom: '1rem', width: '32rem', height: '20%' }}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="Job title"
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </AutoComplete>
        <h4 style={{ marginBottom: '1rem' }}>Employment type</h4>
        <Select
          name="type"
          id="type"
          style={{ marginBottom: '1rem', width: '32rem' }}
          defaultValue={employment_type}
          onChange={(e) => setemployment_type(e)}
        >
          <Option value="Full time">Full time</Option>
          <Option value="Part time">Part time</Option>
          <Option value="Self-employed">Self-employed</Option>
          <Option value="Freelance">Freelance</Option>
          <Option value="Contract">Contract</Option>
          <Option value="Internship">Internship</Option>
          <Option value="Apprenticeship">Apprenticeship</Option>
          <Option value="Seasonal">Seasonal</Option>
        </Select>
        <h4 style={{ marginBottom: '1rem' }}>Company</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          value={recentCompany}
          onChange={(e) => setrecentCompany(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Location</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          value={location}
          onChange={(e) => setlocation(e.target.value)}
        ></Input>
        <Checkbox onChange={currentlyWorking} className={styles.container}>
          I am currently working in this role
        </Checkbox>
        <h4 style={{ marginBottom: '1rem' }}>Start Date - End Date</h4>
        <Space direction="vertical" size={12}>
          <RangePicker
            size="large"
            disabled={[false, disabled]}
            defaultValue={[
              moment(date_picker[0], dateFormat),
              moment(date_picker[1], dateFormat),
            ]}
            format={dateFormat}
            disabledDate={disabledDate}
            onChange={(e) => setdate_picker(e)}
          />
        </Space>
        <h4 className={styles.descriptionModal}>Description</h4>
        <TextArea
          rows={5}
          className={styles.descriptionTextArea}
          onChange={(e) => setdescription(e.target.value)}
          value={description}
        />
      </Modal>
    </div>
  );
};

export default Experience;
