import React from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Checkbox, AutoComplete, Col, Row, Button } from 'antd'

const  AboutCompany2 =({goNextStep, formRef})=>{
  const options = [
            {value:'Admin'},
            {value:'HR/Talent Professional'},
            {value:'Manager/Head of Department'},
            {value:'Director/VP'},
            {value:'C Level'},
            {value:'Business Owner'},
            {value:'Human Resources at Studio'},
            {value:'other'}
          ]
    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
     
    span={20}
    >    
    <Row justify='center'>
        
        <h2 className={styles.title}>Tell us about your company</h2>
          
        </Row>

    <Row justify='center'>
        <Col
        span={20}
        >

    <Form
      name="basic"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="What's your role in the hiring process?"
        name="role"
        rules={[{ required: true, message: 'Please select an option!' }]}
      >
        <AutoComplete
       options={options}
       >
         </AutoComplete>
      </Form.Item>

      <Form.Item
        label="What job title you're recruiting for?"
        name="jobtitle"
        rules={[{ required: true, message: 'Please select an option!' }]}
      >
        <Input/>
      </Form.Item>

      

     

      <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        onClick={(e)=>goNextStep(e)}>
         CONTINUE
        </Button>
      </Form.Item>
    </Form>
        </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default AboutCompany2