import React from 'react';
import style from './HeadingComponents.module.scss';
import PrivacyIconSVG from '../img/privacy.svg';

const PrivacyHeading = () => {
	return (
		<div>
			<div className={style.contentHeader}>
				<img src={PrivacyIconSVG}/>
				<p className={style.titleText}>Privacy</p>
			</div>
			<p className={style.uppercaseText}>Your profile​</p>
		</div>
	);
};

export default PrivacyHeading;