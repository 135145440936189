import { gql } from '@apollo/client';
/*
export const PARSE_LINKEDIN = gql `
query parseLinkedInCode($input: String!){
    parseLinkedInCode(input: $input){
    Email
    FirstName
    LastName
    ProfilePictures {
      Url_800_800
    }
  }
}
`*/
export const PARSE_LINKEDIN = gql`
  query PARSE_LINKEDIN($input: LinkedInInput!) {
    parseLinkedInCode(input: $input) {
      FirstName
      LastName
      Email
      ProfilePictures {
        Url_100_100
        Url_200_200
        Url_400_400
        Url_800_800
      }
      CurrentJob {
        Title
        Company
        StartDate
        EndDate
      }
    }
  }
`;
