import React from 'react';
import style from './BottomCard.module.scss';
import { useTranslation } from 'react-i18next';
import linkedInSVG from '../img/newLinkedIn.svg';

const BottomCard = ({ name, title, image, link }) => {
  const { t } = useTranslation('');
  return (
    <div className={style.bottomCardContainer}>
      <div className={style.imgContainer}>
        <img src={image} alt="container" />
      </div>
      <div className={style.textContainer}>
        <p className={style.name}>{t(name)}</p>
        <p className={style.title}>{t(title)}</p>
        <a href={link} target='_blank' rel="noopener noreferrer">
          <img src={linkedInSVG} className={style.icon} alt=""/>
        </a>
      </div>
    </div>
  );
};

export default BottomCard;
