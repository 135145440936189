import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input, Breadcrumb, Empty, Divider } from 'antd';
import SearchBar from '../../components/Dashboard/SearchBar/SearchBar';
import LandingFooter from '../../components/Landing/Footer/Footer';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import styles from './Jobs.module.scss';

const Jobs = ({ location }) => {
  const [locationInputValue, setLocation] = useState('');
  const [jobInputValue, setJob] = useState('');

  const onChangeLocation = (e) => {
    setLocation(e.target.value);
  };
  const onChangeJobs = (e) => {
    setJob(e.target.value);
  };

  useEffect(() => {
    setLocation(location?.jobsProps?.location);
    setJob(location?.jobsProps?.jobTitle);
  }, []);
  return (
    <div className={styles.Jobs}>
      <header className={styles.header}>
        <LoggedInHeader />
        <SearchBar />
      </header>
      <main>
        <div className={styles.SearchArea}>
          <Row>
            <Col span={5} offset={1}>
              <Breadcrumb separator="|">
                <Breadcrumb.Item className={styles.Breadcrumb}>
                  <span>Jobs</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item className={styles.Breadcrumb}>
                  <span>Casting Calls</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row justify="center" className={styles.Searcher}>
            <Col justify="center">
              <Row justify="center" gutter={[16, 16]}>
                <h1>Start living your best career life</h1>
              </Row>
              <Row justify="center" gutter={[8, 32]}>
                <h3>
                  Discover your next career move, intership, freelance or
                  casting call
                </h3>
              </Row>

              <Row justify="center" align="middle" gutter={[32, 32]}>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    value={jobInputValue}
                    size="large"
                    placeholder="Company or position"
                    prefix="What?"
                    onChange={(e) => onChangeJobs(e)}
                  />
                </Col>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    value={locationInputValue}
                    size="large"
                    placeholder="Region or City"
                    prefix="Where?"
                    onChange={(e) => onChangeLocation(e)}
                  />
                </Col>
                <Col>
                  <Button type="primary" shape="round" size="large">
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row justify="center" align="middle">
          <Divider orientation="left">Found jobs</Divider>
          <Empty />
        </Row>
      </main>
      <footer
        className={styles.landingFooterContainer}
        style={{ marginTop: '5rem' }}
      >
        <LandingFooter />
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <div style={{ width: '18rem', height: '11rem' }} className="logo" />
          </div>
          <div className={styles.logoContainer}>
            <div>COPYRIGHT © 2020 LINKREASE</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Jobs;
