import React from 'react';
import { Col, Row } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SearchBar from '../../components/Dashboard/SearchBar/SearchBar';
import LandingFooter from '../../components/Landing/Footer/Footer';
import CompactFooter from '../../components/PrivacyTermsFooter/PrivacyTermsFooter';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import BasicInfo from '../../components/Profile/BasicInfo/BasicInfo';
import AboutMe from '../../components/Profile/AboutMe/AboutMe';
import Education from '../../components/Profile/Education/Education';
import Experience from '../../components/Profile/Experience/Experience';
import Awards from '../../components/Profile/Awards/Awards';
import styles from './Profile.module.scss';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/users';
import Default from '../../img/no-img.png';

import CustomSkeleton from '../../components/Skeleton/Skeleton';

const Profile = () => {
  const {
    userData: loggedUserData,
    status: sessionStatus,
    isAuthenticated,
  } = useSelector((state) => state.session);
  const history = useHistory();
  const { id: urlUserId } = useParams();
  let isOwnProfile = null;
  let dataSource = null;

  if (
    (!isAuthenticated && sessionStatus === 'done') ||
    sessionStatus === 'rejected'
  ) {
    history.push('/login');
  }

  if (urlUserId === loggedUserData?.userId) {
    isOwnProfile = true;
  }

  const {
    loading,
    error,
    data: getUserData,
  } = useQuery(GET_USER, {
    variables: {
      input: { userId: urlUserId },
    },
  });

  dataSource = getUserData?.getUser;

  const profilePic =
    dataSource?.profileImage == null
      ? Default
      : dataSource?.profileImage?.split(', ');

  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');

  if (loading || sessionStatus === 'loading') {
    return <CustomSkeleton />;
  }

  if (error) {
    console.log('error: ', error.message);
  }

  return (
    <div className={styles.Profile}>
      <header className={styles.header}>
        <LoggedInHeader />
        <SearchBar />
      </header>
      <main>
        <Row justify="center">
          <Col
            className={styles.contentColumn}
            xs={24}
            md={23}
            lg={23}
            xl={20}
            xxl={12}
          >
            <BasicInfo
              userData={dataSource}
              profilePic={smallImage}
              isOwnProfile={isOwnProfile}
            />
            {isOwnProfile && (
              <>
                <AboutMe aboutMe={dataSource?.aboutMe || ''} />
                <Experience jobs={dataSource?.jobs} />
                <Education educations={dataSource?.educations} />
                <Awards awards={dataSource?.awards} />
              </>
            )}
          </Col>
        </Row>
      </main>
      <footer className={styles.landingFooterContainer}>
        <LandingFooter />
        <CompactFooter />
      </footer>
    </div>
  );
};

export default Profile;
