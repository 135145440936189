import React from 'react';
import style from './HeadingComponents.module.scss';
import MemberAccountIconSVG from '../img/memberAccount.svg';

const MemberAccountHeading = () => {
	return (
		<div>
			<div className={style.contentHeader}>
				<img src={MemberAccountIconSVG}/>
				<p className={style.titleText}>Member Account</p>
			</div>
			<p className={style.uppercaseText}>Login Details</p>
		</div>
	);
};

export default MemberAccountHeading;