export const professions = [
  'Actors / Actresses',
  'Amateur Talent',
  'Animators',
  'Acquisitions Executives',
  'Artists',
  'Art Directors',
  'Assistants',
  'Backers',
  'Brands',
  'Camera Operators',
  'Choreographers',
  'Creators',
  'Comic / Comedian',
  'Composers',
  'Coordinators',
  'Costume Designers',
  'Creative Consultants',
  'Dancers',
  'Development Executives',
  'Digital Artists',
  'Distribution Executives',
  'Editors',
  'Engineers',
  'Festivals Crew',
  'Finance Executives',
  'Freelancers',
  'Graphic Designers',
  'Influencers',
  'Illustrators',
  'Journalists',
  'Legal / Lawyers',
  'Location Coordinators',
  'Make Up Artists',
  'Marketing Executives',
  'Models',
  'Performers',
  'Producer',
  'Project Managers',
  'Writers',
  'Videographers',
  'Photographers',
  'Post-Production ',
  'Publicists',
  'Sales Executives',
  'Software Developers',
  'Sound Crew',
  'Sports Agents',
  'Storyboard Artists',
  'Studios',
  'Stunt Coordinators',
  'Talent Agents',
  'Visual Effects',
];

export const firstSetTexts = [
  'attachingYourName',
  'participatingWithCreators',
  'resultingIn',
  'industryDecisionMakers',
  'fundingDistributionDeals',
  'exponentialIncrease',
];

export const secondSetTexts = [
  'connectedToVetted',
  'collaboratingWithYou',
  'collaboratingWithYourProj',
  'curatedGradeProjects',
  'vettedCrowdfundingRounds',
];

export const thirdSetTexts = [
  'attachingYourNameEntertain',
  'collaboratingProjectTeams',
  'sharingProjectsYouSponsor',
  'reachOutToFollowers',
];