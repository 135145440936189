import Logo  from '../../../img/LRLogoDark.png'
export const dataJobs=[
    {
        title:'Film Producer',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Applied',

    },
    {
        title:'Sound Engenieer',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Closed'

    },
    {
        title:'Film Director',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Active'

    },
    {
        title:'SFX Artist',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Draft'

    },
    {
        title:'MakeUp Artist',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Active'

    },
    {
        title:'Composer',
        logo:Logo,
        company:"Linkrease Technologies LLC",
        location: 'Miami, FL, USA',
        vertical:'Film',
        type:'Active'

    },
]