export function generateCompanyColumn() {
  return [
    {
      href: '/about',
      title: 'aboutUs',
    },
    {
      href: '/about#leadership',
      title: 'leadership',
    },
    {
      href: '#',
      title: 'press',
    },
    {
      href: '#',
      title: 'careers',
    },
    {
      href: '/contact',
      title: 'contactUs',
    },
  ];
}
export function generateCommunityColumn() {
  return [
    {
      href: '#',
      title: 'signUp',
    },
    {
      href: '#',
      title: 'members',
    },
    {
      href: '#',
      title: 'guidelines',
    },
    {
      href: '#',
      title: 'support',
    },
    {
      href: '#',
      title: 'linkreaseForStudents',
    },
  ];
}
export function generateProductColumn() {
  return [
    {
      href: '#',
      title: 'howItWorks',
    },
    {
      href: '#',
      title: 'features',
    },
    {
      href: '#',
      title: 'pricing',
    },
    {
      href: '#',
      title: 'crowdfunding',
    },
  ];
}
export function generateBusinessSolutionColumn() {
  return [
    {
      href: '#',
      title: 'talent',
    },
    {
      href: '#',
      title: 'marketing',
    },
    {
      href: '#',
      title: 'sales',
    },
  ];
}
export function generatePartnersColumn() {
  return [
    {
      href: '#',
      title: 'streaming',
    },
    {
      href: '#',
      title: 'filmAndTVStudios',
    },
    {
      href: '#',
      title: 'distributors',
    },
  ];
}
