import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import axios from 'axios';

export const getJobs = createAsyncThunk(
  'jobs/getJobs',
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/jobs`);

      if (status === 200) {
        return data;
      }

      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    } catch (err) {
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);
