import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import Styles from './Feed.module.scss';

import ActionButton from '../ActionButton/ActionButton';
import Cards from '../Cards/Cards';

const Feed = (props) => {

  const [activeSection, setActiveSection] = useState('feed');

  return (
    <div className={Styles.feedContainer}>
      <Row
        className={Styles.buttonRow}
        gutter={[8, 8]}
        justify="center"
      >
        <Col
          flex="auto"
          className={activeSection  === 'feed' ? Styles.activeSection : Styles.defaultBorderBottom}
        >
          <Button
            className={Styles.noStyledButton}
            onClick={ () => setActiveSection('feed') }
          >
            Feed
          </Button>
        </Col>
        <Col
          flex="auto"
          className={activeSection === 'connections' ? Styles.activeSection : Styles.defaultBorderBottom}
        >
          <Button
            className={Styles.noStyledButton}
            onClick={ () => setActiveSection('connections') }
          >
            Connections
          </Button>
        </Col>
        <Col
          flex="auto"
          className={activeSection === 'campaigns' ? Styles.activeSection : Styles.defaultBorderBottom}
        >
          <Button
            className={Styles.noStyledButton}
            onClick={ () => setActiveSection('campaigns') }
          >
            Campaigns
          </Button>
        </Col>
        <Col
          flex="auto"
          className={activeSection === 'submissions' ? Styles.activeSection : Styles.defaultBorderBottom}
        >
          <Button
            className={Styles.noStyledButton}
            onClick={ () => setActiveSection('submissions') }
          >
            Submissions
          </Button>
        </Col>
      </Row>
      <ActionButton />
      <Cards type={activeSection} />
    </div>
  );
};

export default Feed;