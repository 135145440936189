import { createAsyncThunk } from '@reduxjs/toolkit';
import { SIGN_IN, SIGN_UP } from '../../graphql/identity';
import { GET_LOGGED_USER } from '../../graphql/users';
import client from '../../graphql/client';

export const getLoggedUser = createAsyncThunk(
  'session/getLoggedUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query({
        query: GET_LOGGED_USER,
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.getCurrentUser;
    } catch (err) {
      console.log('thunk error', err.message);
      return rejectWithValue(err);
    }
  }
);

export const signUp = createAsyncThunk(
  'register/signUp',
  async (data, { rejectWithValue }) => {
    const { email, password, familyName, givenName } = data;
    try {
      const { data, errors } = await client.mutate({
        mutation: SIGN_UP,
        variables: {
          input: {
            credential: { email, password },
            user: { email, family_name: familyName, given_name: givenName },
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.signUp?.session;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const signIn = createAsyncThunk(
  'register/signIn',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const { data, errors } = await client.mutate({
        mutation: SIGN_IN,
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.signIn;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);
