import React, {useEffect, useState} from 'react';
import { Row, Col, Radio ,Space, Divider } from 'antd';
import CardJobs from './component/CardJobs';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import LandingFooter from '../../components/Landing/Footer/Footer';
import styles  from './ManageJobs.module.scss';
import {dataJobs} from './component/data';

const ManageJobs = () =>{
const [typeJobs, setTypeJobs] = useState('Active');
const [data, setData]= useState([{}]);
const [section, setSection]= useState('Posted Jobs');

useEffect(()=>{
    setData(dataJobs.filter(job=>job.type===typeJobs))
    switch (typeJobs){
        case ('Active' || 'Closed' || 'Draft') :
            {
                setSection('Posted Jobs')
                break;
            }
        case ('Applied' || 'Saved'):{
            setSection('My jobs')
            break;
        }
    }
},[typeJobs])

const onChange=(e)=>{
    console.log('radio checked', e.target.value);
    console.log('type',typeJobs)
    setTypeJobs(e.target.value)
    const filterData=dataJobs.filter(job=>job.type===typeJobs)
    setData(filterData)
    console.log('the data',data)

}
    return(
        <div>
        <header>
            <LoggedInHeader/>
        </header>
        <main style={{paddingTop:'5rem',paddingBottom:'5rem'}}>
            <Row>
                <Col offset={1} span={5}>
                    <div className={styles.box}>
                 <span className={styles.mainTitle}>My jobs items</span>
                 <Row>
                     <Col>
                        <Radio.Group onChange={onChange} value={typeJobs}>
                        <Space direction="vertical">
                         <span className={styles.secundaryTitle}>Posted jobs</span>
                        <Radio value="Active">Active jobs</Radio>
                        <Radio value="Closed">Closed jobs</Radio>
                        <Radio value="Draft">Draft jobs</Radio>
                        <Divider />
                        <span className={styles.secundaryTitle}>My jobs</span>
                        <Radio value="Applied">Applied for</Radio>
                        <Radio value="Saved">Saved</Radio>
                        
                        </Space>
                        </Radio.Group>
                        </Col>
                        <Col>
                        <Space direction="vertical">
                        <span>5</span>
                        <span>5</span>
                        <span>5</span>
                        <span>5</span>
                        </Space>
                        </Col>
                </Row>  
                    </div>
                </Col>
                <Col offset={1} span={10}>
                    <div className={styles.box}>
                        <h3>{section} | {typeJobs}</h3>
                        {CardJobs(data)}
                    </div>
                </Col>
            </Row>
        </main>
        <footer>
            <LandingFooter/>
            <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <div style={{ width: '18rem', height: '11rem' }} className="logo" />
          </div>
          <div className={styles.logoContainer}>
            <div>COPYRIGHT © 2020 LINKREASE</div>
          </div>
        </div>
      
        </footer>
        </div>
    )
}

export default ManageJobs;