import React from 'react';
import styles from './DCMAPolicy.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import CorpNav from '../CorpNav/CorpNav';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';
const DCMAPolicy = () => {
  return (
    <div className={styles.div}>
      <CorpNav />
      <div className={styles.banner__headline_container}>
        <div className={styles.banner__position}>
          <h1 className={styles.banner__headline_t_64}>DCMA Policy</h1>
        </div>
      </div>

      <div className={styles.content}>
        <p>
          This Digital Millennium Copyright Act policy (&quot;Policy&quot;)
          applies to the{' '}
          <a target="_blank" rel="nofollow" href="https://www.linkrease.com">
            linkrease.com
          </a>{' '}
          website (&quot;Website&quot;), &quot;Linkrease&quot; mobile
          application (&quot;Mobile Application&quot;) and any of their related
          products and services (collectively, &quot;Services&quot;) and
          outlines how Linkrease Technologies, LLC (&quot;Linkrease
          Technologies, LLC&quot;, &quot;we&quot;, &quot;us&quot; or
          &quot;our&quot;) addresses copyright infringement notifications and
          how you (&quot;you&quot; or &quot;your&quot;) may submit a copyright
          infringement complaint.
        </p>
        <p>
          Protection of intellectual property is of utmost importance to us and
          we ask our users and their authorized agents to do the same. It is our
          policy to expeditiously respond to clear notifications of alleged
          copyright infringement that comply with the United States Digital
          Millennium Copyright Act (&quot;DMCA&quot;) of 1998, the text of which
          can be found at the U.S. Copyright Office{' '}
          <a target="_blank" href="https://www.copyright.gov" rel="noopener">
            website
          </a>
          .
        </p>
        <h2 className={styles.h2}>
          What to consider before submitting a copyright complaint
        </h2>
        <p>
          Before submitting a copyright complaint to us, consider whether the
          use could be considered fair use. Fair use states that brief excerpts
          of copyrighted material may, under certain circumstances, be quoted
          verbatim for purposes such as criticism, news reporting, teaching, and
          research, without the need for permission from or payment to the
          copyright holder. If you have considered fair use, and you still wish
          to continue with a copyright complaint, you may want to first reach
          out to the user in question to see if you can resolve the matter
          directly with the user.
        </p>
        <p>
          Please note that under 17 U.S.C. § 512(f), you may be liable for any
          damages, including costs and attorneys’ fees incurred by us or our
          users, if you knowingly misrepresent that the material or activity is
          infringing. If you are unsure whether the material you are reporting
          is in fact infringing, you may wish to contact an attorney before
          filing a notification with us.
        </p>
        <p>
          We may, at our discretion or as required by law, share a copy of your
          notification or counter-notification with third parties. This may
          include sharing the information with the account holder engaged in the
          allegedly infringing activity or for publication. If you are concerned
          about your information being forwarded, you may wish to{' '}
          <a
            target="_blank"
            href="https://www.copyrighted.com/professional-takedowns"
            rel="noopener"
          >
            hire an agent
          </a>{' '}
          to report infringing material for you.
        </p>
        <h2 className={styles.h2}>Notifications of infringement</h2>
        <p>
          If you are a copyright owner or an agent thereof, and you believe that
          any material available on our Services infringes your copyrights, then
          you may submit a written copyright infringement notification
          (&quot;Notification&quot;) using the contact details below pursuant to
          the DMCA by providing us with the following information:
        </p>
        <ul className={styles.ul}>
          <li>
            Identification of the copyrighted work that you claim has been
            infringed, or, if multiple copyrighted works are covered by this
            Notification, you may provide a representative list of the
            copyrighted works that you claim have been infringed.
          </li>
          <li>
            Identification of the infringing material and information you claim
            is infringing (or the subject of infringing activity), including at
            a minimum, if applicable, the URL or URLs of the web pages where the
            allegedly infringing material may be found.
          </li>
          <li>
            Information reasonably sufficient to permit us to contact you, such
            as an address, telephone number, and, if available, an e-mail
            address.
          </li>
          <li>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, the copyright owner's agent, or the law.
          </li>
          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </li>
          <li>
            A physical or electronic signature (typing your full name will
            suffice) of the copyright owner or a person authorized to act on
            their behalf.
          </li>
        </ul>
        <p>
          All such Notifications must comply with the DMCA requirements. You may
          refer to a{' '}
          <a
            target="_blank"
            href="https://www.websitepolicies.com/create/dmca-takedown-notice"
          >
            DMCA takedown notice generator
          </a>{' '}
          or other similar services to avoid making mistake and ensure
          compliance of your Notification.
        </p>
        <p>
          Filing a DMCA complaint is the start of a pre-defined legal process.
          Your complaint will be reviewed for accuracy, validity, and
          completeness. If your complaint has satisfied these requirements, our
          response may include the removal or restriction of access to allegedly
          infringing material as well as a permanent termination of repeat
          infringers’ accounts. A backup of the terminated account’s data may be
          requested, however it may be subject to certain penalty fees imposed.
          The final penalty fee will be determined by the severity and frequency
          of the violations.
        </p>
        <p>
          If we remove or restrict access to materials or terminate an account
          in response to a Notification of alleged infringement, we will make a
          good faith effort to contact the affected user with information
          concerning the removal or restriction of access, which may include a
          full copy of your Notification (including your name, address, phone,
          and email address).
        </p>
        <p>
          Notwithstanding anything to the contrary contained in any portion of
          this Policy, Linkrease Technologies, LLC reserves the right to take no
          action upon receipt of a DMCA copyright infringement notification if
          it fails to comply with all the requirements of the DMCA for such
          notifications.
        </p>
        <p>
          The process described in this Policy does not limit our ability to
          pursue any other remedies we may have to address suspected
          infringement.
        </p>
        <h2 className={styles.h2}>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Policy or its terms relating to
          the Services at any time, effective upon posting of an updated version
          of this Policy on the Services. When we do, we will send you an email
          to notify you.
        </p>
        <h2 className={styles.h2}>Reporting copyright infringement</h2>
        <p>
          If you would like to notify us of the infringing material or activity,
          you may send an email to
          &#115;u&#112;p&#111;r&#116;&#64;linkre&#97;se.&#99;&#111;m.
        </p>
        <p>This document was last updated on June 2, 2021</p>
      </div>
      <div className={styles.landingFooterContainer}>
        <LandingFooter />
        <PrivacyTermsFooter />
      </div>
    </div>
  );
};

export default DCMAPolicy;
