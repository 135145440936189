import React from 'react';
import { Row, Col } from 'antd';
import Styles from '../Cards.module.scss';

import campaign from '../img/campaign-sample.jpg';

const Campaign = (props) => {
  return (
    <div className={`${Styles.submissionCard} ${Styles.cardBorderShadow}`}>
      <Row align="top" gutter={[12, 12]}>
        <Col span={4}>
          <img src={campaign} className={`${Styles.submissionImage} img-responsive`} alt="profile" />
        </Col>
        <Col span={19}>
          <Row align="top" gutter={[12, 12]}>
            <Col span={10}>
              <h5 className={Styles.connectionTitle}>
                Pugatory
              </h5>
              <h6 className={Styles.campaignSubTitle}>Horror Feature English</h6>
              <h6 className={Styles.campaignSubTitle}>Locations: Miami, FL, US</h6>
            </Col>
            <Col span={13}>
              <div className={Styles.borderExtraMargin}>
                <h5 className={Styles.connectionTitle}>
                  Status
                </h5>
                <h6 className={Styles.campaignSubTitle}>Script / In Development</h6>
                <h6 className={Styles.campaignSubTitle}>Listed by Guillermo Mazzoni</h6>
              </div>
            </Col>
            <Col>
              <p className={Styles.submissionInfoParagraph}>Someone from your network just applied to be part of this project.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Campaign;
