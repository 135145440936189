import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './IntroText.module.scss';

const IntroText = ({ customAccentText, customText }) => {
  const { t } = useTranslation('landing');

  return (
    <>
      <h1 className={`${Styles.titleText}`}>{t(customAccentText)}</h1>
      <h3 className={`${Styles.text}`}>{t(customText)}</h3>
    </>
  );
};

export default IntroText;
