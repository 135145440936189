import React from 'react';
import style from './MainSettings.module.scss';
import MemberAccount from './ContentComponents/MemberAccount';
import MemberAccountHeading from './HeadingComponents/MemberAccountHeading';
import Privacy from './ContentComponents/Privacy';
import PrivacyHeading from './HeadingComponents/PrivacyHeading';
import Notifications from './ContentComponents/Notifications';
import NotificationsHeading from './HeadingComponents/NotificationsHeading';
import Purchases from './ContentComponents/Purchases';
import PurchasesHeading from './HeadingComponents/PurchasesHeading';


const MainSettings= ({settingsArea}) => {
	let renderMainSettings;
	
	switch(settingsArea){
		case 'memberAccount':{
			renderMainSettings = 
				<div>
					<MemberAccountHeading />
					<MemberAccount />
				</div>
		};
		break
		case 'privacy':{
			renderMainSettings = 
				<div>
					<PrivacyHeading />
					<Privacy />
				</div>
		};
		break
		case 'notifications':{
			renderMainSettings = 
				<div>
					<NotificationsHeading />
					<Notifications />
				</div>
		};
		break
		case 'purchases':{
			renderMainSettings = 
				<div>
					<PurchasesHeading />
					<Purchases />
				</div>
		}
	}
	return (
		<div className={style.mainSettings}>
			
			{renderMainSettings}		
			
		</div>
	);
};

export default MainSettings;