import React from 'react';
import {
  Layout,
  Row,
  Col,
  Button,
  Tag,
  Tabs,
  Input,
  Divider,
  AutoComplete,
  Select,
  Breadcrumb,
} from 'antd';
import { Link } from 'react-router-dom';
import Icon, {
  SearchOutlined,
  EnvironmentOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import styles from './Landing.module.scss';
import LandingFooter from '../../components/Landing/Footer/Footer';
import CompactFooter from '../../components/FooterColumn/FooterColumn';
import LandingNav from '../../components/Landing/Nav/LandingNav';
import EntertainmentVertical from '../../components/Landing/EntertainmentVertical/EntertainmentVertical';
import IntroText from '../../components/Landing/IntroText/IntroText';
import ForCards from '../../components/Landing/Cards/ForCards';
import LandingHeader from '../../components/Landing/Header/LandingHeader';
import JobsSearcher from '../../components/JobSearcher/jobSearcher';
import logoLinkrease from '../../img/LRLogoDark.png';

// temp
//import dataJson from './linkedindata.json'
import { PARSE_LINKEDIN } from '../../graphql/parseLinkedin/index';

// Images and Icons
import { ReactComponent as linkedInSVG } from './img/newLinkedIn.svg';
import { ReactComponent as googleSVG } from './img/newGoogle.svg';
import { ReactComponent as fbSVG } from './img/newFb.svg';
import { ReactComponent as twitterSVG } from './img/newTwitter.svg';
import leftTalkingManSVG from './img/leftTalkingMan.svg';
import rightTalkingManSVG from './img/rightTalkingMan.svg';
import collaborationSVG from './img/collaboration.png';
import crowdfundSVG from './img/crowdfund.png';
import happyFaceImage from './img/happyface.png';
import logo from './img/people.png';
import searchVector from './img/searchVector.png';
import Popup from '../../components/CookiePopup/Popup';
import { Modal } from 'antd';
import { SEARCH_JOB_TITLE } from '../../graphql/jobTitles/index';
import { useLazyQuery } from '@apollo/client';

// Data
import {
  professions,
  firstSetTexts,
  secondSetTexts,
  thirdSetTexts,
} from './data.js';
import CookiePolicy from '../../components/CookiePolicy/CookiePolicy';
import PrivacyTermsFooter from '../../components/PrivacyTermsFooter/PrivacyTermsFooter';

const { Content, Footer } = Layout;
const { TabPane } = Tabs;
const { useState, useEffect } = React;
const { Option } = Select;

const Landing = () => {
  const { t } = useTranslation('landing');
  const [collapsed, setCollapsed] = useState(false);
  const [linkedinUserData, setLinkedinUser] = useState('');
  const [fetchData, setFetchData] = useState([]);
  const socialIcons = [linkedInSVG, googleSVG, fbSVG, twitterSVG];
  const firstTextCard = firstSetTexts.map((text) => t(text));
  const secondTextCard = secondSetTexts.map((text) => t(text));
  const thirdTextCard = thirdSetTexts.map((text) => t(text));
  const [timedPopup, setTimedPopup] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cookiePolicy, setVisible5] = useState(false);
  const [getJobs, { loadingJob, errorJob, dataJob }] =
    useLazyQuery(SEARCH_JOB_TITLE);
  const [value, setValue] = useState('');
  const [result, setResult] = useState('');
  const [options, setOptions] = useState([]);
  const [happyFace, sethappyFace] = useState(false);
  const [happyFaceprofessions, sethappyFaceprofessions] = useState('');

  const clickHappyFace = (f) => {
    sethappyFace(true);
    sethappyFaceprofessions(f);
  };
  const onChangeLocation = (e) => {
    setCountry(e.target.value);
    e.preventDefault();
    console.log(country);
  };

  function getCloseLocation() {
    var location = new XMLHttpRequest();
    location.responseType = 'json';
    location.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        setCountry(location.response.country_name);
        setState(location.response.region_name);
        setPostalCode(location.response.zip_code);
      }
    };
    location.open('GET', 'https://freegeoip.app/json/');
    //https://geolocation-db.com/
    //https://ipstack.com/
    location.send();
    console.log(country, state, postalCode);
  }

  const onSearch = (changeText) => {
    try {
      let res = [];
      if (
        typeof (
          dataJob &&
          dataJob.searchJobTitle &&
          dataJob.searchJobTitle.jobTitles
        ) !== 'undefined'
      ) {
        setResult(
          (
            dataJob &&
            dataJob.searchJobTitle &&
            dataJob.searchJobTitle.jobTitles
          ).substring(
            1,
            (
              dataJob &&
              dataJob.searchJobTitle &&
              dataJob.searchJobTitle.jobTitles
            ).length - 1
          )
        );
        let nose = result.replace(/"/g, '');
        res = nose.split(',');
      }
      setOptions(!changeText ? [] : res);
    } catch (error) {
      console.log(error);
    }
  };
  const onChange = (changeText) => {
    setValue(changeText);
    const searchJobTitleInput = {
      name: changeText,
    };

    try {
      getJobs({
        variables: { input: searchJobTitleInput },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  if (loadingJob) {
    console.log('loading');
  }

  if (errorJob) {
    console.log('error: ', error.message);
  }

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(true);
      getCloseLocation();
    }, 3000);
  }, []);
  const [getLinkedin, { loading, error, data }] = useLazyQuery(PARSE_LINKEDIN);

  useEffect(() => {
    localStorage.setItem('linkedinData', '');

    const url = window.location.href;
    if (url.split('?')[1] != undefined) {
      let urlCode = url.split('?')[1].split('&')[0].split('=')[1];
      queryCall(urlCode);
    }
  }, [linkedinUserData, data]);

  // useEffect(()=>{
  //   data && setFetchData([...fetchData,data])
  // },[data])

  if (loading) console.log('loanding...');
  if (error) console.log(error.message);
  console.log('data--', fetchData);
  if (fetchData.length > 0)
    console.log('type: ', fetchData[0].parseLinkedInCode.__typename);

  const queryCall = async (urlCode) => {
    try {
      getLinkedin({
        variables: { input: { authCode: urlCode } },
      });
    } catch (error) {
      console.log(error);
    }
    data && setFetchData([...fetchData, data]);
    if (data != undefined) {
      setLinkedinUser(data.parseLinkedInCode);

      await localStorage.setItem(
        'linkedinData',
        JSON.stringify(linkedinUserData)
      );
      window.location.href = '/register-vertical';
    }
  };

  const socialLogin = (index) => {
    if (index === 0) {
      console.log('linkidein');
      window.location.href =
        'https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile&client_id=86u4otk5sye6lv&redirect_uri=https%3A%2F%2Fwww.linkrease.com';
    } else {
      console.log('no linkidin');
    }
  };

  return (
    <Layout className={styles.container}>
      <Content>
        <div className={styles.initial}>
          <div className={styles.landingHeader}>
            <LandingNav />
          </div>
          <div className={`${styles.initialWrapper}`}>
            <Row className={styles.innerContainer}>
              <Col xs={24} md={{ span: 12 }} lg={{ span: 9 }} xl={{ span: 10 }}>
                <div className={styles.leftSider}>
                  <div className={styles.leftTitle}>
                    <strong>{t('MonetizeYourCreativity')}</strong>
                  </div>
                  <div className={styles.leftText}>{t('whetherInFilm')}</div>
                  <div className={styles.paddedSections}>
                    <EntertainmentVertical />
                  </div>
                  <div className={styles.paddedSections}>
                    <Divider className={styles.joinDivider} orientation="left">
                      {t('orJoinUs')}
                    </Divider>
                  </div>
                  <div className={styles.socialMediaContainer}>
                    {socialIcons.map((icon, index) => (
                      <Icon
                        key={`social-icon-${index}`}
                        onClick={() => socialLogin(index)}
                        component={icon}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={{ span: 12 }} xl={{ span: 14 }}>
                <div className={styles.rightSider}>
                  <Trans t={t}>collaborativeNetworkText</Trans>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles.whoContainer}>
          <div className={styles.searchContainer}>
            <h1 className={styles.searchHeader}>
              Search creators on Linkrease
            </h1>
            <input placeholder="" type="text"></input>
            <Button type="primary" shape="round">
              Search
            </Button>
          </div>
          <Modal
            visible={happyFace}
            footer={null}
            closable={false}
            maskClosable={true}
            keyboard={true}
            onCancel={() => sethappyFace(false)}
            className={styles.modal}
          >
            <div className={styles.happyFaceContent}>
              <Row className={styles.row}>
                <img
                  src={logoLinkrease}
                  className={styles.logo}
                  alt="linkrease logo"
                />
              </Row>
              <Row className={styles.row}>
                <img
                  src={happyFaceImage}
                  className={styles.happylogo}
                  alt="linkrease logo"
                />
              </Row>
              <Row>
                <h2 className={styles.body}>
                  Partner with your network to get the lastest opportunities for{' '}
                  <span className={styles.span}>{happyFaceprofessions}</span>
                </h2>
              </Row>
              <Row>
                <p className={styles.grey}>
                  Sign up for access to thousands of jobs, casting calls and
                  projects to join
                </p>
              </Row>
              <Row>
                <Col>
                  <Link to="/register-vertical">
                    <Button
                      className={styles.button}
                      type="primary"
                      shape="round"
                      size={'large'}
                    >
                      JOIN NOW
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </Modal>
          <IntroText
            className={styles.whoIs}
            customAccentText="whoIsLinkreaseFor"
            customText="lookingToProduce"
          />
          <div className={styles.collapsable}>
            <div
              className={`${styles.tagContainer} ${
                collapsed && styles.collapsed
              }`}
            >
              {professions.map((f, i) => (
                <Tag
                  key={i}
                  className={styles.tag}
                  onClick={() => clickHappyFace(f)}
                >
                  <span className={styles.tagText}>{f}</span>
                </Tag>
              ))}
            </div>
            <div
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              <p
                className={`${styles.showMore} ${
                  collapsed && styles.collapsed
                }`}
              >
                {collapsed ? t('showLess') : t('showMore')}{' '}
                {collapsed ? <UpOutlined /> : <DownOutlined />}
              </p>
            </div>
          </div>
          <div className={styles.elementContainer}>
            <Button
              className={styles.button}
              type="primary"
              shape="round"
              size="large"
            >
              <strong>{t('getStarted')}</strong>
            </Button>
          </div>
          <img src={logo} className="img-responsive" alt="Logo" />
        </div>
        {/*<div className={styles.connectContainer}>
          <IntroText
            className={styles.whoIs}
            customAccentText="connectCreateCollaborate"
            customText="whatIsLinkrease"
          />
          <div className={styles.elementContainer1}>
            <Button
              className={styles.button}
              type="primary"
              shape="round"
              size="large"
            >
              <strong>{t('getStarted')}</strong>
            </Button>
          </div>
          <h3 className={styles.textL}>
            ARE YOU AN INFLUENCER?{' '}
            <Link to="/" className={styles.textLink}>
              Learn More
            </Link>
          </h3>
        </div>*/}
        <div className={styles.cardsContainer}>
          <Row justify={'center'} gutter={4}>
            <Col xs={24} lg={12} xl={8}>
              <ForCards
                headerText="forIndustryProfessionals"
                text="monetizeBy"
                number="01"
                textsToRender={firstTextCard}
                styles={styles}
              />
            </Col>
            <Col xs={24} lg={12} xl={8}>
              <ForCards
                headerText="forAspirationalTalent"
                text="increaseYourChances"
                number="02"
                textsToRender={secondTextCard}
                styles={styles}
              />
            </Col>

            <Col xs={24} lg={12} xl={8}>
              <ForCards
                headerText="forInfluencers"
                text="monetizeYourInfluence"
                number="03"
                textsToRender={thirdTextCard}
                styles={styles}
              >
                <div className={styles.bottomContainer}>
                  <Button
                    className={styles.button}
                    type="primary"
                    shape="round"
                    size="large"
                  >
                    <strong>{t('learnMore')}</strong>
                  </Button>
                </div>
              </ForCards>
            </Col>
          </Row>
          <div className={styles.bottomContainer}>
            <Button
              className={styles.botton}
              type="primary"
              shape="round"
              size="large"
            >
              <strong>{t('getStarted')}</strong>
            </Button>
          </div>
        </div>
        <div className={styles.FourthSection}>
          <Row justify="center">
            <Col xs={24} lg={{ span: 16 }}>
              <IntroText
                customAccentText="harnessThePower"
                customText="linkreaseIsWhere"
              />
            </Col>
          </Row>
          <Row justify="center" className={styles.menTalkingWrapper}>
            <Col span={8}>
              <img
                className={`${styles.topImg}${styles.image} img-responsive`}
                src={leftTalkingManSVG}
                alt=""
              />
            </Col>
            <Col span={8}>
              <div className={styles.leftSubTitle}>
                <strong>{t('myEntertainmentVerticalTitle')}</strong>
              </div>
              <EntertainmentVertical />
            </Col>
            <Col span={8}>
              <img
                className={`${styles.topImg} ${styles.image} img-responsive`}
                src={rightTalkingManSVG}
                alt=""
              />
            </Col>
          </Row>
          <Row align={'middle'}>
            <Col xs={24} lg={12}>
              <img
                src={collaborationSVG}
                alt=""
                className={`${styles.bigImage} img-responsive`}
              />
            </Col>
            <Col xs={24} lg={11}>
              <LandingHeader headerText="collaborationTools" />
              <p className={`${styles.text}`}>{t('ideaToSubmission')}</p>
              <Link to="/" className={styles.textLink}>
                {t('learnMoreAboutHowItWorks')}
              </Link>
            </Col>
          </Row>
          <Row align={'middle'}>
            <Col xs={24} lg={{ span: 11, offset: 2 }}>
              <LandingHeader headerText="crowdFundYourEligibleProjects" />
              <p className={`${styles.text}`}>{t('leverageNetworkEffect')}</p>
              <Link to="/" className={styles.textLink}>
                {t('learnMoreAboutHowItWorks')}
              </Link>
            </Col>
            <Col xs={24} lg={11}>
              <img
                src={crowdfundSVG}
                alt=""
                className={`${styles.bigImage} img-responsive`}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.FifthSection}>
          <Row justify="center">
            <Col xs={24} lg={8} xl={6}>
              <img
                src={searchVector}
                className={`${styles.vector} img-responsive`}
                alt=""
              />
            </Col>
          </Row>
          <IntroText
            customAccentText="startLivingYourBestCareer"
            customText="weHelpAspirationalTalent"
          />
          {/* <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              type="primary"
              shape="round"
              size="large"
            >
              {t('postJob')}
            </Button>
            <Button className={styles.button} shape="round" size="large">
              {t('listCastingCall')}
            </Button>
          </div> */}
          {/* <Row justify="center">
            <Col span={7} offset={4}>
              <Tabs defaultActiveKey="1" onChange={() => {}}>
                <TabPane tab={t('jobs')} key="1">
                  <AutoComplete
                    style={{ width: '100%' }}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    value={value}
                    className={styles.input}
                    onSearch={onSearch}
                    onChange={onChange}
                  >
                    <Input
                      className={styles.input}
                      placeholder={t('searchJobTitlesOrComps')}
                      prefix={<SearchOutlined />}
                      size="large"
                    />
                    {options.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </AutoComplete>
                  <Input
                    className={styles.input}
                    size="large"
                    placeholder={t('selectCityStateCountry')}
                    value={country}
                    onChange={(e) => onChangeLocation(e)}
                    prefix={<EnvironmentOutlined />}
                  />
                  <Link
                    to={{
                      pathname: '/jobs',
                      jobsProps: { location: country, jobTitle: value },
                    }}
                  >
                    <Button
                      className={styles.button}
                      type="primary"
                      shape="round"
                      size="large"
                    >
                      Search for jobs
                    </Button>
                  </Link>
                </TabPane>
                <TabPane tab={t('castingCalls')} key="2">
                  Content of Tab Pane 2
                </TabPane>
              </Tabs>
            </Col>
            <Col xs={24} lg={8} xl={6}>
              <img
                src={searchVector}
                className={`${styles.vector} img-responsive`}
                alt=""
              />
            </Col>
          </Row> */}

          {/* <Row>
            <Col span={5} offset={1}>
              <Breadcrumb separator="|">
                <Breadcrumb.Item >
                  <span><a>Jobs</a></span>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <span>Casting Calls</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={[32, 32]}>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    //value={job}
                    size="large"
                    placeholder="Company or position"
                    prefix="What?"
                    //onChange={(e) => onChangeJobs(e)}
                  />
                </Col>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    //value={location}
                    size="large"
                    placeholder="Region or City"
                    prefix="Where?"
                    //onChange={(e) => onChangeLocation(e)}
                  />
                </Col>
                <Col>
                <Link
                    to={{
                      pathname: '/jobs',
                      jobsProps: { location: country, jobTitle: value },
                    }}
                  >
                  <Button type="primary" shape="round" size="large">
                    
                    Search
                  </Button>

                  </Link>
                  
                </Col>
              </Row> */}
          <JobsSearcher country={country} value={value} />
        </div>

        <div className={styles.jobPostingContainer}>
          <IntroText
            customAccentText="areYouAnEmployer"
            customText="seeHowOurSolutionsHelp"
          />
          <div className={styles.buttonContainer}>
            <Link
              to={{
                pathname: '/post-job',
              }}
            >
              <Button
                className={styles.button}
                type="primary"
                shape="round"
                size="large"
              >
                {t('postJob')}
              </Button>
            </Link>
            <Button className={styles.button} shape="round" size="large">
              {t('listCastingCall')}
            </Button>
          </div>
        </div>
      </Content>
      <Popup trigger={timedPopup} setTrigger={setTimedPopup}>
        <span className={styles.message}>
          This website uses cookies to ensure you get the best experience on our
          website.{' '}
          <Link
            className={styles.link}
            onClick={() => setVisible5(true)}
            to="/cookiePolicy"
          >
            Learn more
          </Link>
        </span>
      </Popup>
      <LandingFooter />
      <Footer className={styles.footer}>
        <PrivacyTermsFooter />
      </Footer>
    </Layout>
  );
};

export default Landing;
