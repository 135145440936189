import React from 'react';
import styles from './Invitations.module.scss';
import { Row, Col, Button, Divider } from 'antd';
import infinite from './img/infinite.PNG';
import infinite2 from './img/infinite2.PNG';
const { useState } = React;

const Invitations = ({ picture, name, job, connected, rank }) => {
  const [showInfinite, setShowInfinite] = useState(true);
  return (
    <Row>
      {showInfinite ? (
        <>
          <Col span={9}>
            <img src={picture} className={styles.profile} alt="" />
            <h2 className={`${styles.connectionData} ${styles.top}`}>{name}</h2>
            <h4 className={styles.connectionData}>{job}</h4>
          </Col>
          <Col span={2}>
            <img
              src={infinite2}
              className={styles.infinite}
              alt=""
              onMouseEnter={() => setShowInfinite(false)}
            />
          </Col>
        </>
      ) : (
        <>
          <Col span={3}>
            <img src={picture} className={styles.profile} alt="" />
          </Col>
          <Col
            span={8}
            className={styles.sharedConnections}
            onMouseLeave={() => setShowInfinite(true)}
          >
            <Col span={5}>
              <img src={infinite} className={styles.infinite2} alt="" />
            </Col>
            <>
              <h4 className={styles.sharedContent}>
                2 shared connections Diego Garcia and Raul Argueta
              </h4>
            </>
          </Col>
        </>
      )}
      <Col span={1}>
        <Divider type="vertical" className={styles.verticalDivider} />
      </Col>
      <Col span={11} className={styles.rightConnection}>
        <Row>
          <h2>Rank</h2>
          <span className={styles.numberCircle}>{rank}</span>
        </Row>
        <Row>
          <Col>
            <Button
              ghost
              type="primary"
              shape="round"
              size={'large'}
              className={`${styles.auxNavBtn} ${styles.Btn}`}
            >
              Decline
            </Button>
          </Col>
          <Col>
            <Button ghost shape="round" size={'large'} className={styles.Btn}>
              Accept
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={3}></Col>
      <Col span={21}>
        <div className={styles.reply}>
          <Col span={16}>
            <h4>
              Hey Dante, it looks like we have a mutual contact. I'd love to
              connect and find ways to collaborate in some of each other's
              projects
            </h4>
          </Col>
          <Col span={5}></Col>
          <Col>
            <Button
              ghost
              type="primary"
              shape="round"
              size={'large'}
              className={`${styles.auxNavBtn} ${styles.btns}`}
            >
              Reply
            </Button>
          </Col>
        </div>
      </Col>
      <Col span={24}>
        <hr className={styles.postDivider} />
      </Col>
    </Row>
  );
};
export default Invitations;
