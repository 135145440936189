import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

export const useModal = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  const onClose = () => {
    if (loading) return;
    setShow(false);
  };

  const onOpen = () => {
    setShow(true);
  };

  return {
    onOpen,
    onClose,
    loading,
    show,
    toggleLoading,
  };
};

export const useSharing = () => {
  const onShare = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    toast.success('Profile URL copied');
  };

  return {
    onShare,
  };
};

export const useBodyScrollControl = () => {
  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
    if (!document.querySelector('.main-layout')) return;
    document.querySelector('.main-layout').style.overflow = 'hidden';
  };

  const unlockScroll = () => {
    document.body.style.overflow = 'unset';
    if (!document.querySelector('.main-layout')) return;
    document.querySelector('.main-layout').style.overflow = 'unset';
  };

  return {
    lockScroll,
    unlockScroll,
  };
};

// export const useDetectHLSBrowser = () => {
//   const browser = detect();
//   const hlsBrowsers = ['ios', 'crios', 'safari'];
//   const isHlsBrowser = hlsBrowsers.some((el) => browser?.name.includes(el));

//   return isHlsBrowser;
// };
