import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { setVertical } from '../../../containers/Register/Register.slice';
import Style from './EntertainmentVertical.module.scss';
const { Option } = Select;

const EntertainmentVertical = () => {
  const history = useHistory();
  const registerData = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const onChangeHandler = (value) => {
    console.log(value);
    dispatch(setVertical(value));
  };
  const onFinish = () => {
    history.push('/register-path');
  };
  const { t } = useTranslation('landing');
  return (
    <div>
      <Form onFinish={onFinish}>
        <div className={`${Style.verticalItem} ${Style.select}`}>
          <Form.Item
            rules={[
              { required: true, message: 'Please select your vertical!' },
            ]}
          >
            <Select
              defaultValue={registerData?.vertical}
              className={Style.verticalItem}
              placeholder="Please Select One Option"
              onChange={onChangeHandler}
            >
              <Option className={Style.selectItem} value="film">
                {t('film')}
              </Option>
              <Option value="sports">{t('sports')}</Option>
              <Option value="music">{t('music')}</Option>
              <Option value="gaming">{t('gaming')}</Option>
            </Select>
          </Form.Item>
        </div>
        <div className={`${Style.verticalItem} ${Style.continueContainer}`}>
          <Button
            disabled={!registerData?.vertical}
            className={Style.verticalItem}
            htmlType="submit"
            type="primary"
            shape="round"
            size={'large'}
          >
            {t('continue')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EntertainmentVertical;
