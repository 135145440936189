import React from 'react';
import { Divider } from 'antd';
import style from './LeftNav.module.scss';
import ProfileSection from './ProfileSection';
import tempPic from './img/profilePic.svg'




const LeftNav = () => {
	return (
		<div>
			<ProfileSection image={tempPic} 
				accountName="Diego"
				accountJob="Content Manager @Netflix"
				accountSite="www.diegogarcia.com"
				rank={1}
			/>
			<Divider className={style.siderDivider}/>
			
		</div>
	);
};

export default LeftNav;