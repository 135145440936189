import { gql } from '@apollo/client';

const USER_SELECT = `
userId
profileImage
given_name
family_name
email
created
updated
roles
portofolios
expertise
joiningReasons
aboutMe
jobs {
  userId
    userJobId
    company
    title
    description
    location
    employment
    startDate
    endDate
    created
    updated
}
awards {
  userId
  userAwardId
  title
  description
  website
  date
  created
  updated
}
educations {
  userId
  userEducationId
  school
  fieldOfStudy
  degree
  startDate
  endDate
  created
  updated
}
settings {
  language
  contact
  searchability
  activity
  portfolio
  campaign
  contributions
}
addresses {
  city
  country
  postalCode
}
phones {
  number
  type
}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        profileImage
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($input: CreateUserInput!) {
    createUser(input: $input) {
      ${USER_SELECT}
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($input: GetUserInput!) {
    getUser(input: $input) {
      ${USER_SELECT}
    }
  }
`;

export const GET_USER_PUBLIC = gql`
  query GET_USER_PUBLIC($id: ID, $user_name: String) {
    getUserPublic(id: $id, user_name: $user_name) {
      ${USER_SELECT}
    }
  }
`;

export const GET_LOGGED_USER = gql`
  query GET_LOGGED_USER {
    getCurrentUser {
      ${USER_SELECT}
    }
  }
`;

export const UPDATE_USER_REASON = gql`
  mutation UPDATE_USER_REASON($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        userId
        joiningReasons
      }
    }
  }
`;

export const CREATE_USER_JOB = gql`
  mutation CREATE_USER_JOB($input: CreateUserJobInput!) {
    createUserJob(input: $input) {
      userJob {
        userId
        userJobId
        company
        title
        description
        startDate
        endDate
      }
    }
  }
`;

export const CREATE_USER_EDUCATION = gql`
  mutation CREATE_USER_EDUCATION($input: CreateUserEducationInput!) {
    createUserEducation(input: $input) {
      userEducation {
        userEducationId
        school
        fieldOfStudy
        degree
        startDate
        endDate
      }
    }
  }
`;

export const UPDATE_USER_EDUCATION = gql`
  mutation UPDATE_USER_EDUCATION($input: UpdateUserEducationInput!) {
    updateUserEducation(input: $input) {
      userEducation {
        userEducationId
        school
        fieldOfStudy
        degree
        startDate
        endDate
      }
    }
  }
`;

export const DELETE_USER_EDUCATION = gql`
  mutation DELETE_USER_EDUCATION($input: DeleteUserEducationInput!) {
    deleteUserEducation(input: $input) {
      message
    }
  }
`;

export const CREATE_USER_ADDRESS = gql`
  mutation CREATE_USER_ADDRESS($input: CreateUserAddressInput!) {
    createUserAddress(input: $input) {
      userAddress {
        userId
        userAddressId
        city
        country
        postalCode
      }
    }
  }
`;

export const DELETE_USER_ADDRESS = gql`
  mutation DELETE_USER_ADRESS($input: DeleteUserAddressInput!) {
    deleteUserAddress(input: $input) {
      message
    }
  }
`;

export const CREATE_USER_AWARD = gql`
  mutation CREATE_USER_AWARD($input: CreateUserAwardInput!) {
    createUserAward(input: $input) {
      userAward {
        userId
        userAwardId
        title
        description
        website
        date
        created
        updated
      }
    }
  }
`;

export const UPDATE_USER_AWARD = gql`
  mutation UPDATE_USER_AWARD($input: UpdateUserAwardInput!) {
    updateUserAward(input: $input) {
      userAward {
        userId
        userAwardId
        title
        description
        website
        date
        created
        updated
      }
    }
  }
`;

export const DELETE_USER_AWARD = gql`
  mutation DELETE_USER_AWARD($input: DeleteUserAwardInput!) {
    deleteUserAward(input: $input) {
      message
    }
  }
`;

export const UPDATE_USER_JOB = gql`
  mutation UPDATE_USER_JOB($input: UpdateUserJobInput!) {
    updateUserJob(input: $input) {
      userJob {
        userId
        userJobId
        company
        title
        description
        location
        employment
        startDate
        endDate
        updated
      }
    }
  }
`;

export const DELETE_USER_JOB = gql`
  mutation DELETE_USER_JOB($input: DeleteUserJobInput!) {
    deleteUserJob(input: $input) {
      message
    }
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation UPDATE_USER_ROLES($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        userId
        roles
      }
    }
  }
`;

export const UPDATE_USER_PORTAFOLIO = gql`
  mutation UPDATE_USER_PORTAFOLIO($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        userId
        portofolios
      }
    }
  }
`;

export const UPDATE_USER_ABOUT_ME = gql`
  mutation UPDATE_USER_ABOUT_ME($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        aboutMe
      }
    }
  }
`;

export const IS_USER_EMAIL_TAKEN = gql`
  query IS_USER_EMAIL_TAKEN($input: IsUserEmailTakenInput!) {
    isUserEmailTaken(input: $input)
  }
`;
