import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './SetupAccount.module.scss';
import { Form, Input, Checkbox, Button } from 'antd';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { useApolloClient } from '@apollo/client';
import { setAccountDetails } from '../../Register.slice';
import { IS_USER_EMAIL_TAKEN } from '../../../../graphql/users';

export default function SetupAccount({ formRef, setCurrentStep, isLoading }) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const externalData = localStorage.getItem('linkedinData')
    ? JSON.parse(localStorage.getItem('linkedinData'))
    : '';
  const apiEmail = externalData ? externalData.Email : '';

  const verifyIfEmailIsTaken = async (value) => {
    const { data } = await client.query({
      query: IS_USER_EMAIL_TAKEN,
      variables: { input: { email: value } },
    });

    return data?.isUserEmailTaken;
  };

  const onFinish = (values) => {
    const { email, password, agreement } = values;

    dispatch(setAccountDetails({ data: { email, password, agreement } }));
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <div className={styles.SetupAccount}>
      <div className={styles.intro}>
        <h1 className={styles.stepTitle}>
          Great, now let’s set up your free account
        </h1>
      </div>

      <Form
        name="setupAccount"
        onFinish={onFinish}
        className={styles.formWrapper}
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          remember: false,
        }}
      >
        <label htmlFor="email" className="formLabel">
          Email
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="email"
          initialValue={apiEmail}
          rules={[
            { required: true, message: 'Please input your email!' },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            ({ getFieldValue }) => ({
              async validator(rule, value) {
                if (!value) {
                  return;
                }
                const isUserEmailTaken = await verifyIfEmailIsTaken(value);

                if (!isUserEmailTaken) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('The email is already taken!');
                }
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <div>
          <span className={styles.passTip}>8 or more characters</span>
          <label className={`${styles.passLabel} formLabel`} htmlFor="password">
            Password
          </label>
        </div>
        <Form.Item
          validateTrigger="onBlur"
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            {
              min: 8,
              message: 'Cannot be less than 8 characters',
            },
            {
              message:
                'Your password must contain lower and uppercase letters, digits, and special characters.',
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            },
          ]}
        >
          <Input.Password allowClear />
        </Form.Item>

        <label htmlFor="confirmPassword" className="formLabel">
          Confirm Password
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
        >
          <Input.Password allowClear />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        'Please indicate that you accept the User Agreement, Privacy Policy, and Cookie Policy!'
                      )
                    ),
            },
          ]}
        >
          <Checkbox>
            I agree to the Linkrease{' '}
            <Link className={styles.link} to="/termsandconditions">
              Terms and conditions
            </Link>
            , {}
            <Link className={styles.link} to="/privacypolicy">
              Privacy Policy
            </Link>
            , and{' '}
            <Link className={styles.link} to="/cookiepolicy">
              Cookie Policy
            </Link>
            .
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
