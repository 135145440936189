import React, { useState } from 'react';
import { Col, Button, Input } from 'antd';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

// Styles
import Styles from '../CreatePanel.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// images/icons
import cameraIcon from '../img/camera.svg';

const ArticleContentPanel = (props) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  return (
    <>
      <Col span={24}>
        <label className={Styles.articleThumbnailLabel} htmlFor="thumbnail">ARTICLE THUMBAIL</label>
        <div className={Styles.thumbnailInputWrapper}>
          <img className={Styles.cameraIcon} src={cameraIcon} alt=""/>
          <Input id="thumbnail" type="file" accept="image/png, image/jpeg" />
        </div>
      </Col>
      <Col span={24}>
        <label htmlFor="headline">HEADLINE</label>
        <Input className={Styles.styleTextInput} id="headline" />
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={Styles.editorWrapper}
          onEditorStateChange={setEditorState}
        />
      </Col>
      <Col span={3}>
        <Button ghost block type="primary" shape="round">CANCEL</Button>
      </Col>
      <Col offset={14} span={3}>
        <Button ghost block type="primary" shape="round">SAVE AS DRAFT</Button>
      </Col>
      <Col offset={1} span={3}>
        <Button block type="primary" shape="round">PUBLISH</Button>
      </Col>
    </>
  );
}

export default ArticleContentPanel;
