import React from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Checkbox, Space, Col, Row, Button, Radio } from 'antd'

const  AboutCompany =({goNextStep, formRef})=>{
    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
     
    span={20}
    >    
    <Row justify='center'>
        
        <h2 className={styles.title}>Tell us about your company</h2>
          
        </Row>

    <Row justify='center'>
        <Col
        span={20}
        >

    <Form
      name="basic"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
        style={{marginBottom:'5em'}}
       // rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Radio.Group>
        <Space direction='vertical'>
            <Radio className={styles.radio} value={1} checked="true">I want to hire for my company</Radio>
            <Radio className={styles.radio} value={2}>I'm a recruiter/Recruiting agency</Radio>
            <Radio className={styles.radio} value={3}>I'm a temporary staffing agency</Radio>
            
        </Space>
        </Radio.Group>
      </Form.Item>

      

     

      <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        onClick={(e)=>goNextStep(e)}>
         CONTINUE
        </Button>
      </Form.Item>
    </Form>
        </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default AboutCompany