import React from 'react';
import { Row, Col, Button, Progress } from 'antd';
import Styles from './TrendingCampaign.module.scss';

// Images
import campaignImage from './img/campaign.jpeg';
import profile from './img/profile-sample.jpg';

const TrendingCampaign = () => {
  return (
    <div className={Styles.trendingCampaignContainer}>
      <div className={Styles.trendingBox}>
        <Row align="middle" justify="space-between">
          <Col>
            <h4 className={Styles.title}>Trending Campaigns</h4>
          </Col>
          <Col>
            <Button
              className={Styles.campaignInformationButton}
              type="primary"
              shape="circle"
            >
              ?
            </Button>
          </Col>
          {/* <Col>
						<Button className={Styles.chevronButton} shape="circle" icon={<DownOutlined />} />
					</Col> */}
        </Row>
        <Row justify="end"></Row>
        <h6 className={Styles.boxTitle}>We summon the darkness</h6>
        <img
          src={campaignImage}
          className={`${Styles.campaignImage} img-responsive`}
          alt=""
        />
        <p className={Styles.campaignInfo}>
          Thriller | English <br /> Locations: Los Angeles, CA, US
        </p>
        <span className={Styles.campaignRaisedMoney}>$28,539 raised</span>
        <Progress
          percent={75}
          showInfo={false}
          strokeColor="#122EFF"
          trailColor="#EBEBEB"
        />
        <Row align="middle">
          <Col span={3}>
            <img src={profile} className={Styles.listedProfilePic} alt="" />
          </Col>
          <Col>
            <p className={Styles.listedBy}>listed by guillermo mazzoni</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TrendingCampaign;
