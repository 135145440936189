import React from 'react';
import { Collapse } from "antd";
import style from "./ContentComponents.module.scss";
import { useTranslation } from "react-i18next";
import { generateNotificationsHeaders, handleCollapse, headerRenderer } from './contentUtilities';

const { Panel } = Collapse;

const Notifications = () => {
	const { t } = useTranslation("settings");
  const headers = generateNotificationsHeaders();

	return (
    <div>
      <Collapse
        className={style.collapseContainer}
        expandIcon={handleCollapse}
        accordion
        expandIconPosition="right"
      >
        {headers.map((el, index)=> {
          return (
          <Panel header={headerRenderer({
            mainText: t(el.mainText),
            subText: t(el.subText)
          })} key={index} disabled>
            <p>lol</p>
          </Panel>
          )
        })}
      </Collapse>
    </div>
  );
};

export default Notifications;