import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './CorpNav.module.scss';
import logo from '../../img/LRLogoLight.png';
import { useQuery } from '@apollo/client';
import { GET_LOGGED_USER } from '../../graphql/users';

const CorpNav = ({ title }) => {
  const { t } = useTranslation('landing');
  const { loading, error, data } = useQuery(GET_LOGGED_USER);
  const given_name = data?.getUser?.given_name;
  const family_name = data?.getUser?.family_name;
  console.log(data);
  return (
    <Row className={style.container}>
      <Col xs={24} lg={{ span: 2 }} xl={{ span: 2 }}>
        <Link to="/">
          <img src={logo} className={style.logo} alt="linkrease logo" />
        </Link>
      </Col>
      <Col
        className={style.whyContainer}
        xs={24}
        lg={{ span: 3, offset: 3 }}
        xl={{ span: 3, offset: 3 }}
      >
        <Link className={style.textLink}>{title}</Link>
      </Col>
      <Col lg={7} offset={8} className={`hidden-xlarge-down`}>
        <div className={style.buttonContainer}>
          {data ? (
            <span className={style.welcomeUser}>{`Welcome ${
              given_name + ' ' + family_name + ' '
            }!`}</span>
          ) : (
            <>
              <Link className={style.textLink} to="/login">
                {t('signIn')}
              </Link>
              <Link to="/register-vertical">
                <Button shape="" size={'large'} className={style.auxNavBtn}>
                  {t('joinNow')}
                </Button>
              </Link>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default CorpNav;
