export const cdnPath = '';

export const mockRequest = (data) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data,
      });
    }, 1000);
  });

export const postMediaStatus = {
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
};

export const postAccessType = {
  FREE: 'FREE',
  EXCLUSIVE: 'EXCLUSIVE',
};
export const postMediaType = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

export const breakpoints = {
  mobile: '(max-width:767px)',
  tablet: '(max-width:1023px)',
  desktop: '(min-width:1024px)',
};

export const imageOptimizationSettings = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1900,
  useWebWorker: true,
};
