import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import logo from '../../img/LRLogoDark.png';
import styles from './LogIn.module.scss';
import CircularSpinner from '../../components/Common/Spinners/CircularSpinner';
import { setAuthItemsToLocalStorage } from '../../utils';
import { signIn, getLoggedUser } from '../Session/Session.thunks';

const LogIn = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('idle');
  const [signInError, setSignInError] = useState('');

  const onFinish = async (values) => {
    setStatus('loading');
    try {
      const result = await dispatch(signIn(values)).unwrap();

      setAuthItemsToLocalStorage(result?.session);

      await dispatch(getLoggedUser()).unwrap();
      setStatus('done');
    } catch (error) {
      console.log('onFinish error', error);
      setSignInError(error.message);
      setStatus('failed');
    }
  };

  return (
    <div className={styles.LogIn}>
      <div className={styles.whiteBox}>
        <div className={styles.withPadding}>
          <div className={styles.heading}>
            <img src={logo} alt="Linkerease Logo" className={styles.logo} />
            <h1>Welcome Back</h1>
            <p>
              Sign in to partner with your network and monetize your creativity
            </p>
          </div>
          {signInError && (
            <span className={styles.signInError}>{signInError}</span>
          )}

          <Form
            name="loginForm"
            onFinish={onFinish}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            <label htmlFor="email" className="formLabel">
              Email
            </label>
            <Form.Item
              validateTrigger="onBlur"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                name="password"
                type="text"
                maxLength="50"
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              validateTrigger="onBlur"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input
                name="password"
                type="password"
                maxLength="30"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="continueBtn"
                htmlType="submit"
                type="primary"
                shape="round"
                loading={status === 'loading'}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className={styles.footer}>
          <p>
            New to Linkrease? <Link to="/register-vertical">Join for free</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
