import React,{useState} from 'react'
import styles from '../PostJob.module.scss'
import { useQuery } from '@apollo/client';
import { Form, Checkbox, Input, AutoComplete, Col, Row, Button } from 'antd'
import { useSelector } from 'react-redux';
import { ShopOutlined } from '@ant-design/icons';



const  CompanyForm =({goNextStep, formRef})=>{
  const options=[{value:'Company (1-5 employees)'},
                  {value:'Company (6-10 employees)'},
                  {value:'Company (11-20 employees)'},
                  {value:'Company (21-50 employees)'},
                  {value:'Company (51-100 employees)'},
                  {value:'Company (100-250 employees)'},
                  {value:'Company (250+ employees)'}]

  const [agreed, setAgreed] = useState(false)
  const IdToken = localStorage.getItem('cognito-idToken');
  const accessToken = localStorage.getItem('cognito-accessToken');
  const refreshToken = localStorage.getItem('cognito-refreshToken');
  const { status, userData } = useSelector((state) => state.session);



  const MessageTitle=()=>{
    if(IdToken)
    return(
    <Row justify='center'>
    <h2 className={styles.title}>Start hiring today, {userData?.given_name}</h2>
    </Row>
    )
    else
    return (<Row justify='center'>
         <h2 className={styles.title}>Join linkrease to start your job post</h2>
       </Row>
         )
    
  }
  
  const ButtonText=()=>{
    if(IdToken)
    return(<Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
    >
    Get started for free
   </Button>)
    else
    return (<Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
    >
    AGREE AND JOIN
   </Button>)
  }
return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
    // span={16}
    // offset={4}
    >    
      {/* <h2 className={styles.title}>Join linkrease to start your job post</h2> */}
      {MessageTitle()}
    <Row>

    <Col
    
    >
    <Form
      name="company1"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
      onFinish={goNextStep}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="What's the name of your company?"
        name="company"
        rules={[{ required: true, message: 'Please input your company name' }]}
      >
      
        <Input prefix={<ShopOutlined />} />
      </Form.Item>

      <Form.Item
        label="How many employees do you have?"
        name="employees"
        rules={[{ required: true, message: 'Please select a option' }]}
      >
         <AutoComplete
        options={options}>
          
          </AutoComplete>
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" rules={[{validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),}]}
      >
        <Checkbox onClick={e=>setAgreed(e.target.checked)}>I agree to the <a>User Agreement Policy</a> and <a>Cookie Policy</a></Checkbox>
      </Form.Item>

      <Form.Item>
        {ButtonText()}
      </Form.Item>
    </Form>
    </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
)
}

export default CompanyForm