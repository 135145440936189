import React, {useState, useRef} from 'react';
import {
  Row,
  Col,
  Button,
  Input,
  Breadcrumb,
  AutoComplete,
  Empty,
  Divider,
} from 'antd';
import styles from './PostJobDetails.module.scss'
import { useQuery } from '@apollo/client';
import { GET_LOGGED_USER } from '../../graphql/users';
import { Link } from 'react-router-dom';
import LandingFooter from '../../components/Landing/Footer/Footer';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import PstJobPage1 from './components/page1';
import PstJobPage2 from './components/page2';

const PostJobDetails = ()=>{
    const [page,setPage] = useState(1);
    
    const goNextPage = ()=>{
      setPage(2)
    }

    const pages=()=>{
      switch(page){
        case 1:{
          return<PstJobPage1 goNextPage={goNextPage}/>
        }
        case 2:{
          return<PstJobPage2/>
        }

      }

    }
    return(
        <div>
        <header>
            <LoggedInHeader/>
        </header>
        <main>
            <Row>
                <Col
                offset={1}
                span={22}
                >
                <Row
                    className={styles.title}
                >
                    <Col
                    span={4}
                    >
                        <h2>Job Details</h2>                
                    </Col>
                    <Col
                    offset={16}
                    >
                      <Link to='/manage-jobs'>
                    <Button className={styles.manageJobs}>Manage Jobs</Button>
                    </Link>
                    </Col>
                </Row>
                

                    {pages()}
                
                
                </Col>
              </Row>
        </main>
        <footer>
            <LandingFooter/>
            <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <div style={{ width: '18rem', height: '11rem' }} className="logo" />
          </div>
          <div className={styles.logoContainer}>
            <div>COPYRIGHT © 2020 LINKREASE</div>
          </div>
        </div>
      
        </footer>
        </div>
    )
}

export default PostJobDetails;