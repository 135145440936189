import React, { useState } from 'react';
import Backdrop from '../../components/Backdrop/Backdrop';
import Slide from '../../components/Slide';
import CircularSpinner from '../../components/Common/Spinners/CircularSpinner';
import classes from './Notifications.module.scss';
import style from '../../components/LoggedInHeader/LoggedInHeader.module.scss';
import icon from '../../components/LoggedInHeader/img/Notifications.png';
import bellicon from './img/bell.png';
import clsx from 'clsx';
import LastNotifications from '../../components/Notifications/LastNotifications';
import Notification from '../../components/Notifications/Notification/Notification';

export default function Notifications() {
  const [isOpen, setIsOpen] = useState(false);
  const loading=false //const [loading, setLoading] = useState(false);
  //const { notifications, setNotifications } = useNotifications();
  //const [unSeenCounter, setUnSeenCounter] = useState(0);

  return (
    <div>
      <div onClick={() => setIsOpen(true)}>
        <img className={style.headerIcon} src={icon} alt=""/>
        <span className={style.badge}>2</span>
        <br />
        Notifications
      </div>
      <Backdrop show={isOpen} desktop onClickHandler={() => setIsOpen(false)} />
      <Slide
        show={isOpen}
        onHide={() => setIsOpen(false)}
        renderHeaderContent={() => (
          <>
            <img className={classes.headerIcon} src={bellicon} alt="" />
            <span className={classes.badge}>2</span>
            <span className={classes.relativeCentered}>Notifications</span>
            <div className={classes.newNotifications}>
              You have new notifications
            </div>
          </>
        )}
      >
        {loading ? (
          <CircularSpinner className={classes.loader} isShowing={loading} />
        ) : (
          <div className={clsx([classes.Notifications, 'custom-scroll'])}>
            <LastNotifications
              data={[
                <Notification
                  keyProp={2}
                  aggregatedNotifications={[]}
                  type=""
                  setDrawerVisible={setIsOpen}
                />,
              ]}
              setDrawerVisible={setIsOpen}
            />
          </div>
        )}
      </Slide>
    </div>
  );
}
