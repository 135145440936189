import { gql } from '@apollo/client';

const CREATE_FILE = `
	signedURL
    urls
    file_id
`;

export const GET_SIGNED_URL = gql`
mutation GET_SIGNED_URL($input: CreateFileInput!){
    getSignedURL(input: $input){
        ${CREATE_FILE}
    }
}
`