import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./EnterVerification.module.scss";
import { Link } from "react-router-dom";

const EnterVerification = ({ formRef }) => {
  const { t } = useTranslation("logIn");
  return (
    <div className={styles.EnterVerification}>
      <div className={styles.inputsWrapper}>
        <Form
          name="enterVerification"
          className={styles.formWrapper}
          ref={formRef}
        >
          <label className="formLabel">{t("Enter Code")}</label>
          <Form.Item
            validateTrigger="onBlur"
            name="code"
            rules={[{ required: true, message: "Please input your code!" }]}
          >
            <Input allowClear maxLength="6" />
          </Form.Item>

          <label className="formLabel">{t("Enter New Password")}</label>
          <Form.Item
            validateTrigger="onBlur"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 8,
                message: "Cannot be less than 8 characters",
              },
              {
                message:
                  "Your password must contain lower and uppercase letters, digits, and special characters.",
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              },
            ]}
          >
            <Input.Password allowClear />
          </Form.Item>
        </Form>
      </div>

      <div className={styles.footer}>
        <p>
          Didn't receive the code? <Link to="/">Resend</Link>
        </p>
      </div>
    </div>
  );
};

export default EnterVerification;
