import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HiddenOnAuthRoute from './routes/HiddenOnAuthRoute';
import Landing from './containers/Landing/Landing';
import LogIn from './containers/LogIn/LogIn';
import NotFound from './containers/NotFound/NotFound.js';
import RegisterOuterWrapper from './containers/Register/components/RegisterOuterWrapper/RegisterOuterWrapper';
import VerticalSelector from './containers/Register/steps/VerticalSelector/VerticalSelector';
import RegisterPath from './containers/Register/steps/RegisterPath/RegisterPath';
import ContactUs from './components/ContactUs/ContactUs';
import AboutUs from './components/AboutUs/AboutUs';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Awards from './containers/Register/steps/Awards/Awards';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import InvestorInformation from './containers/Register/steps/InvestorInformation/InvestorInformation';
import Dashboard from './containers/Dashboard/Dashboard';
import Profile from './containers/Profile/Profile';
import Jobs from './containers/Jobs';
import PostJob from './containers/PostJob/PostJob';
import PostJobDetails from './containers/PostJobDetails/PostJobDetails';
import ManageJobs from './containers/ManageJobs/ManageJobs';
import Network from './containers/Network/Network';
import AccountSettings from './containers/SettingsPage/AccountSettings/AccountSettings';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy/CookiePolicy';
import RefundPolicy from './components/RefundPolicy/RefundPolicy';
import DCMAPolicy from './components/DCMAPolicy/DCMAPolicy';
import Disclamer from './components/Disclamer/Disclamer';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import AcceptableUsePolicy from './components/AcceptableUsePolicy/AcceptableUsePolicy';
import { getLoggedUser } from './containers/Session/Session.thunks';
import { useDispatch } from 'react-redux';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getLoggedUser());
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  const app = (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/profile/:id" exact component={Profile} />
        <Route path="/contact" exact component={ContactUs} />
        <Route path="/about" exact component={AboutUs} />
        <Route
          path="/acceptableusepolicy"
          exact
          component={AcceptableUsePolicy}
        />
        <Route path="/privacypolicy" exact component={PrivacyPolicy} />
        <Route path="/cookiepolicy" exact component={CookiePolicy} />
        <Route path="/refundpolicy" exact component={RefundPolicy} />
        <Route path="/dcmapolicy" exact component={DCMAPolicy} />
        <Route path="/disclaimer" exact component={Disclamer} />
        <Route
          path="/termsandconditions"
          exact
          component={TermsAndConditions}
        />
        <Route exact path="/settings" component={AccountSettings} />

        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/jobs" component={Jobs} />
        <Route path="/post-job" component={PostJob} />
        <Route path="/postjob-details" component={PostJobDetails} />
        <Route path="/network" component={Network} />
        <Route path="/post-job" component={PostJob} />
        <Route path="/manage-jobs" component={ManageJobs} />
        <HiddenOnAuthRoute exact path="/login" component={LogIn} />
        <HiddenOnAuthRoute
          exact
          path="/register-vertical"
          component={VerticalSelector}
        />
        <HiddenOnAuthRoute
          exact
          path="/register-path"
          component={RegisterPath}
        />
        <Route exact path="/register-flow" component={RegisterOuterWrapper} />

        <Route path="/awards" exact component={Awards} />
        <Route
          path="/investor-information"
          exact
          component={InvestorInformation}
        />

        <Route component={NotFound} />
      </Switch>
    </Router>
  );

  return <div className="App">{app}</div>;
};

export default App;
