import React from 'react';
import styles from './Connections.module.scss';
import { Row, Col, Button, Dropdown, Divider, Menu } from 'antd';
import { DashOutlined, DeleteOutlined } from '@ant-design/icons';

const menuConnection = (
  <Menu>
    <Menu.Item key="0">
      <a>
        <DeleteOutlined /> Remove Connection
      </a>
    </Menu.Item>
  </Menu>
);

const Connections = ({ picture, name, job, connected, rank }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <img src={picture} className={styles.profile} alt="" />
          <h2 className={styles.connectionData}>{name}</h2>
          <h4 className={styles.connectionData}>{job}</h4>
          <p className={styles.connectionData}>Connected {connected}</p>
        </Col>
        <Col span={1}>
          <Divider type="vertical" className={styles.verticalDivider} />
        </Col>
        <Col span={11} className={styles.rightConnection}>
          <Row>
            <h2>Rank</h2>
            <span className={styles.numberCircle}>{rank}</span>
            <Button
              ghost
              type="primary"
              shape="round"
              size={'large'}
              className={styles.messageBtn}
            >
              Message
            </Button>
            <Dropdown overlay={menuConnection} trigger={['click']}>
              <DashOutlined
                className={styles.threeDots}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          </Row>
        </Col>
        <Col span={24}>
          <hr className={styles.postDivider} />
        </Col>
      </Row>
    </>
  );
};
export default Connections;
