import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Award.module.scss';
import { Form, DatePicker, Input, Button, message } from 'antd';
import { saveAward } from '../../Register.thunks';

export default function Education({
  setCurrentStep,
  setStatus,
  isLoading,
  skipStep,
  setSectionStep,
}) {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);

  const onFinish = async (values) => {
    try {
      setStatus('pending');

      await dispatch(saveAward(values)).unwrap();

      setCurrentStep((prev) => prev + 1);
      setSectionStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  return (
    <div className={styles.Award}>
      <h1 className="stepTitle">
        {registerData?.givenName}, become a verified entertainment industry
        professional
      </h1>
      <p className="stepIntro">
        Verifying increases your chances of ranking higher within our system.
        The higher your rank, the more relevant your connectivity,
        collaboration, and funding opportunities become.
      </p>
      <Form name="awardForm" onFinish={onFinish} className={styles.formWrapper}>
        <label htmlFor="awardOrganization" className="formLabel">
          Award Organization
        </label>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your Award Organization!',
            },
          ]}
          name="awardOrganization"
        >
          <Input
            style={{ marginBottom: '1rem', width: '100%' }}
            placeholder="Organization Name"
          ></Input>
        </Form.Item>
        <label htmlFor="website" className="formLabel">
          Website
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input the Website!' }]}
          name="website"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <label htmlFor="award" className="formLabel">
          Award
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input the Award!' }]}
          name="award"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <label htmlFor="awardDate" className="formLabel">
          Date Received
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input the date!' }]}
          name="awardDate"
        >
          <DatePicker
            size="large"
            style={{ marginBottom: '1rem', width: '100%' }}
          />
        </Form.Item>

        <Button
          className="skipBtn"
          disabled={isLoading}
          type="primary"
          shape="round"
          onClick={() => skipStep()}
        >
          Skip
        </Button>
        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
