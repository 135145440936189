import React,{useState,useEffect} from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Checkbox, AutoComplete, Col, Row, Button } from 'antd'

const  GreetingForm =({goNextStep, formRef,errorMsg, goBack})=>{
  
    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
    span={20}
    >    
        <Row justify='center'>
        
        <h2 className={styles.title}>Now, how should we greet you?</h2>
          
        </Row>

    <Row justify='center'>
        <Col
        span={20}
        >

    <Form
      name="greeting"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
      onFinish={goNextStep}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="First Name"
        name="given_name"
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="family_name"
        rules={[{ required: true, message: 'Please input your last name!' }]}
      >
        <Input/>
      </Form.Item>

      <span style={{color:'red'}}>{errorMsg}</span>
      <Form.Item
      >
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        //onClick={(e)=>goNextStep(e)}
        hidden={errorMsg}
        >
         CONTINUE
        </Button>
      </Form.Item>

      <Form.Item
      hidden={!errorMsg}>
        <Button type="primary" shape="round" size="large"  style={{width:'100%'}}
        onClick={()=>goBack()}
        >
         Go Back
        </Button>
      </Form.Item>
      
    </Form>
        </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default GreetingForm