import React from 'react';
import { Row, Col } from 'antd';
import Styles from '../Cards.module.scss';

import profile from '../img/profile-sample.jpg';

const Connection = (props) => {
  return (
    <div className={Styles.connectionCard}>
      <Row align="top" gutter={[6, 6]}>
        <Col span={2}>
          <img src={profile} className={Styles.profileBubble} alt="profile" />
        </Col>
        <Col span={21}>
          <Row align="top" gutter={[6, 6]}>
            <Col xs={24} sm={8} md={16} lg={16}>
              <h5 className={`${Styles.connectionTitle}`}>
                <a href="/">You</a> are now connected to{' '}
                <a href="/">Guillermo Mazzoni</a>
              </h5>
            </Col>
            <Col>
              <div className={Styles.borderLeft}>
                <h5
                  className={`${Styles.postTitle} uppercase ${Styles.paddingLeft}`}
                >
                  Rank
                  <span className={Styles.rankBubble}>2</span>
                </h5>
              </div>
            </Col>
            <Col span={24}>
              <Row align="middle">
                <Col>
                  <img
                    src={profile}
                    className={Styles.backingBubble}
                    alt="profile"
                  />
                </Col>
                <Col>
                  <h5 className={Styles.peopleBackingUp}>
                    Christian Hernandez and 10 others are backing Guillermo.
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Connection;
