import React from "react";
import { Collapse } from "antd";
import style from "./ContentComponents.module.scss";
import { useQuery } from "@apollo/client";
import { GET_LOGGED_USER } from "../../../../graphql/users";

import {
  generateMemberAccountHeaders,
  handleCollapse,
  headerRenderer,
} from "./contentUtilities";

import {
  EmailField,
  LanguageField,
  MobileField,
  PasswordField,
} from "./MemberAccountContents";

const { Panel } = Collapse;

const MemberAccount = () => {
  const { loading, error, data } = useQuery(
		GET_LOGGED_USER,
    {ssr: true}
	);

  let headers = [];
	
  try {
    headers= generateMemberAccountHeaders(data)
    console.log(data.getUser);
    
  } catch (error) {
    
    headers= generateMemberAccountHeaders()
    console.log(data);
  }

  const contents = [
    <PasswordField />,
    <EmailField />,
    <MobileField />,
    <LanguageField />,
  ];

  return (
    <div>
      <Collapse
        className={style.collapseContainer}
        expandIcon={handleCollapse}
        accordion
        expandIconPosition="right"
      >
        {headers.map((el, index) => {
          return (
            <Panel
              header={headerRenderer({
                mainText: el.mainText,
                subText: el.subText,
              })}
              key={index}
            >
              {contents[index]}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default MemberAccount;
