import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Input } from 'antd';
import styles from './AboutMe.module.scss';
import Edit from './img/edit.svg';
import { UPDATE_USER_ABOUT_ME } from '../../../graphql/users';
import { useMutation } from '@apollo/client';

const { TextArea } = Input;

const AboutMe = ({ aboutMe = '', refetch }) => {
  const [updateUserMutation] = useMutation(UPDATE_USER_ABOUT_ME);
  const [Visible3, setVisible3] = useState(false);
  const [content, setContent] = useState(aboutMe);
  const [isReadMore, setIsReadMore] = useState(true);
  const [readMore, setreadMore] = useState('');
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    readMoreLength();
  };
  const readMoreLength = () => {
    if (aboutMe.length > 150 && isReadMore) {
      setreadMore('...read more');
    } else if (aboutMe.length > 150 && !isReadMore) {
      setreadMore(' show less');
    }
  };

  const updateAboutMe = async () => {
    try {
      const result = await updateUserMutation({
        variables: {
          input: {
            aboutMe: content,
          },
        },
      });
      setVisible3(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    readMoreLength();
  });
  return (
    <div className={styles.AboutMe}>
      <div className={styles.editContainer}>
        <img
          src={Edit}
          className={styles.edit}
          onClick={() => setVisible3(true)}
          alt=""
        />
      </div>
      <div className={styles.title}>
        <p>About Me</p>
      </div>
      <div className={styles.body}>
        <h4 className={styles.text}>
          {isReadMore ? aboutMe.slice(0, 150) : aboutMe}
          <span onClick={toggleReadMore} className={styles.readorhide}>
            {readMore}
          </span>
        </h4>
      </div>
      <Modal
        title="Edit your about me"
        centered
        visible={Visible3}
        onOk={() => updateAboutMe()}
        onCancel={() => setVisible3(false)}
        className={styles.ModalAboutMe}
        bodyStyle={{ height: '250px' }}
      >
        <TextArea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Share ideas recommendations and links with your contacts..."
          className={styles.textAreaAboutMe}
          maxLength={420}
          rows={4}
          showCount
        />
      </Modal>
    </div>
  );
};

export default AboutMe;
