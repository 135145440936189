import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import Styles from './PrivacyTermsFooter.module.scss';
import greyLogoSVG from '../../img/LRLogoDark.png';
import 'antd/dist/antd.css';

const PrivacyTermsFooter = () => {
  return (
    <div className={Styles.footerContainer}>
      <Row justify="center">
        <Col xs={24} md={3}>
          <p className={Styles.linkText}>
            <img
              className={Styles.footerLogo}
              src={greyLogoSVG}
              alt="Footer Logo"
            />{' '}
            ©2020
          </p>
        </Col>
        <Col offset={1} xs={24} md={3}>
          <Link className={Styles.link} to="/acceptableusepolicy">
            Acceptable Use Policy
          </Link>
        </Col>
        <Col xs={24} md={2}>
          <Link className={Styles.link} to="/privacypolicy">
            Privacy Policy
          </Link>
        </Col>
        <Col xs={24} md={2}>
          <Link className={Styles.link} to="/cookiepolicy">
            Cookie Policy
          </Link>
        </Col>
        <Col xs={24} md={2}>
          <Link className={Styles.link} to="/refundpolicy">
            Refund policy
          </Link>
        </Col>
        <Col xs={24} md={2}>
          <Link className={Styles.link} to="/dcmapolicy">
            DMCA policy
          </Link>
        </Col>
        <Col xs={24} md={2}>
          <Link className={Styles.link} to="/disclaimer">
            Disclaimer
          </Link>
        </Col>
        <Col xs={24} md={3}>
          <Link className={Styles.link} to="/termsandconditions">
            Terms and conditions
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default PrivacyTermsFooter;
