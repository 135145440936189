import React from 'react';
import { Row, Col } from 'antd';
import Styles from '../Cards.module.scss';

import campaign from '../img/campaign-sample.jpg';
import profile from '../img/profile-sample.jpg';

const Campaign = () => {
  return (
    <div className={`${Styles.campaignCard} ${Styles.cardBorderShadow}`}>
      <Row gutter={[12, 12]}>
        <Col span={4}>
          <img src={campaign} className="img-responsive" alt="profile" />
        </Col>
        <Col span={20}>
          <h5 className={Styles.connectionTitle}>
            We summon the darkness (2019) completes $100,000 Raise
          </h5>
          <h6 className={Styles.campaignSubTitle}>Thriller / English</h6>
          <h6 className={Styles.campaignSubTitle}>
            Locations: Los Angeles, CA, US
          </h6>
          <Row>
            <Col>
              <img
                src={profile}
                className={Styles.backingBubble}
                alt="profile"
              />
            </Col>
            <Col>
              <h6 className={Styles.peopleBackingUp}>
                Christian Hernandez and 758 others backed this project.
              </h6>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Campaign;
