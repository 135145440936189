import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './FooterColumn.module.scss';

import { HashLink as Link } from 'react-router-hash-link';

const FooterColumn = ({ columnTitle, links }) => {
  const { t } = useTranslation('landing');
  return (
    <div>
      <div className={style.footerTitle}>{t(columnTitle)}</div>
      <div className={style.linksContainer}>
        {links.map((link, index) => (
          <Link key={index} className={style.footerItem} to={link.href}>
            {t(link.title)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FooterColumn;
