import React from 'react'
import { Row, Col, Input ,Button, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styles from './JobsSearcher.module.scss'

const JobsSearcher = ( country, value )=>{

    return(
        <div>
            <Row>
            <Col span={5} offset={1} className={styles.typeJobSection}>
              <Breadcrumb separator="|">
                <Breadcrumb.Item >
                  <span className={styles.castingJobs}>Jobs</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <span className={styles.castingJobs}>Casting Calls</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={[32, 32]}>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    //value={job}
                    size="large"
                    placeholder="Company or position"
                    prefix="What?"
                    //onChange={(e) => onChangeJobs(e)}
                  />
                </Col>
                <Col xs={16} md={14} lg={10}>
                  <Input
                    //value={location}
                    size="large"
                    placeholder="Region or City"
                    prefix="Where?"
                    //onChange={(e) => onChangeLocation(e)}
                  />
                </Col>
                <Col>
                <Link
                    to={{
                      pathname: '/jobs',
                      jobsProps: { location: country, jobTitle: value },
                    }}
                  >
                  <Button type="primary" shape="round" size="large">
                    
                    Search
                  </Button>

                  </Link>
                  
                </Col>
              </Row>
        </div>
    )
}

export default JobsSearcher