const en = {
  landing: {
    connectCreateCollaborate: 'Connect, create, collaborate, get funded',
    whatIsLinkrease:
      'Linkrease is a disruptive platform where entertainment industry professionals and aspirational talent can relevantly connect, create, collaborate crowd-source and fund an entertainment project, ultimately translating what would otherwise be a simple idea, script or amateur content into viable and monetizable projects.',
    jointheContent: 'Join the content creation revolution',
    newToLinkrease: 'New to Linkrease?',
    myEntertainmentVerticalTitle: 'MY ENTERTAINMENT VERTICAL',
    theProfessionalNetworkPlatformForContentCreation:
      'The professional network platform for content creation',
    collaborativeNetworkText:
      'THE <strong>COLLABORATIVE</strong> NETWORK PLATAFORM FOR THE <strong>ENTERTAINMENT</strong> INDUSTRY',
    whyLinkrease: 'WHY LINKREASE?',
    signIn: 'Sign In',
    joinNow: 'Join Now',
    MonetizeYourCreativity: 'Monetize your creativity',
    whetherInFilm:
      'Whether in film, television, music, sports or gaming Linkrease is the professional network where aspirational and professional talent come together to create content at a massive scale.  ',
    myEntertainmentVertical: 'MY ENTERTAINMENT VERTICAL',
    film: 'Film',
    sports: 'Sports',
    music: 'Music',
    gaming: 'Gaming',
    continue: 'CONTINUE',
    orJoinUs: 'OR JOIN US WITH',
    whoIsLinkreaseFor: 'Who is Linkrease for?',
    lookingToProduce:
      'Anyone looking to produce and fund an entertainment project:',
    filmMakers: 'Film Makers',
    castingAgents: 'Casting Agents',
    videoCreators: 'Video Creators',
    managementCompanies: 'Management Companies',
    crew: 'Crew',
    musicians: 'Musicians',
    showMore: 'show more',
    showLess: 'show less',
    getStarted: 'GET STARTED',
    connectCreate: 'Connect, Create, Collaborate, Get Funded',
    linkreasePlatformDescription:
      'Linkrease is a disruptive platform where entertainment industry professionals and aspirational talent can relevantly connect, create, collaborate, crowd source and fund an entertainment project, ultimately translating what would otherwise be a simple idea, script or amateur content into viable and monetizable projects.',
    areYouAnInfluencer: 'Are you an Influencer?',
    learnMore: 'LEARN MORE',
    forIndustryProfessionals: 'For Industry Professionals',
    monetizeBy: 'MONETIZE YOUR INDUSTRY PROFESSIONAL STATUS BY:',
    attachingYourName: 'Attaching your name, experience',
    participatingWithCreators:
      'Participating with other Creators in new development projects',
    resultingIn: 'Resulting in enhanced content that',
    industryDecisionMakers: 'Industry decision makers leading to',
    fundingDistributionDeals: 'Funding distribution deals and',
    exponentialIncrease: 'An exponential increase of your',
    forAspirationalTalent: 'For Aspirational Talent',
    increaseYourChances: 'INCREASE YOUR CHANCES OF SUCCESS BY:',
    connectedToVetted: 'Connected to Vetted entertainment',
    collaboratingWithYou: 'Help you become active in the industry by',
    collaboratingWithYourProj: 'Collaborating with you on your project',
    curatedGradeProjects: 'Curated grade projects that can be seen',
    vettedCrowdfundingRounds: 'Vetted crowdfunding rounds alternative',
    forInfluencers: 'For\nInfluencers',
    monetizeYourInfluence: 'MONETIZE YOUR INFLUENCE:',
    attachingYourNameEntertain: 'Attaching your name to the entertainment',
    collaboratingProjectTeams: 'Collaborating with project teams',
    sharingProjectsYouSponsor: 'Sharing the projects you sponsor',
    reachOutToFollowers: 'Reaching out to your followers to',
    registerForEarlyAccess: 'Register for early access',
    signUp: 'Sign Up',
    harnessThePower:
      'Harness the power of your network and monetize your creativity',
    linkreaseIsWhere:
      'Linkrease is where aspirational talent bring their ideas for an entertainment project and verified industry professionals join in to make them viable.',
    pleaseSelectOne: 'Please select one option',
    collaborationTools: 'Collaboration tools to set you up for success',
    ideaToSubmission:
      'From Idea Submission, to attracting talent or launching a crowdfunding campaign for your entertainment project Linkrease gives you and your team the right tools to manage your deal flow and monetize your opportunities faster and smarter.',
    learnMoreAboutHowItWorks: 'Learn More about how it works',
    crowdFundYourEligibleProjects: 'Crowdfund your eligible projects',
    leverageNetworkEffect:
      'Whether you are the star or the spark of a crowdfunding campaign, our users can leverage the network effect of the linkrease platform to bring their entertainment projects to life.',
    startLivingYourBestCareer: 'Start living your best career life',
    weHelpAspirationalTalent:
      'We help aspirational talent land the right auditions and industry professionals find great jobs',
    jobs: 'Jobs',
    castingCalls: 'Casting Calls',
    searchJobTitlesOrComps: 'Search Job titles or companies',
    selectCityStateCountry: 'Select City, State and Country',
    findJobs: 'Find Jobs',
    areYouAnEmployer: 'Are you an Employer or Casting agency?',
    seeHowOurSolutionsHelp: 'See how our talent solutions can help you',
    postJob: 'Post a Job',
    listCastingCall: 'List a Casting Call',
    company: 'Company',
    aboutUs: 'About Us',
    leadership: 'Leadership',
    press: 'Press',
    careers: 'Careers',
    contactUs: 'Contact Us',
    community: 'Community',
    members: 'Members',
    guidelines: 'Guidelines',
    support: 'Support',
    linkreaseForStudents: 'Linkrease for students',
    product: 'Product',
    howItWorks: 'How it works',
    features: 'Features',
    pricing: 'Pricing',
    crowdfunding: 'Crowdfunding',
    businessSolutions: 'Business Solutions',
    talent: 'Talent',
    marketing: 'Marketing',
    sales: 'Sales',
    partners: 'Partners',
    streaming: 'Streaming',
    filmAndTVStudios: 'Film & Television Studios',
    distributors: 'Distributors',
    joinTheWaitingList: 'Join the waiting list',
  },
  register: {
    whatsYourEntertainmentVertical: 'What’s your entertainment vertical?',
    makingItInTheEntertainment:
      'Making it in the Entertainment Industry can take you years. Registration takes less than 2 minutes!',
    iHaventDecidedMy: 'I haven’t decided my vertical yet',
    continue: 'CONTINUE',
    tellUsWhichDefines: 'Tell us which defines you best',
    imAspirationalTalent:
      'I’m aspirational talent. I want to break into the entertainment industry.',
    iAlreadyWorkInTheIndustry:
      'I already work in the industry. I’m an active entertainment industry professional.',
    greatLetsSetUpYour: 'Great, let’s set up your free account',
    emailAddress: 'Email address',
    password: 'Password',
    nOrMoreCharacters: '(6 or more characters)',
    repeatPassword: 'Repeat Password',
    youAgreeToTheLinkrease:
      'I agree to the Linkrease User Agreement, Privacy Policy and Cookie Policy.',
    agreeAndJoin: 'Agree and Join',
    howShouldWeGreet: 'Now, how should we greet you?',
    given_name: 'First Name',
    lastName: 'Last Name',
    nowLetsDoAQuick: 'Now, let’s do a quick security check',
    welcome: 'Welcome,',
    letsSetUpYourProfile:
      'Let’s set up your profile so you can start connecting, collaborating and monetizing content',
    countryRegion: 'Country / Region',
    city: 'City',
    postalCode: 'Postal Code',
    checkYourEmail: 'Check your email!',
    weveSentA: 'We’ve sent a 6-digit confirmation code to',
    itWillExpireShortly: 'It will expire shortly so enter it soon',
    keepThisWindowOpen:
      'Keep this window open while checking for your code. Remember to try your spam folder!',
    textMeTheCode: 'Text me the code instead',
    sendCode: 'Send Code',
    didntReceiveTheCode: 'Didn’t receive the code?',
    sendAgain: 'Send again',
    oopsYouHaveEntered:
      'Oops, you have entered an invalid code. Please re-enter.',
    nowTellUsWhyYou: 'Now, tell us why you’re joining',
    selectAllThatApply: 'Select all that apply',
    thisInformationWillHelp:
      'This information will help us tailor your opportunities for monetizing content',
    connectWithOtherProfessionals:
      'Connect with other Professionals / Influencers',
    crowdfundProjectsCampaigns: 'Crowdfund Projects / Campaigns',
    listSubmitFinance: 'List / Submit / Finance Entertainment Projects',
    searchJobsPostJobs: 'Search Jobs / Post Jobs',
    joinProjectsAndIncrease: 'Join Projects & Increase Deal Flow',
    imAnInvestorApply: 'I’m an Investor / Apply for Investor Status*',
    other: 'Other',
    accessMultipleEarlyStage:
      'Access multiple early stage investment opportunities with your Investor Profile',
    inOrderToViewFinancial:
      'In order to view financial information and receive alerts about entertainment project investments on Linkrease, you must fill out the following information for our records. This will not be visible to anyone.',
    areYouAUsCitizen: 'Are you a U.S citizen?',
    areYouAnAccredited: 'Are you an accredited investor?',
    yes: 'Yes',
    no: 'No',
    pleaseSelectAtLeast: 'Please select at least one',
    whatMakesYouAn: 'What makes you an Accredited Investor?',
    whatDoesThisMean: '( What does this mean? )',
    iInvestOnBehalfOf:
      'I invest on behalf of a company or funds with assets exceeding $5 million',
    iHaveANetWorth:
      'I have a net worth of at leaset $1 million (excluding my primary residence)',
    iHaveAnIndividualIncome:
      'I have an individual income of at least $200,000, or a joint income of at least $300,000',
    apply: 'Apply',
    iWillVerifyLater: 'I will verify later',
    bePartOfMultiple: 'Be part of multiple projects by getting verified',
    attachYourEntertainmentIndustry:
      'Attach your entertainment industry profile to a project and increase its chances of funding.',
    learnMore: 'Learn more',
    verifyWithLinkedin: 'Verify with Linkedin',
    verifyWithWorkEmail: 'Verify using my work email',
    linkedinWillOnlyBeUsed:
      'Linkedin will only be used to verify your place of work. We will not change how you login to Linkrease',
    yourWorkEmailWillOnly:
      'Your work email will only be used to verify your place of work. We will not change how you login to Linkrease',
    becomeAVerfiedEntertainment:
      'Become a verified entertainment industry professional',
    verifyingIncreasesYourChances:
      'Verifying increases your chances of ranking higher within our system. The higher your rank, the more relevant your connectivity, collaboration, and funding opportunities become.',
    mostRecentCompany: 'Most recent company',
    mostRecentTitle: 'Most recent title',
    fromTo: 'From / To:',
    selectTheFieldThatBest:
      'Select the field that best describe your entertainment career',
    iWillAddThisLater: 'I will add this later',
    yourProfessionalSideHas: 'Your professional side has a face. Make it count',
    addingAPhotoMakes: 'Adding a photo makes people recognize you',
    useMyGooglePhoto: 'Use my Google photo',
    useMyFacebookPhoto: 'Use my Facebook photo',
    yourEntertainmentPortfolioHelps:
      'your entertainment portfolio helps you showcase your best work',
    addWebsite: 'Add website+',
    addLink: 'Add link+',
    viewMore: 'View more',
    awardOrganization: 'Award Organization',
    award: 'Award',
    dateReceived: 'Date received',
    addAward: 'Add Award',
    cancel: 'Cancel',
    congratulations: 'Congratulations',
    talent: 'Talent',
    filmmaker: 'Filmmaker',
    executive: 'Executive',
    casting: 'Casting',
    companiesstudios: 'Companies & Studios',
    crew: 'Crew',
    representation: 'Representation',
    actor: 'Actor',
    actress: 'Actress',
    producer: 'Producer',
    director: 'Director',
    cinematographer: 'Cinematographer',
    animationDepartment: 'Animation Department',
    artDirector: 'Art Director',
    editor: 'Editor',
    castingAgent: 'Casting Agent',
    castingDirector: 'Casting Director',
    castingDepartment: 'Casting Department',
    manager: 'Manager',
    publicist: 'Publicist',
    studioExecutive: 'Studio Executive',
    companyOwner: 'Company Owner',
    marketingDirector: 'Marketing Director',
    advertising: 'Advertising',
    sales: 'Sales',
    bankingFinance: 'Banking, Finance',
    cLevelOperations: 'C - Level, Operations',
    dLevelDirector: 'D - Level, Director',
    vicePresident: 'Vice – President, VP',
    verifyLinkedinTitle:
      'Guillermo be part of multiple projects by getting verified',
    verifyLinkedinSub:
      'Attach your entertainment industry profile to a project and increase its chances of funding.',
    linkedInDisclaimer: `<strong>LinkedIn</strong> will only be used to verify your place of work. We will not change how you login to 
        <strong>Linkrease</strong>. Your <strong>work email</strong> will only be used to verify your place of work. We will not change how you login to 
        <strong>Linkrease</strong>.`,
    becomeVerifiedTitle:
      'Guillermo, become a verified entertainment industry professional',
    becomeVerifiedSubtitle:
      'Verifying increases your chances of ranking higher within our system. The higher your rank the more relevant your connectivity, collaboration and funding opportunities become.',
    rankedProfileTitle: '',
    rankedProfileSubtitle:
      '<strong>Congratulations guillermo!! <br/> You have ranked as a Verified Professional </strong>',
    rankedProfileMessage:
      'Your verified investor status is Pending. <br/> Make sure to check your email to complete the investor Profile Status. <br/> Once completed and verified your rank will be updated to Verified Investor.',
    rankedHelpMeButton: 'Help me get things set up',
    rankedGoHomeButton: 'Go to Homepage',
  },
  compactFooter: {
    about: 'About',
    agreement: 'User Agreement',
    privacyPolicy: 'Privacy Policy',
    cookiePolicy: 'Cookie Policy',
    copyrightPolicy: 'Copyright Policy',
    brandPolicy: 'Brand Policy',
    guestControls: 'Guest Controls',
    communityGuidelines: 'Community Guidelines',
    language: 'Language',
  },
  errors: {
    404: "We can't find the page that you're looking for.",
    500: "It's not you, it’s us. We are working on fixing the problem.",
    403: 'Access denied. You don’t have permissions to access this page.',
  },
  about: {},
  contact: {},
  LOGIN_ERROR: 'Invalid username or password!',
};

export default en;
