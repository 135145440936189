import React from 'react';
import styles from './AcceptableUsePolicy.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import CorpNav from '../CorpNav/CorpNav';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';
const AcceptableUsePolicy = () => {
  return (
    <div className={styles.div}>
      <CorpNav />
      <div className={styles.banner__headline_container}>
        <div className={styles.banner__position}>
          <h1 className={styles.banner__headline_t_64}>
            Acceptable Use Policy
          </h1>
        </div>
      </div>

      <div className={styles.content}>
        <p>
          This acceptable use policy (&quot;Policy&quot;) sets forth the general
          guidelines and acceptable and prohibited uses of the
          <a target="_blank" rel="nofollow" href="https://www.linkrease.com">
            {' '}
            linkrease.com
          </a>{' '}
          website (&quot;Website&quot;), &quot;Linkrease&quot; mobile
          application (&quot;Mobile Application&quot;) and any of their related
          products and services (collectively, &quot;Services&quot;). This
          Policy is a legally binding agreement between you (&quot;User&quot;,
          &quot;you&quot; or &quot;your&quot;) and Linkrease Technologies, LLC
          (&quot;Linkrease Technologies, LLC&quot;, &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot;). By accessing and using the
          Services, you acknowledge that you have read, understood, and agree to
          be bound by the terms of this Agreement. If you are entering into this
          Agreement on behalf of a business or other legal entity, you represent
          that you have the authority to bind such entity to this Agreement, in
          which case the terms &quot;User&quot;, &quot;you&quot; or
          &quot;your&quot; shall refer to such entity. If you do not have such
          authority, or if you do not agree with the terms of this Agreement,
          you must not accept this Agreement and may not access and use the
          Services. You acknowledge that this Agreement is a contract between
          you and Linkrease Technologies, LLC, even though it is electronic and
          is not physically signed by you, and it governs your use of the
          Services.
        </p>
        <h2 className={styles.h2}>Prohibited activities and uses</h2>
        <p>
          You may not use the Services to publish content or engage in activity
          that is illegal under applicable law, that is harmful to others, or
          that would subject us to liability, including, without limitation, in
          connection with any of the following, each of which is prohibited
          under this Policy:
        </p>
        <ul className={styles.ul}>
          <li>Distributing malware or other malicious code.</li>
          <li>Disclosing sensitive personal information about others.</li>
          <li>
            Collecting, or attempting to collect, personal information about
            third parties without their knowledge or consent.
          </li>
          <li>Distributing pornography or adult related content.</li>
          <li>
            Promoting or facilitating prostitution or any escort services.
          </li>
          <li>
            Hosting, distributing or linking to child pornography or content
            that is harmful to minors.
          </li>
          <li>
            Promoting or facilitating gambling, violence, terrorist activities
            or selling weapons or ammunition.
          </li>
          <li>
            Engaging in the unlawful distribution of controlled substances, drug
            contraband or prescription medications.
          </li>
          <li>
            Managing payment aggregators or facilitators such as processing
            payments on behalf of other businesses or charities.
          </li>
          <li>
            Facilitating pyramid schemes or other models intended to seek
            payments from public actors.
          </li>
          <li>
            Threatening harm to persons or property or otherwise harassing
            behavior.
          </li>
          <li>
            Manual or automatic credit card or other available payment methods
            testing using bots or scripts.
          </li>
          <li>
            Purchasing any of the offered Services on someone else’s behalf.
          </li>
          <li>
            Misrepresenting or fraudulently representing products or services.
          </li>
          <li>
            Infringing the intellectual property or other proprietary rights of
            others.
          </li>
          <li>
            Facilitating, aiding, or encouraging any of the above activities
            through the Services.
          </li>
        </ul>
        <h2 className={styles.h2}>System abuse</h2>
        <p>
          Any User in violation of the Services security is subject to criminal
          and civil liability, as well as immediate account termination.
          Examples include, but are not limited to the following:
        </p>
        <ul className={styles.ul}>
          <li>
            Use or distribution of tools designed for compromising security of
            the Services.
          </li>
          <li>
            Intentionally or negligently transmitting files containing a
            computer virus or corrupted data.
          </li>
          <li>
            Accessing another network without permission, including to probe or
            scan for vulnerabilities or breach security or authentication
            measures.
          </li>
          <li>
            Unauthorized scanning or monitoring of data on any network or system
            without proper authorization of the owner of the system or network.
          </li>
        </ul>
        <h2 className={styles.h2}>Service resources</h2>
        <p>
          You may not consume excessive amounts of the resources of the Services
          or use the Services in any way which results in performance issues or
          which interrupts the Services for other Users. Prohibited activities
          that contribute to excessive use, include without limitation:
        </p>
        <ul className={styles.ul}>
          <li>
            Deliberate attempts to overload the Services and broadcast attacks
            (i.e. denial of service attacks).
          </li>
          <li>
            Engaging in any other activities that degrade the usability and
            performance of the Services.
          </li>
        </ul>
        <h2 className={styles.h2}>No spam policy</h2>
        <p>
          You may not use the Services to send spam or bulk unsolicited
          messages. We maintain a zero tolerance policy for use of the Services
          in any manner associated with the transmission, distribution or
          delivery of any bulk e-mail, including unsolicited bulk or unsolicited
          commercial e-mail, or the sending, assisting, or commissioning the
          transmission of commercial e-mail that does not comply with the U.S.
          CAN-SPAM Act of 2003 (&quot;SPAM&quot;).
        </p>
        <p>
          Your products or services advertised via SPAM (i.e. Spamvertised) may
          not be used in conjunction with the Services. This provision includes,
          but is not limited to, SPAM sent via fax, phone, postal mail, email,
          instant messaging, or newsgroups.
        </p>
        <p>
          Sending emails through the Services to purchased email lists
          (&quot;safe lists&quot;) will be treated as SPAM.
        </p>
        <h2 className={styles.h2}>Defamation and objectionable content</h2>
        <p>
          We value the freedom of expression and encourage Users to be
          respectful with the content they post. We are not a publisher of User
          content and are not in a position to investigate the veracity of
          individual defamation claims or to determine whether certain material,
          which we may find objectionable, should be censored. However, we
          reserve the right to moderate, disable or remove any content to
          prevent harm to others or to us or the Services, as determined in our
          sole discretion.
        </p>
        <h2 className={styles.h2}>Copyrighted content</h2>
        <p>
          Copyrighted material must not be published via the Services without
          the explicit permission of the copyright owner or a person explicitly
          authorized to give such permission by the copyright owner. Upon
          receipt of a claim for copyright infringement, or a notice of such
          violation, we will immediately run full investigation and, upon
          confirmation, will notify the person or persons responsible for
          publishing it and, in our sole discretion, will remove the infringing
          material from the Services. We may terminate the Service of Users with
          repeated copyright infringements. Further procedures may be carried
          out if necessary. We will assume no liability to any User of the
          Services for the removal of any such material. If you believe your
          copyright is being infringed by a person or persons using the
          Services, please get in touch with us to report copyright
          infringement.
        </p>
        <h2 className={styles.h2}>Security</h2>
        <p>
          You take full responsibility for maintaining reasonable security
          precautions for your account. You are responsible for protecting and
          updating any login account provided to you for the Services. You must
          protect the confidentiality of your login details, and you should
          change your password periodically.
        </p>
        <h2 className={styles.h2}>Enforcement</h2>
        <p>
          We reserve our right to be the sole arbiter in determining the
          seriousness of each infringement and to immediately take corrective
          actions, including but not limited to:
        </p>
        <ul className={styles.ul}>
          <li>
            Suspending or terminating your Service with or without notice upon
            any violation of this Policy. Any violations may also result in the
            immediate suspension or termination of your account.
          </li>
          <li>
            Disabling or removing any content which is prohibited by this
            Policy, including to prevent harm to others or to us or the
            Services, as determined by us in our sole discretion.
          </li>
          <li>
            Reporting violations to law enforcement as determined by us in our
            sole discretion.
          </li>
          <li>
            A failure to respond to an email from our abuse team within 2 days,
            or as otherwise specified in the communication to you, may result in
            the suspension or termination of your account.
          </li>
        </ul>
        <p>
          Suspended and terminated User accounts due to violations will not be
          re-activated.
        </p>
        <p>
          Nothing contained in this Policy shall be construed to limit our
          actions or remedies in any way with respect to any of the prohibited
          activities. We reserve the right to take any and all additional
          actions we may deem appropriate with respect to such activities,
          including without limitation taking action to recover the costs and
          expenses of identifying offenders and removing them from the Services,
          and levying cancellation charges to cover our costs. In addition, we
          reserve at all times all rights and remedies available to us with
          respect to such activities at law or in equity.
        </p>
        <h2 className={styles.h2}>Reporting violations</h2>
        <p>
          If you have discovered and would like to report a violation of this
          Policy, please contact us immediately. We will investigate the
          situation and provide you with full assistance.
        </p>
        <h2 className={styles.h2}>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Policy or its terms relating to
          the Services at any time, effective upon posting of an updated version
          of this Policy on the Services. When we do, we will send you an email
          to notify you. Continued use of the Services after any such changes
          shall constitute your consent to such changes.
        </p>
        <h2 className={styles.h2}>Acceptance of this policy</h2>
        <p>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Services you agree to
          be bound by this Policy. If you do not agree to abide by the terms of
          this Policy, you are not authorized to access or use the Services.
        </p>
        <h2 className={styles.h2}>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to it, you may
          send an email to
          &#115;&#117;pp&#111;&#114;t&#64;&#108;i&#110;&#107;&#114;ease&#46;&#99;o&#109;.
        </p>
        <p>This document was last updated on June 2, 2021</p>
      </div>
      <div className={styles.landingFooterContainer}>
        <LandingFooter />
        <PrivacyTermsFooter />
      </div>
    </div>
  );
};

export default AcceptableUsePolicy;
