import React, { useState, Fragment } from 'react';
import { Form, Button, Row, Col, Select, Divider, Input } from 'antd';
import Styles from './Awards.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as BuildingIcon } from './img/building.svg';
import { ReactComponent as AwardIcon } from './img/award.svg';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

function onSearch(val) {
  console.log('search:', val);
}

const Awards = () => {
  const [numberOfAwards, setNumberOfAwards] = useState(1);
  const [awards, setAwards] = useState([
    { awardOrganization0: '', award0: '', yearReceived0: '' },
  ]);

  const onSubmit = (values) => {
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addOrganizationAction = () => {
    console.log('addOrganizationAction');
  };

  const onAddOrganizationChange = () => {
    console.log('onAddOrganizationChange');
  };

  const addAwardAction = () => {
    console.log('addOrganizationAction');
  };

  const onAddAwardChange = () => {
    console.log('onAddOrganizationChange');
  };

  const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
  };

  const updateInputValueHandler = (value, index, fieldName) => {
    const awardsCopy = [...awards];

    awardsCopy[index][`${fieldName}${index}`] = value;

    setAwards(awardsCopy);
  };

  const renderAwardInputs = (numberOfAwards) => {
    const awardArr = Array(numberOfAwards)
      .fill()
      .map((x, i) => i);

    return awardArr.map((number) => {
      const awardObject = awards[number];

      return (
        <Fragment key={`award-${number}`}>
          {number > 0 && (
            <Row gutter={8} align="middle">
              <Col span={6} offset={9}>
                <hr />
              </Col>
            </Row>
          )}
          <Row gutter={8} align="middle">
            <Col span={1} offset={6}>
              <Icon className={Styles.logo} component={BuildingIcon} />
            </Col>
            <Col span={10}>
              <Form.Item
                label="AWARD ORGANIZATION"
                name={`awardOrganization${number}`}
                labelAlign="left"
                className="formLabel"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={awardObject[`awardOrganization${number}`]}
                  onChange={(value) =>
                    updateInputValueHandler(value, number, 'awardOrganization')
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />

                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: 'auto' }}
                          onChange={onAddOrganizationChange}
                        />
                        <span
                          style={{
                            flex: 'none',
                            padding: '8px',
                            display: 'block',
                            cursor: 'pointer',
                          }}
                          onClick={addOrganizationAction}
                        >
                          <PlusOutlined /> Add organization
                        </span>
                      </div>
                    </div>
                  )}
                >
                  <Option value="organization1">Organization1</Option>
                  <Option value="organization2">Organization2</Option>
                  <Option value="organization3">Organization3</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} align="middle">
            <Col span={1} offset={6}>
              <Icon className={Styles.logo} component={AwardIcon} />
            </Col>
            <Col span={10}>
              <Form.Item
                label="AWARD"
                name={`award${number}`}
                labelAlign="left"
                className="formLabel"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={awardObject[`award${number}`]}
                  onChange={(value) =>
                    updateInputValueHandler(value, number, 'award')
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />

                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: 'auto' }}
                          onChange={onAddAwardChange}
                        />
                        <span
                          style={{
                            flex: 'none',
                            padding: '8px',
                            display: 'block',
                            cursor: 'pointer',
                          }}
                          onClick={addAwardAction}
                        >
                          <PlusOutlined /> Add award
                        </span>
                      </div>
                    </div>
                  )}
                >
                  <Option value="award1">Award1</Option>
                  <Option value="award2">Award2</Option>
                  <Option value="award3">Award3</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} align="middle">
            <Col span={1} offset={6}>
              <Icon className={Styles.logo} component={CalendarIcon} />
            </Col>
            <Col span={10}>
              <Form.Item
                label="DATE RECEIVED"
                name={`yearReceived${number}`}
                labelAlign="left"
                className="formLabel"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={awardObject[`yearReceived${number}`]}
                  onChange={(value) =>
                    updateInputValueHandler(value, number, 'yearReceived')
                  }
                >
                  <Option value="test1">test1</Option>
                  <Option value="test2">test2</Option>
                  <Option value="test3">test3</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      );
    });
  };

  const addAwardHandler = () => {
    setNumberOfAwards((prevNumberOfAwards) => prevNumberOfAwards + 1);
    setAwards((prevAwards) =>
      prevAwards.concat({
        [`awardOrganization${numberOfAwards}`]: '',
        [`award${numberOfAwards}`]: '',
        [`yearReceived${numberOfAwards}`]: '',
      })
    );
  };

  return (
    <div className={Styles.Awards}>
      <div className={Styles.intro}>
        <h1 className={Styles.stepTitle}>Gilberto, your entertainment portfolio <br /> helps you showcase your best work</h1>
        
      </div>
      <div className={Styles.formWrapper}>
        <Form
          {...layout}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
        >
          {renderAwardInputs(numberOfAwards)}

          <Row gutter={8} align="end">
            <Col span={3} pull={7}>
              <Button className={Styles.addAwardButton} onClick={addAwardHandler}>
                + Add Award
              </Button>
            </Col>
          </Row>

          {/* <Form.Item {...verifyBtnLayout}>
            <Button className={Styles.verifyButton} type="link">
              I will add this later
            </Button>
          </Form.Item> */}
        </Form>
      </div>
    </div>
  );
};

export default Awards;