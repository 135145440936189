import React, { useState } from 'react';

import Post from './Types/Post';
import Connection from './Types/Connection';
import Campaign from './Types/Campaign';
import Submission from './Types/Submission';

const Cards = (props) => {
  switch (props.type) {
    case 'feed':
      return <Post />;
    case 'connections':
      return <Connection />;
    case 'campaigns':
      return <Campaign />;
    case 'submissions':
      return <Submission />;
    default:
      return <div>Error</div>;
  }
};

export default Cards;
