import React from 'react';
import style from './Profile.module.scss';
import Default from '../../../img/no-img.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Profile = () => {
  const { t } = useTranslation('dashboard');
  const { status, userData } = useSelector((state) => state.session);

  if (status !== 'done') {
    return 'loading';
  }

  const profilePic =
    userData?.profileImage == null
      ? Default
      : userData?.profileImage?.split(', ');
  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');

  return (
    <div className={style.profileContainer}>
      <div className={style.imgContainer}>
        <Link to={`/profile/${userData?.userId}`}>
          <img src={smallImage} alt="profile pic" />
        </Link>
      </div>
      <div className={style.infoContainer}>
        <p className={style.welcomeText}>
          {' '}
          {t('Welcome, ')} {userData?.given_name} {userData?.family_name}
        </p>
        <p className={style.regularText}>{userData?.jobs[0]?.title}</p>
        <p className={style.regularText}>{userData?.jobs[0]?.company}</p>
        <p className={`${style.rankText} ${style.upperCase}`}>
          Rank <div className={style.numberCircle}>1</div>
        </p>
      </div>
    </div>
  );
};

export default Profile;
