import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './Greeting.module.scss';
import { Form, Input, Button } from 'antd';
import { setAccountDetails } from '../../Register.slice';
import { signUp, getLoggedUser } from '../../../Session/Session.thunks';
import { setAuthItemsToLocalStorage } from '../../../../utils';

export default function Greeting({ setCurrentStep, isLoading, setStatus }) {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const [signUpError, setSignUpError] = useState('');

  const externalData = localStorage.getItem('linkedinData')
    ? JSON.parse(localStorage.getItem('linkedinData'))
    : '';
  const apiFirstName = externalData ? externalData.FirstName : '';
  const apiLastName = externalData ? externalData.LastName : '';

  const onFinish = async (values) => {
    const { givenName, familyName } = values;
    const { email, password } = registerData;

    try {
      setStatus('pending');
      dispatch(setAccountDetails({ data: { givenName, familyName } }));

      const session = await dispatch(
        signUp({ email, password, givenName, familyName })
      ).unwrap();

      setAuthItemsToLocalStorage(session);
      await dispatch(getLoggedUser()).unwrap();
      setCurrentStep((prev) => prev + 1);

      setStatus('done');
    } catch (error) {
      setStatus('error');
      setSignUpError(error);
    }
  };

  return (
    <div className={`${style.container} maxWidth`}>
      <div className={style.intro}>
        <h1 className={style.stepTitle}>Now, how should we greet you?</h1>
        <p className={style.stepIntro}>
          This is how you will appear in your content creator professional
          profile
        </p>
      </div>

      {signUpError && <p className="validation-error">{signUpError}</p>}

      <Form name="greeting" onFinish={onFinish} className={style.loginForm}>
        <label htmlFor="givenName" className="formLabel">
          FIRST NAME
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="givenName"
          initialValue={apiFirstName}
          rules={[
            {
              required: true,
              message: 'Please input your First Name!',
            },
            {
              message: 'No whitespaces allowed!',
              pattern: /^\S*$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <label htmlFor="familyName" className="formLabel">
          LAST NAME
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="familyName"
          initialValue={apiLastName}
          rules={[
            {
              required: true,
              message: 'Please input your Last Name!',
            },
            {
              message: 'No whitespaces allowed!',
              pattern: /^\S*$/,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Button
          className="continueBtn"
          disabled={isLoading}
          htmlType="submit"
          type="primary"
          shape="round"
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
