import React, { useState } from 'react';
import Styles from './CreatePanel.module.scss';
import { Row, Col, Button } from 'antd';
import Icon from '@ant-design/icons';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';

import ArticleContentPanel from './Types/Article';
import PostContentPanel from './Types/Post';
import CampaignContentPanel from './Types/Campaign';
import EventContentPanel from './Types/Event';

// icons
import { ReactComponent as createArticleSvg } from './img/create-article.svg';
import { ReactComponent as sharePostSvg } from './img/share-post.svg';
import { ReactComponent as startCampaignSvg } from './img/start-campaign.svg';
import { ReactComponent as scheduleEventSvg } from './img/schedule-event.svg';
import checkIcon from './img/check.svg';

const CreatePanel = () => {

  const { url } = useRouteMatch();
  const { params: { contentType } } = useRouteMatch(`${url}/:contentType`);
  const [selectedContent, setSelectedContent] = useState(contentType);

	return (
    <div className={Styles.createPanelContainer}>
      <Row justify="center" align="middle">
        <Col span={18}>
          <Row justify="space-around" align="middle">
            <Col span={4}>
              <Link to={`${url}/article`}>
                <Button
                  className={`${Styles.navCreateButton} ${(selectedContent === 'article') ? `${Styles.selectedContent}` : ''}`}
                  block
                  onClick={() => setSelectedContent('article')}
                >
                  { (selectedContent==='article') && <img src={checkIcon} alt="checkmark" className={Styles.checkIcon}/> }
                  <Icon component={createArticleSvg} />
                  CREATE AN ARTICLE
                </Button>
              </Link>
            </Col>
            <Col span={4}>
              <Link to={`${url}/post`}>
                <Button
                  className={`${Styles.navCreateButton} ${(selectedContent === 'post') ? `${Styles.selectedContent}` : ''}`}
                  block
                  onClick={() => setSelectedContent('post')}
                >
                  {
                    (selectedContent==='post') && <img src={checkIcon} alt="checkmark" className={Styles.checkIcon}/>
                  }
                  <Icon component={sharePostSvg} />
                  SHARE A POST
                </Button>
              </Link>
            </Col>
            <Col span={4}>
              <Link to={`${url}/campaign`}>
                <Button
                  className={`${Styles.navCreateButton} ${(selectedContent === 'campaign') ? `${Styles.selectedContent}` : ''}`}
                  block
                  onClick={() => setSelectedContent('campaign')}
                >
                  { (selectedContent==='campaign') && <img src={checkIcon} alt="checkmark" className={Styles.checkIcon}/> }
                  <Icon component={startCampaignSvg} />
                  START A CAMPAIGN
                </Button>
              </Link>
            </Col>
            <Col span={4}>
              <Link to={`${url}/event`}>
                <Button
                  className={`${Styles.navCreateButton} ${(selectedContent === 'event') ? `${Styles.selectedContent}` : ''}`}
                  block
                  onClick={() => setSelectedContent('event')}
                >
                  { (selectedContent==='event') && <img src={checkIcon} alt="checkmark" className={Styles.checkIcon}/> }
                  <Icon component={scheduleEventSvg} />
                  SCHEDULE AN EVENT
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <hr className={Styles.divider} />
          <Row justify="center" align="middle">
            <Col span={18}>
              <Row justify="space-around" align="top">
                <Col span={4}>
                  { selectedContent === 'article' && <div className={Styles.selectedIndicator} /> }
                </Col>
                <Col span={4}>
                  { selectedContent === 'post' && <div className={Styles.selectedIndicator} /> }
                </Col>
                <Col span={4}>
                  { selectedContent === 'campaign' && <div className={Styles.selectedIndicator} /> }
                </Col>
                <Col span={4}>
                  { selectedContent === 'event' && <div className={Styles.selectedIndicator} /> }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Switch>
          <Route path={`${url}/article`} component={ArticleContentPanel} />
          <Route path={`${url}/post`} component={PostContentPanel} />
          <Route path={`${url}/campaign`} component={CampaignContentPanel} />
          <Route path={`${url}/event`} component={EventContentPanel} />
        </Switch>
      </Row>
    </div>
  );
};

export default CreatePanel;
