import React from 'react';
import style from './NavLinks.module.scss';
import MemberAccountIconSVG from '../MainSettings/img/memberAccount.svg';
import NotificationsIconSVG from '../MainSettings/img/notifications.svg';
import PrivacyIconSVG from '../MainSettings/img/privacy.svg';
import PurchasesIconSVG from '../MainSettings/img/purchases.svg';
import { linkValues } from './leftNavUtilities';





const NavLinks = ({areaState: [area, setArea]}) => {
	const info = linkValues;
	return (
		<div className={style.navLinksContainer}>
			{/* {info.map((el, index) => {
          return (
						<div className={style.sectionLink} onClick={() => setArea({index})}>
							<img className={style.sectionIcon} src={el.iconName} />{el.text}
						</div>
          )
        })} */}
			<div className={style.sectionLink} onClick={() => setArea('memberAccount')}>
				<img className={style.sectionIcon} src={MemberAccountIconSVG} />Member Account
			</div>
			<div className={style.sectionLink} onClick={() => setArea('notifications')}>
				<img className={style.sectionIcon} src={NotificationsIconSVG} />Notifications
			</div>
			<div className={style.sectionLink} onClick={() => setArea('privacy')}>
				<img className={style.sectionIcon} src={PrivacyIconSVG} />Privacy
			</div>
			<div className={style.sectionLink} onClick={() => setArea('purchases')}>
				<img className={style.sectionIcon} src={PurchasesIconSVG} />Purchases
			</div>
		</div>
	);
};

export default NavLinks;