import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './LandingNav.module.scss';
import logo from '../../../img/LRLogoDark.png';

const LandingNav = () => {
  const { t } = useTranslation('landing');
  const { isAuthenticated, userData } = useSelector((state) => state.session);

  return (
    <Row className={style.container}>
      <Col xs={24} lg={{ span: 2 }} xl={{ span: 2 }}>
        <Link to="/">
          <img src={logo} className={style.logo} alt="linkrease logo" />
        </Link>
      </Col>
      <Col xs={24} lg={{ span: 3, offset: 3 }} xl={{ span: 3, offset: 3 }}>
        <Link className={style.whyLinkrease} to="/about">
          Why Linkrease?
        </Link>
      </Col>
      <Col lg={6} offset={8} className={`hidden-xlarge-down`}>
        <div className={style.buttonContainer}>
          {isAuthenticated ? (
            <span
              className={style.welcomeUser}
            >{`Welcome ${userData?.given_name}!`}</span>
          ) : (
            <>
              {/* <Button
                ghost
                type="primary"
                shape="round"
                size={'large'}
                className={`${style.navBtn} ${style.auxNavBtn}`}
              >
                {t('whyLinkrease')}
              </Button> */}
              <Link to="/login">
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  size={'large'}
                  className={`${style.navBtn} ${style.auxNavBtn}`}
                >
                  {t('signIn')}
                </Button>
              </Link>
              <Link to="/register-vertical">
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  className={style.navBtn}
                >
                  {t('joinNow')}
                </Button>
              </Link>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default LandingNav;
