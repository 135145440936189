import React, { Fragment, useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import Styles from './ActionButton.module.scss';

// Icons
import { ReactComponent as ActionIcon } from './img/action-icon.svg';
import { ReactComponent as UsersIcon } from './img/users-icon.svg';
import { ReactComponent as FlagIcon } from './img/flag-icon.svg';
import { ReactComponent as ArticleIcon } from './img/add-file-icon.svg';
import { ReactComponent as ScriptIcon } from './img/script-icon.svg';
import { ReactComponent as UserIcon } from './img/user-icon.svg';

const ActionButton = (props) => {
  const [active, setActive] = useState(false);


  useEffect(() => {
    const escKeyHandler = (event) => {
      if (!active || event.keyCode !== 27) return;

      setActive(false);
    }

    document.addEventListener('keyup', escKeyHandler);

    return () => {
      document.removeEventListener('keyup', escKeyHandler);
    }
  });

  const renderMenu = () => {

    return (
      <ul className={Styles.menuButtonWrapper}>
        <li className={Styles.scriptButtonWrapper}>
          <span>SUBMIT A <br/> SCRIPT/<br/>SYNOPSIS/STORYLINE</span>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={ScriptIcon} />
          </button>
        </li>
        <li className={Styles.addMemberButtonWrapper}>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={UsersIcon} />
          </button>
          <span>ADD A MEMBER <br/>TO A PROJECT</span>
        </li>
        <li className={Styles.addCampaignButtonWrapper}>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={FlagIcon} />
          </button>
          <span>ADD A <br/>CAMPAIGN</span>
        </li>
        <li className={Styles.writeArticleButtonWrapper}>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={ArticleIcon} />
          </button>
          <span>WRITE AN <br/>ARTICLE</span>
        </li>
        <li className={Styles.endorseProjectButtonWrapper}>
          <span>ENDORSE A<br/>PROJECT</span>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={ScriptIcon} />
          </button>
        </li>
        <li className={Styles.attachNameButtonWrapper}>
          <span>ATTACH MY NAME<br/>TO A PROJECT</span>
          <button className={Styles.whiteRoundedButton}>
            <Icon component={UserIcon} />
          </button>
        </li>
      </ul>
    );
  }

  return (
    <Fragment>
      <div className={Styles.menuContainer}>
        <button
          onClick={() => setActive(!active)}
          className={`${Styles.actionButton} ${active ? Styles.actionButtonBorder : ''}`}
        >
          <Icon className={`${Styles.actionIcon} ${active ? Styles.active : ''}`} component={ActionIcon} />
        </button>
        {
          active && renderMenu()
        }
      </div>
      {
        active && <div onClick={ () => setActive(false) } className={Styles.actionOverlay} />
      }
    </Fragment>
  );
};

export default ActionButton;
