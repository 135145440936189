export const visibility = {
	activity: 'Nobody',
	portfolio: 'All Members',
	campaigns: 'My Network',
	pledges: 'All Members',
}

export const notifications = {
	chats: '2',
	reactions:'1',
	jobs:'2',
	campaigns:'2',
	pledges:'4',
	events:'1',
}