import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Confirmation.module.scss';
import logo from '../../../../img/LRLogoDark.png';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

// const confirmText = [
//   {
//     path: 'aspirational',
//     title: 'Congratulations Dante!! Your Linkrease rank is #1',
//     intro:
//       'Learn how you can rank higher and increase your monetizing oportunities',
//   },
//   {
//     path: 'professional',
//     title:
//       'Congratulations Dante!! You have ranked as a “Verified Professional”',
//     intro:
//       'Your Verified Investor Status is Pending. Make sure to check your email to complete the Investor Profile Status.Once completed and verified your rank will be updated to Verified Investor.',
//   },
// ];

// MAKE SURE A CARREER PATH WAS SELECTED

export default function Confirmation() {
  const registerData = useSelector((state) => state.register?.accountDetails);
  //  const getCurrentCarreerPathInfo = (carreerType) => {
  //    return confirmText.find((text) => text.path === carreerType);
  // };

  return (
    <div className={styles.Confirmation}>
      <img src={logo} alt="Linkerease Logo" className={styles.logo} />
      <div className={styles.intro}>
        <h1 className={styles.stepTitle}>
          Congratulations {registerData?.givenName}!! Your Linkrease rank is #1
        </h1>
        <p className={styles.stepIntro}>
          Learn how you can rank higher and increase your monetizing
          oportunities
        </p>
        <Link to="/dashboard">
          <Button className="continueBtn" type="primary" shape="round">
            Dashboard
          </Button>
        </Link>
      </div>
    </div>
  );
}
