import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../Notification/Notification';
import classes from '../LastNotifications/LastNotifications.module.scss';

export default function NotificationList({
  data,
  badgeText,
  setDrawerVisible,
}) {
  return (
    <div className={classes.notificationGroup}>
      <div className={classes.Title}>
        <span className={classes.badge}>{badgeText}</span>
      </div>
      {data.length ? (
        data.map((group) => (
          <div
            key={`notification-container-${group.id}`}
            className={classes.LastNotificationsBox}
          >
            <div className={classes.List}>
               <Notification
                key={group.id}
                aggregatedNotifications={group}
                type={group.verb}
                setDrawerVisible={setDrawerVisible}
              /> 
            </div>
          </div>
        ))
      ) : (
        <div className={classes.Empty}>
          You don&apos;t have new notifications
        </div>
      )}
    </div>
  );
}

NotificationList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  badgeText: PropTypes.string,
  setDrawerVisible: PropTypes.func,
};

NotificationList.defaultProps = {
  data: null,
  badgeText: '',
  setDrawerVisible: () => {},
};
