import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Location.module.scss';
import { Form, Input, Button } from 'antd';
import { setAccountDetails } from '../../Register.slice';
import { saveAddress } from '../../Register.thunks';

const userLocation = {
  0: {
    country: '',
    state: '',
    postalCode: '',
  },
};

function getCloseLocation() {
  var location = new XMLHttpRequest();
  location.responseType = 'json';
  location.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      userLocation.country = location.response.country_name;
      userLocation.state = location.response.region_name;
      userLocation.postalCode = location.response.zip_code;
    }
  };
  location.open('GET', 'https://freegeoip.app/json/');
  //https://geolocation-db.com/
  //https://ipstack.com/
  location.send();
}

getCloseLocation();

export default function Location({ setCurrentStep, setStatus, isLoading }) {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);

  const onFinish = async (values) => {
    const { country, state, postalCode } = values;

    try {
      setStatus('pending');

      dispatch(setAccountDetails({ data: { country, state, postalCode } }));
      await dispatch(saveAddress({ country, state, postalCode })).unwrap();

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className={styles.Location}>
      <div className={styles.intro}>
        <h1
          className={styles.stepTitle}
        >{`Welcome, ${registerData?.givenName}!`}</h1>
        <p className={styles.stepIntro}>
          Let’s set up your profile so you can start connecting, collaborating
          and monetizing content.
        </p>
      </div>
      <Form
        name="locationForm"
        onFinish={onFinish}
        className={styles.formWrapper}
        initialValues={{
          remember: true,
          country: userLocation.country,
          state: userLocation.state,
          postalCode: userLocation.postalCode,
        }}
      >
        <label htmlFor="country" className="formLabel">
          COUNTRY / REGION
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="country"
          rules={[
            { required: true, message: 'Please input your postal country!' },
          ]}
        >
          <Input />
        </Form.Item>

        <label htmlFor="state" className="formLabel">
          State
        </label>

        <Form.Item
          validateTrigger="onBlur"
          name="state"
          rules={[
            { required: true, message: 'Please input your postal state!' },
          ]}
        >
          <Input />
        </Form.Item>

        <label htmlFor="postalCode" className="formLabel">
          Postal Code
        </label>
        <Form.Item
          validateTrigger="onBlur"
          name="postalCode"
          rules={[
            { required: true, message: 'Please input your postal Code!' },
            {
              message: 'Only numbers can be entered',
              pattern: /^[0-9]+$/,
            },
            {
              max: 5,
              message: 'Cannot be greater than 5 characters',
            },
          ]}
        >
          <Input maxLength="5" />
        </Form.Item>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
