import React from 'react';
import Icon from '@ant-design/icons';
import Styles from './Connections.module.scss';
import { Link } from 'react-router-dom';

import { ReactComponent as usersIconSVG } from './img/users-icon.svg';
import { ReactComponent as briefcaseIconSVG } from './img/briefcase-icon.svg';
import { ReactComponent as projectsIconSVG } from './img/projects-icon.svg';
import { ReactComponent as calendarIconSVG } from './img/calendar-icon.svg';
import { ReactComponent as groupsIconSVG } from './img/groups-icon.svg';
import { ReactComponent as companiesIconSVG } from './img/companies-icon.svg';

const Connections = () => {

	return (
		<div className={Styles.connectionContainer}>
      <Link>
        <Icon component={usersIconSVG} />
        My network <span className={Styles.fontBlue}>(26)</span>
      </Link>
      <Link>
        <Icon component={briefcaseIconSVG} />
        My jobs
      </Link>
      <Link>
        <Icon component={projectsIconSVG} />
        My projects
      </Link>
      <Link>
        <Icon component={calendarIconSVG} />
        My events
      </Link>
      <Link>
        <Icon className={Styles.groupsIcon} component={groupsIconSVG} />
        My groups
      </Link>
      <Link>
        <Icon component={companiesIconSVG} />
        My companies
      </Link>
    </div>
	);
};

export default Connections;