import React from "react";
import styles from "../PostJobDetails.module.scss";
import {
  Form,
  Input,
  Checkbox,
  AutoComplete,
  Col,
  Row,
  Button,
  InputNumber,
  Select,
} from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";

const PstJobPage2 = () => {
  const formValues = localStorage.getItem("formInfo")
    ? JSON.parse(localStorage.getItem("formInfo"))
    : "";

  const { Option } = Select;
  const benefits = [
    { label: "Medical", value: "Medical" },
    { label: "Dental insurence", value: "Dental insurence" },
    { label: "Vision insurence", value: "Vision insurence" },
    { label: "401k", value: "401k" },
    { label: "Plus commission", value: "Plus commision" },
  ];

  return (
    <Form layout={"vertical"}>
      <Row>
        <Col span={10}>
          <label htmlFor="howYouHeard" className={styles.pageTitles}>
            How do you hear about us
          </label>
          <Form.Item name="howYouHeard">
            <Input />
          </Form.Item>
        </Col>

        <Col span={10} offset={1}>
          <label htmlFor="offer" className={styles.pageTitles}>
            Offer code (optional)
          </label>
          <Form.Item
            name="offer"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[0, 32]}>
        <Col span={21}>
          <label htmlFor="benefits" className={styles.pageTitles}>
            Benefits
          </label>
          <div
            style={{
              border: "solid",
              borderWidth: "thin",
              borderColor: "lightgray",
              padding: "0.75rem",
              marginBottom: "2rem",
            }}
          >
            <Form.Item name="benefits">
              <Checkbox.Group className={styles.font}>
                <Checkbox value="Medical">Medical</Checkbox>
                <Checkbox value="Dental">Dental insurence</Checkbox>
                <Checkbox value="Vision">Vision insurence</Checkbox>
                <Checkbox value="401k">401k</Checkbox>
                <Checkbox value="Commission">Plus commission</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row gutter={[0, 32]}>
        <Col span={21}>
          <label className={styles.pageTitles}>Compensation Range</label>
          <div className={styles.box}>
            <Row >

              <Col span={4}>
              <InputNumber addonBefore="$" />
              </Col>

              <Col span={2}>
              <span className={styles.font}>To</span>
              </Col>

              <Col span={4}>
              <InputNumber addonBefore="$" />
              </Col>

              <Col span={4}>
              <Select defaultValue="annually" className={styles.font} style={{ width: 120 }}>
                <Option value="annually">annually</Option>
                <Option value="monthly">monthly</Option>
              </Select>
              </Col>

              <Col span={4}>
              <Checkbox value="pluscommission" className={styles.font}>Plus commission</Checkbox>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={21}>
          <label htmlFor="description" className={styles.pageTitles}>
            Company Description
          </label>
          <Form.Item name="description">
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 8 }} />
          </Form.Item>
        </Col>

            
        <Col span={21}>
          <label htmlFor="application" className={styles.pageTitles}>
            How would you like to receive your applications
          </label>
          <div
          className={styles.box}
          >
          <Form.Item name="description" wrapperCol={{span:14}}>
            <CheckboxGroup className={styles.font}>
              <Checkbox value='profile'>Let applicants apply with their Linkrease profile and notify by email (recomend)</Checkbox>
              <Input style={{marginLeft:'5%'}}/>
              <Checkbox value= 'external'>Direct applicants to external website to apply</Checkbox>
              <Input style={{marginLeft:'5%'}}/>
            </CheckboxGroup>
          </Form.Item>
          </div>
        </Col>

        <Col offset={15} span={5}>
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ width: "100%" }}
              // onClick={()=>console.log('aqui si ',updatedOTPValues)}
            >
              Post Job
            </Button>
          </Form.Item>
        </Col>

        
      </Row>
    </Form>
  );
};

export default PstJobPage2;
