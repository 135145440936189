import React from 'react';
import style from './ContactUs.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider, Button, Select, Form, Input } from 'antd';

import messageSVG from './img/messageUs.png';
import callSVG from './img/callUs.png';
import CorpNav from '../CorpNav/CorpNav';

const { Option } = Select;
const { TextArea } = Input;

const ContactUs = () => {
  const { t } = useTranslation('contact');
  return (
    <>
      <div className={style.contactContainer}>
        <CorpNav />
        <Row className={style.initialContainer} justify='center' align="middle">
          <Col span={24}>
            <p className={style.mainText}>
              Need to reach us? We'll follow up
            </p>
            <p className={style.subHeader}>
              For general inquiries, plans, pricing, feedback or just about
              anything - we're here for it all
            </p>
          </Col>
        </Row>

        <Row className={style.methodContainer} justify='center'>
          <Col className={style.imagesContainer} span={6}>
            <img className={style.colImage} src={messageSVG} alt="message" />
            <p className={style.subHeader}>
              Send us a message
            </p>
            <p className={style.regularText}>
              Someone from our team will get in touch as soon as possible
            </p>
            <div className={style.buttonContainer}>
              <Button
                className={style.button}
                block
                type="primary"
                shape="round"
                size="large"
              >
                Get in touch
              </Button>
            </div>
          </Col>
          <Col span={6}>
            <Divider className={style.colDivider} type="vertical" />
          </Col>
          <Col className={style.imagesContainer} span={6}>
            <img className={style.colImage} src={callSVG} alt="call" />
            <p className={style.subHeader}>Call us</p>
            <p className={style.regularText}>
              Just have your people call our people
            </p>
            <div className={style.buttonContainer}>
              <Button
                className={style.button}
                block
                type="primary"
                shape="round"
                size="large"
              >
                ########
              </Button>
            </div>
          </Col>
        </Row>

        <Row align="middle" className={style.sectionDivider}>
          <Col span={24}>
            <p className={style.mainText}>Send us a message</p>
            <p className={style.regularText}>
              Use the form below and we'll follow up
            </p>
          </Col>
        </Row>

        <Row justify="center">
          {/* <Col offset={10} span={20}> */}
          <Form
            size="large"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
          >
            <Form.Item className={style.subjectText} label="Subject">
              <Select defaultValue={t('General Inquiries')} size="large">
                <Option value="generalInquiries">{t('General Inquiries')}</Option>
              </Select>
            </Form.Item>
            <Form.Item className={style.verticalText} label="Entertainment Vertical">
              <Select defaultValue="Film">
                <Option value="film">{t('Film')}</Option>
                <Option value="sports">{t('Sports')}</Option>
                <Option value="music">{t('Music')}</Option>
                <Option value="gaming">{t('Gaming')}</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item>
              <Input placeholder="Work Email" />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item wrapperCol={{ span: 22 }}>
                  <Input placeholder="Country" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Input placeholder="Phone" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <TextArea
                placeholder="Message"
                autoSize={{ minRows: 6, maxRows: 10 }}
              ></TextArea>
            </Form.Item>
            <Form.Item>
              <div className={style.buttonContainer}>
                <Button
                  className={style.submitButton}
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Contact Us
                </Button>
              </div>
            </Form.Item>
          </Form>
          {/* </Col> */}
        </Row>

        <div className={style.landingFooterContainer}>
          <LandingFooter />
          <div className={style.footer}>
            <div className={style.logoContainer}>
              <div>COPYRIGHT © 2020 LINKREASE</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
