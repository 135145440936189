/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Form, Input, Button, message } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as linkedInSVG } from '../../../../img/linkedin.svg';
import { ReactComponent as mailSVG } from '../../../../img/mail.svg';
import { ReactComponent as selectedCircle } from '../../../../img/selected-circle.svg';
import Styles from './LinkedInVerification.module.scss';

const onChange = (e) => {
  console.log(e.target);
};

const emailVisibleRules = [
  { required: true, message: 'Please input your email!' },
  {
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
];

const emailHiddenRules = [
  {
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
];

const LinkedInVerification = ({ setCurrentStep, setStatus, isLoading }) => {
  const registerData = useSelector((state) => state.register?.accountDetails);
  const [isWorkEmail, setIsworkEmail] = useState(false);

  const handleWorkEmailVerification = (isVisible) => {
    setIsworkEmail(isVisible);
  };

  const onFinish = async () => {
    try {
      setStatus('pending');

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
      message.success('Saved!');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };
  return (
    <div className={`${Styles.container} maxWidth`}>
      <div className={Styles.intro}>
        <h1 className="stepTitle">
          {`${registerData?.givenName}, be part of multiple projects`}
          <br />
          {`by getting verified`}
        </h1>
        <p className="stepIntro">
          Attach your entertainment industry profile to a project and increase
          its chances of funding.
        </p>
      </div>
      <Form onFinish={onFinish} name="linkedInVerification">
        <Radio.Group onChange={onChange} className={Styles.radioGroup}>
          <Radio.Button
            onClick={() => handleWorkEmailVerification(false)}
            value="linkedIn"
            name="linkedIn"
            className={Styles.radioLinkdInVerification}
          >
            <Icon component={linkedInSVG} className={Styles.radioIcon} />
            Verify with LinkedIn
            <div className={`${Styles.checkedElement} checkedWrapper`}>
              <Icon
                component={selectedCircle}
                className={Styles.checkedIconElement}
              />
            </div>
          </Radio.Button>
          <strong className="display-block">OR</strong>
          <Radio.Button
            value="mail"
            name="mail"
            onClick={() => handleWorkEmailVerification(true)}
            className={Styles.radioLinkdInVerification}
          >
            <Icon component={mailSVG} className={Styles.radioIcon} />
            Verify using my work email
            <div className={`${Styles.checkedElement} checkedWrapper`}>
              <Icon
                component={selectedCircle}
                className={Styles.checkedIconElement}
              />
            </div>
          </Radio.Button>
        </Radio.Group>

        <div
          className={`${isWorkEmail && Styles.visible} ${
            Styles.workEmailWrapper
          }`}
        >
          <Form.Item
            validateTrigger="onBlur"
            name="email"
            className={Styles.workEmailInput}
            rules={isWorkEmail ? emailVisibleRules : emailHiddenRules}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
        <Form.Item style={{ marginTop: '30px' }}>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <div className={Styles.content}>
        <p>
          <strong>LinkedIn</strong> will only be used to verify your place of
          work. We will not change how you login to <strong>Linkrease</strong>.
          <br />
          <strong>Your Work Email</strong> will only be used to verify your
          place of work. We will not change how you login to{' '}
          <strong>Linkrease</strong>.
        </p>
      </div>
    </div>
  );
};

export default LinkedInVerification;
