import React from 'react';
import Styles from './ContentCreation.module.scss';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
const ContentCreation = () => {
  return (
    <Row justify="center">
      <Col span={24}>
        <Link to="/dashboard/create/post">
          <Button block className={Styles.contentCreationMainBtn} shape="round">
            Post an article, image, link or anything else you think is worth
            sharing
          </Button>
        </Link>
      </Col>
      <Col span={19}>
        <Row align="middle" justify="space-around">
          <Col>
            <Link className={Styles.navLinks}>All Updates</Link>
          </Col>
          <Col>
            <div className={Styles.verticalDivider} />
          </Col>
          <Col>
            <Link className={Styles.navLinks}>Groups</Link>
          </Col>
          <Col>
            <div className={Styles.verticalDivider} />
          </Col>
          <Col>
            <Link className={Styles.navLinks}>More</Link>
          </Col>
          <Col>
            <div className={Styles.verticalDivider} />
          </Col>
          <Col>
            <Link className={Styles.navLinks}>Recent Activity</Link>
          </Col>
          <Col>
            <div className={Styles.verticalDivider} />
          </Col>
          <Col>
            <Link className={Styles.navLinks}>Favorites</Link>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Link to="/dashboard/create/post">
          <Button
            block
            className={Styles.contentCreationBtn}
            type="primary"
            shape="round"
          >
            Start a Post
          </Button>
        </Link>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} offset={1}>
        <Link to="/dashboard/create/article">
          <Button
            block
            className={`${Styles.contentCreationBtn} ${Styles.blueBorderBtn}`}
            shape="round"
          >
            Write an Article
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default ContentCreation;
