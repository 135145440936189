import React, { useRef, useState } from 'react';
import { Col, Button, Divider, Row, Radio } from 'antd';
import {
  User,
  Award,
  FileText,
  Calendar,
  Briefcase,
  Link,
  ArrowRight,
  MapPin,
  Globe,
  Inbox,
} from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'antd/lib/modal/Modal';
import styles from './BasicInfo.module.scss';
import Card from './img/card.svg';
import Camera from './img/camera.svg';
import Edit from './img/edit.svg';
import NoImage from '../../../img/no-img.png';

import Companies from './img/companies.svg';
import Groups from './img/group.svg';
import Castings from './img/castings.svg';

import Grid from './img/grid.svg';
import Campaign from './img/campaign.svg';
import Banner from './img/ProfileBanner-01.png';

import Share from './img/share.png';
import Recommend from './img/recommend.png';
import Unfollow from './img/unfollow.png';
import Remove from './img/remove.png';
import Report from './img/report.png';

import Favicon from './img/favicon.png';
import Phone from './img/phone.svg';
import Email from './img/email.svg';
import Birthday from './img/birthday.svg';

import FileUpload from '../../../components/FileUpload/FileUpload';
import imageCompression from 'browser-image-compression';
import { imageOptimizationSettings } from '../../../utils/constants';
import { GET_SIGNED_URL } from '../../../graphql/signedUrl';
import { UPDATE_USER } from '../../../graphql/users';
import { useMutation } from '@apollo/client';
import { dataURLtoBlob, createFileFromBlob } from '../../../utils';
import { uploadFileS3 } from '../../../utils/uploadFile';
import { CropModal } from '../../../components/CropImage';

import SquareElementsWithTitle from '../../../containers/Register/components/SquareElementsWithTitle/SquareElementsWithTitle';
import guitarSVG from '../../../img/vertical/guitar.svg';
import controlSVG from '../../../img/vertical/game-control.svg';
import filmSVG from '../../../img/vertical/film-roll.svg';
import ballSVG from '../../../img/vertical/ball-brazilian.svg';

const data = [
  { value: 'film', text: 'Film', iconType: filmSVG },
  { value: 'sports', text: 'Sports', iconType: ballSVG },
  { value: 'music', text: 'Music', iconType: guitarSVG },
  { value: 'gaming', text: 'Gaming', iconType: controlSVG },
];

const BasicInfo = ({ userData, profilePic, isOwnProfile }) => {
  const interactionOverlay = useRef(null);
  const [getInTouchModal, setVisible] = useState(false);
  const [editGeneralInfo, setVisible2] = useState(false);
  const [editVerticalInfo, setVisible3] = useState(false);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(profilePic);
  const [isProfileImageOnEdit, setIsProfileImageOnEdit] = useState(false);
  const [isCropModalShow, setCropModalShow] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [coverImage, setCoverImage] = useState('');
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [getSignedURLMutation] = useMutation(GET_SIGNED_URL);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const fullName = userData?.given_name + ' ' + userData?.family_name;
  let showHide = false;

  const refreshPage = () => {
    window.location.reload(false);
  };

  const showOverlay = () => {
    if (showHide) {
      interactionOverlay.current.style.display = 'none';
      showHide = false;
    } else {
      interactionOverlay.current.style.display = 'inline-block';
      showHide = true;
    }
  };

  const toggleCropModal = (value) => {
    setCropModalShow(value);
  };

  const onChange = (e) => {
    // dispatch(setUserVertical(e.target.value));
  };

  async function handleCoverImageInputChange(file) {
    const optimizedFile = await imageCompression(file, {
      imageOptimizationSettings,
      maxWidthOrHeight: 828,
    });

    setCoverImageFile(optimizedFile);
    setIsProfileImageOnEdit(false);
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImage(reader.result);
      toggleCropModal(true);
    };
    reader.readAsDataURL(optimizedFile);
  }

  async function handleProfileImageInputChange(file) {
    const optimizedFile = await imageCompression(file, {
      imageOptimizationSettings,
      maxWidthOrHeight: 360,
    });

    setProfileImageFile(optimizedFile);
    setIsProfileImageOnEdit(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImage(reader.result);
      toggleCropModal(true);
    };
    reader.readAsDataURL(optimizedFile);
  }

  const onImageCrop = async (croppedImgDataUrl) => {
    if (isProfileImageOnEdit) {
      const fileExtension = profileImageFile.name.split('.').pop();

      setProfileImage(croppedImgDataUrl);
      setProfileImageFile(
        createFileFromBlob(
          dataURLtoBlob(croppedImgDataUrl),
          `${uuidv4()}.${fileExtension}`,
          profileImageFile.type
        )
      );

      const type = 'image';
      const mime = 'image/jpeg';
      try {
        const signedURL = await getSignedURLMutation({
          variables: {
            input: {
              type,
              mime,
            },
          },
        });
        const signedURLJson = JSON.parse(signedURL.data.getSignedURL.signedURL);
        const urls = JSON.parse(signedURL.data.getSignedURL.urls);
        await uploadFileS3(signedURLJson, profileImageFile);
        const updatedUSer = await updateUserMutation({
          variables: {
            input: {
              profileImage: signedURLJson.fields.Key,
            },
          },
        });
        refreshPage();
      } catch (e) {
        console.log('getsignedmutation error: ', e);
      }
    } else {
      const fileExtension = coverImageFile.name.split('.').pop();
      setCoverImage(croppedImgDataUrl);
      setCoverImageFile(
        createFileFromBlob(
          dataURLtoBlob(croppedImgDataUrl),
          `${uuidv4()}.${fileExtension}`,
          coverImageFile.type
        )
      );
    }
    toggleCropModal(false);
  };

  return (
    <div className={styles.BasicInfo}>
      <div className={styles.imagesContainer}>
        <div className={styles.coverPhoto}>
          <img
            className={styles.bannerImage}
            src={coverImage || Banner}
            alt=""
          />
          <div>
            {isOwnProfile ? (
              <FileUpload
                id="coverPhoto"
                className={styles.action}
                labelName={
                  <img src={Camera} className={styles.bannerEdit} alt="" />
                }
                onFileChange={handleCoverImageInputChange}
                accept="image/*"
              />
            ) : null}
          </div>
          <div className={styles.profileImageWrapper}>
            <div className={styles.imgContainer}>
              <img src={profileImage || NoImage} alt="" />
            </div>
            {isOwnProfile ? (
              <FileUpload
                id="profilePhoto"
                className={`${styles.action}`}
                labelName="Change Profile Picture"
                onFileChange={handleProfileImageInputChange}
                accept="image/*"
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <Row>
          <Col xs={24} md={20} lg={12}>
            <div className={styles.topRow}>
              <p>
                {userData?.given_name} {userData?.family_name}
              </p>
              <p className={styles.secondary}>
                Rank <span className={styles.numberCircle}>1</span>
              </p>
              <Divider className={styles.divider} type="vertical" />
              <p className={styles.secondary}>
                Vertical: Film{' '}
                {isOwnProfile ? (
                  <img
                    src={Edit}
                    className={styles.edit}
                    onClick={() => setVisible3(true)}
                    alt=""
                  />
                ) : null}
              </p>
            </div>
            <div className={styles.info}>
              <p>
                <Briefcase />
                {userData?.jobs[0]?.title}
                {' @'}
                {userData?.jobs[0]?.company}
              </p>
              <p>
                <MapPin />
                {userData?.address
                  ? userData?.address
                  : 'Miami Beach, Florida, United States'}
              </p>
              <p>
                <Globe />
                <a
                  href={
                    userData?.portafolios == null
                      ? ''
                      : userData?.portafolios[0]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userData?.portafolios == null
                    ? ''
                    : userData?.portafolios[1]}
                </a>
              </p>
              <p>
                <Inbox />
                <a onClick={() => setVisible(true)}>Get In Touch Info</a>
              </p>
              {!isOwnProfile ? (
                <div className={styles.bottomLinks}>
                  <a href="/profile">
                    <FileText />
                    View {userData?.given_name}'s Projects
                    <ArrowRight className={styles.arrow} />
                  </a>
                  <a href="/profile">
                    <img src={Campaign} alt="" />
                    View {userData?.given_name}'s Projects
                    <ArrowRight className={styles.arrow} />
                  </a>
                </div>
              ) : null}
            </div>
          </Col>
          <Col xs={0} md={4} lg={12}>
            {isOwnProfile ? (
              <>
                <div className={styles.editLinkButtonContainer}>
                  <div
                    onClick={() => setVisible2(true)}
                    className={styles.linkButtons}
                  >
                    <User />
                    <p>Profile</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <Award />
                    <p>Awards</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <FileText />
                    <p>Projects</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <img src={Companies} alt="" />
                    <p>Companies</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <Calendar />
                    <p>Events</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <img src={Groups} alt="" />
                    <p>Groups</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <Briefcase />
                    <p>Jobs</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <img src={Castings} alt="" />
                    <p>Castings</p>
                  </div>
                  <div className={styles.linkButtons}>
                    <Link />
                    <p>Links</p>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.interactionsContainer}>
                <Button className={styles.followButton} shape="round">
                  Follow
                </Button>
                <Button className={styles.messageButton} shape="round">
                  Message
                </Button>
                <div className={styles.dropdown}>
                  <img
                    onClick={showOverlay}
                    className={styles.gridButton}
                    src={Grid}
                    alt=""
                  />
                  <div
                    ref={interactionOverlay}
                    className={styles.overlayContainer}
                  >
                    <div onMouseLeave={showOverlay} class={styles.overlay}>
                      <div className={styles.overlayButton}>
                        <img src={Share} alt="" />
                        <p>Share</p>
                      </div>
                      <div className={styles.overlayButton}>
                        <img src={Recommend} alt="" />
                        <p>Reccommend</p>
                      </div>
                      <div className={styles.overlayButton}>
                        <img src={Unfollow} alt="" />
                        <p>Unfollow</p>
                      </div>
                      <div className={styles.overlayButton}>
                        <img src={Remove} alt="" />
                        <p>Remove</p>
                      </div>
                      <div className={styles.overlayButton}>
                        <img src={Report} alt="" />
                        <p>Report</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Button className={styles.inviteButton} shape="round">
                  Invite {userData?.given_name} to join a project
                </Button>
              </div>
            )}
          </Col>
        </Row>

        <Modal
          title={fullName}
          centered
          visible={getInTouchModal}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={2000}
        >
          <Button className={styles.representationButton}>
            Representation
          </Button>
          <h1>
            <img src={Card} alt="" /> Get In Touch Info
          </h1>
          <h3>
            <img src={Favicon} alt="" />
            {userData?.given_name}'s Profile
          </h3>
          <p>
            <a href="/profile">user.profileLink</a>
          </p>
          <h3>
            <img src={Phone} alt="" />
            Phone
          </h3>
          <p>
            <a href={`tel: user.phoneNumber`}>user.phoneNumber</a>
          </p>
          <h3>
            <img src={Location} alt="" />
            Address
          </h3>
          <p>
            <a href="/profile">user.address</a>
          </p>
          <h3>
            <img src={Email} alt="" />
            Email
          </h3>
          <p>
            <a href={`mailto: user.email`}>{userData?.email}</a>
          </p>
          <h3>
            <img src={Birthday} alt="" />
            Birthday
          </h3>
          <p>user.birthday</p>
        </Modal>

        <Modal
          title="Edit your profile basics"
          centered
          visible={editGeneralInfo}
          onOk={() => setVisible2(false)}
          onCancel={() => setVisible2(false)}
          width={1000}
        >
          <div className={styles.formContainer}>
            <div className={styles.inputItem}>
              <h4>First Name</h4>
              <input
                required
                value={userData?.given_name}
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>Last Name</h4>
              <input
                required
                value={userData?.family_name}
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>Current Position</h4>
              <input
                required
                value={userData?.jobs[0]?.title}
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>Education</h4>
              <input
                required
                value=""
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>Country/Region</h4>
              <input
                required
                value=""
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>City</h4>
              <input
                required
                value=""
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>Postal Code</h4>
              <input
                required
                value=""
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>

            <div className={styles.inputItem}>
              <h4>State</h4>
              <input
                required
                value=""
                onChange={(e) => this.onTodoChange(e.target.value)}
              />
            </div>
          </div>
        </Modal>

        <Modal
          title="Edit your entertainment industry vertical"
          editGeneralInfo
          visible={editVerticalInfo}
          onOk={() => setVisible3(false)}
          onCancel={() => setVisible3(false)}
          width={1000}
          className={styles.entertainmentVertical}
        >
          <Radio.Group
            className={styles.radioGroup}
            onChange={onChange}
            defaultValue="a"
          >
            {data.map((radioElement, index) => {
              return (
                <Radio.Button
                  key={index}
                  className={styles.button}
                  value={radioElement.value}
                >
                  <SquareElementsWithTitle text={radioElement.text}>
                    {/* {React.createElement(IconAntd[radioElement.iconType], {
                  className: styles.icon,
                })} */}
                    <img
                      src={radioElement.iconType}
                      alt=""
                      className={styles.icon}
                    />
                  </SquareElementsWithTitle>
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Modal>

        <CropModal
          show={isCropModalShow}
          image={uploadedImage}
          onAccept={onImageCrop}
          cropSettings={{ ratio: isProfileImageOnEdit ? 1 : 92 / 35 }}
          onCancel={() => {
            toggleCropModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default BasicInfo;
