import React, { useEffect , useState, useRef } from "react";
import Company from "./CompanyForm";
import Account from "./Account";
import Greeting from "./GreetingForm";
import Code from './CodeVerification';
import Location from "./Location";
import AboutCompany from "./AboutCompany";
import EmployeeVertical from "./AboutCompanyVert";
import RecruiterRole from "./AboutCompany2";
import { useQuery } from '@apollo/client';
import { message } from "antd";
import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { SIGN_UP , SUBMIT_EMAIL_VERIFICATION} from '../../../graphql/identity';
import { CREATE_USER_ADDRESS } from "../../../graphql/users";
import { setAuthItemsToLocalStorage } from '../../../utils';
//import { useAuth } from '../../../context/authContext';


const userLocation = {
  
    country: '',
    state: '',
    postalCode: '',

};

function getCloseLocation() {
  var location = new XMLHttpRequest();
  location.responseType = 'json';
  location.open('GET', 'https://freegeoip.app/json/');
  location.send();
  location.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
      userLocation.country = location.response.country_name;
      userLocation.state = location.response.region_name;
      userLocation.postalCode = location.response.zip_code;
    }
  };
}

getCloseLocation()


const Forms = () => {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({})
  const [errorMsg, setErrorMsg] = useState('')
  const [otpValue, setOtpValue] = useState(null);
  const formRef = useRef(null);
 // const { setUserInfo, setAuthenticated } = useAuth();
  const IdToken = localStorage.getItem('cognito-idToken');
  const accessToken = localStorage.getItem('cognito-accessToken');
  const refreshToken = localStorage.getItem('cognito-refreshToken');
  const [validateCodeMutation] = useMutation(SUBMIT_EMAIL_VERIFICATION);
  const [signUpMutation] = useMutation(SIGN_UP); 
  const [userAddressMutation] = useMutation(CREATE_USER_ADDRESS); 

  const setStepValues = (values) => {
    setFormValues({ ...formValues, ...values });
  };


  const handleSignUp= async(fields, nameFields)=>{
    const { email, password } = fields;
    const { given_name, family_name } = nameFields;
    try {
      const result = await signUpMutation({
        variables: {
          input: {
            credential: { email, password },
            user: { email, family_name, given_name },
          },
        },
      });
      //setAuthenticated(true);
      setAuthItemsToLocalStorage(result?.data?.signUp?.session);
      console.log('result:', result);
      setStep(step+1)
    } catch (error) {
      console.log(error.message.slice(14));
      setErrorMsg(error.message.slice(14));
      
    }
  }

 

   const handleCodeVerification = async (formValues) => {
    
    console.log(otpValue);
    if (
      otpValue &&
      otpValue[0] != '' &&
      otpValue[1] != '' &&
      otpValue[2] != '' &&
      otpValue[3] != '' &&
      otpValue[4] != '' &&
      otpValue[5]
    ) {
      try {
        const result = await validateCodeMutation({
          variables: {
            input: {
              principal: {
                accessToken: accessToken,
                idToken: IdToken,
                refreshToken: refreshToken,
              },
              code:
                otpValue[0] +
                otpValue[1] +
                otpValue[2] +
                otpValue[3] +
                otpValue[4] +
                otpValue[5],
            },
          },
        });
        console.log(result);
        message.success('Your email was successfully verified!');
        setStep(step+1)
      } catch (error) {
        message.error('The given code is invalid');
        console.log(error);
      }
    } else {
      message.error('fill in the values');
    }
   };

   const handleAddress = async (fields) => {
    const { country, state, postalCode } = fields;
    //setIsLoading(true);
    console.log('e');
    try {
      const result = await userAddressMutation({
        variables: {
          input: {
            city: state,
            country: country,
            postalCode: postalCode,
          },
        },
      });
      //setIsLoading(false);
      setStep(step + 1);
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
      setErrorMsg(error.message.slice(14));
     // setIsLoading(false);
    }
  };

  const goBack=()=>{
    setErrorMsg('')
    if(step>0)
    setStep(step-1)
  }
  const goNextStep = async(e) => {
    const stepForm = formRef.current
    
      //stepForm.submit()
      //e.preventDefault()
      setStepValues(stepForm.getFieldsValue())
      console.log('formvalues',stepForm.getFieldsValue())
    if(step===0){
      if(IdToken)
      setStep(step+6)
      else
      setStep(step+1)
    }
    if(step===1){
      setStep(step+1)
    }    
    if(step===2){
      
      handleSignUp(formValues,stepForm.getFieldsValue())
    }
    if(step===3)
    {
      await handleCodeVerification()
    }
    if(step===4){
      handleAddress(stepForm.getFieldsValue())
    }
    if(step===5){
      setStep(step+1)
    }  
    if(step===6){
      setStep(step+1)
    }
    if(step===7){
      setStep(step+1)
    }      
    
    
  };

  switch (step) {
    case 0: {
      return <Company goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg}/>;
      break;
    }
    case 1: {
      return <Account goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg}/>;
      break;
    }
    case 2: {
      console.log(formValues)
      return <Greeting goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg} goBack={goBack}/>;
      break;
    }
    case 3:{
      console.log(formValues)
      return<Code goNextStep={goNextStep} formRef={formRef} otpValue={(otpvalue) => setOtpValue(otpvalue)} 
      given_name={formValues.given_name} email={formValues.email} errorMsg={errorMsg}/>;
    }
    case 4: {
      console.log(formValues)
      return <Location goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg} given_name={formValues.given_name}
      userLocation={userLocation}/>;
      break;
    }
    case 5: {
        return <AboutCompany goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg}/>;
        break;
      }
      case 6: {
        return <EmployeeVertical goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg}/>;
        break;
      }
      case 7: {
        return <RecruiterRole goNextStep={goNextStep} formRef={formRef} errorMsg={errorMsg}/>;
        break;
      }

      default: {
      localStorage.setItem('formInfo',JSON.stringify(formValues))
      window.location.href='/postjob-details'
        break;
      }
  }
};

export default Forms;
