/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, message } from 'antd';
import SingleInput from '../../../../components/OTPInput/SingleInput';
import styles from './EmailVerification.module.scss';
import { validateEmailVerificationCode } from '../../Register.thunks';

const VerificationInput = ({ setCurrentStep, setStatus, isLoading }) => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const [activeInput, setActiveInput] = useState(0);
  const [digits, setVerificationDigits] = useState(['', '', '', '', '', '']);
  const [pasted, setPasted] = useState(null);
  const [changed, setchanged] = useState(null);

  const IdToken = localStorage.getItem('cognito-idToken');
  const accessToken = localStorage.getItem('cognito-accessToken');
  const refreshToken = localStorage.getItem('cognito-refreshToken');

  useEffect(() => {
    if (pasted || changed) {
      setVerificationDigits(digits);
    }
  }, [pasted, changed]);
  // Helper to return value with the right type: 'text' or 'number'
  const getOnlyNumber = useCallback((str) => {
    return !str || /\d/.test(str) ? str : '';
  }, []);

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(6 - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [6]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedDigits = [...digits];
      updatedDigits[activeInput] = str[0] || '';
      setVerificationDigits(updatedDigits);
    },
    [activeInput, digits]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = getOnlyNumber(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }

      changeCodeAtFocus(val);
      setchanged(digits);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getOnlyNumber]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
          e.preventDefault();
          if (digits[activeInput]) {
            changeCodeAtFocus('');
            focusPrevInput();
          } else {
            focusPrevInput();
          }
          break;
        case 'Delete':
          e.preventDefault();
          if (digits[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusNextInput();
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          focusPrevInput();
          break;
        case 'ArrowRight':
          e.preventDefault();
          focusNextInput();
          break;
        case ' ':
          e.preventDefault();
          break;
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, digits]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, 6 - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedDigits = [...digits];
        updatedDigits.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getOnlyNumber(pastedData.shift() || val);
            if (changedValue) {
              updatedDigits[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setPasted(updatedDigits);
        setVerificationDigits(updatedDigits);
        setActiveInput(Math.min(nextFocusIndex + 1, 6 - 1));
      }
    },
    [activeInput, getOnlyNumber, digits]
  );

  const onFinish = async (values) => {
    if (digits.some((digit) => digit === '')) {
      message.error('Fill in the values');
      return;
    }

    try {
      setStatus('pending');

      const response = await dispatch(
        validateEmailVerificationCode({
          digits: digits,
          tokens: { IdToken, refreshToken, accessToken },
        })
      ).unwrap();

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
      message.success(response?.message);
    } catch (error) {
      setStatus('error');
      message.error('The given code is invalid');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <h1
          className={styles.stepTitle}
        >{`Check your email ${registerData?.givenName}!`}</h1>
        <p className={styles.stepIntro}>
          {` We’ve sent a 6-digit confirmation code to `}
          <span>{`${registerData?.email}`}</span>
          {` – It will expire shortly so enter it soon`}
        </p>
      </div>
      <div className={styles.inputsWrapper}>
        <Form
          name="emailVerification"
          className={styles.formWrapper}
          onFinish={onFinish}
        >
          <Row gutter={16} justify="center">
            <Col span={2}>
              <SingleInput
                focus={activeInput === 0}
                value={digits[0]}
                onFocus={handleOnFocus(0)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
            <Col span={2}>
              <SingleInput
                focus={activeInput === 1}
                value={digits[1]}
                onFocus={handleOnFocus(1)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
            <Col span={2}>
              <SingleInput
                focus={activeInput === 2}
                value={digits[2]}
                onFocus={handleOnFocus(2)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
            <Col span={1}>
              <div className={styles.hyphen} />
            </Col>
            <Col span={2}>
              <SingleInput
                focus={activeInput === 3}
                value={digits[3]}
                onFocus={handleOnFocus(3)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
            <Col span={2}>
              <SingleInput
                focus={activeInput === 4}
                value={digits[4]}
                onFocus={handleOnFocus(4)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
            <Col span={2}>
              <SingleInput
                focus={activeInput === 5}
                value={digits[5]}
                onFocus={handleOnFocus(5)}
                onChange={handleOnChange}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
              />
            </Col>
          </Row>

          <Form.Item style={{ marginTop: '30px' }}>
            <Button
              className="continueBtn"
              disabled={isLoading}
              htmlType="submit"
              type="primary"
              shape="round"
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerificationInput;
