import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';

import clsx from 'clsx';
import classes from './CropImage.module.scss';

const CropImage = ({ src, onCrop, ratio, cropBoxResizable, rotatable }) => {
  const cropperRef = useRef();
  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    onCrop(cropper.getCroppedCanvas().toDataURL());
  };

  const rotateImage = (deg) => {
    cropperRef.current.cropper.rotate(deg);
  };
  return (
    <div>
      <Cropper
        data-testid="cropper"
        className={classes.cropper}
        style={{ maxHeight: 350 }}
        src={src}
        aspectRatio={ratio}
        guides={false}
        cropend={handleCrop}
        ref={cropperRef}
        rotatable={rotatable}
        cropBoxResizable={cropBoxResizable}
      />
      {rotatable && (
        <div className={classes.Actions}>
          <button
            className={clsx([
              'btn btn-icon',
              classes.ActionsBtn,
              classes.ActionsBtnLeft,
            ])}
            type="button"
            onClick={() => rotateImage(-90)}
          >
            <img src="/assets/img/rotate.svg" alt="rotate" />
          </button>
          <button
            className={clsx([
              'btn btn-icon',
              classes.ActionsBtn,
              classes.ActionsBtnRight,
            ])}
            type="button"
            onClick={() => rotateImage(90)}
          >
            <img src="/assets/img/rotate.svg" alt="rotate" />
          </button>
        </div>
      )}
    </div>
  );
};

CropImage.propTypes = {
  src: PropTypes.string,
  onCrop: PropTypes.func,
  ratio: PropTypes.number,
  cropBoxResizable: PropTypes.bool,
  rotatable: PropTypes.bool,
};

CropImage.defaultProps = {
  onCrop: () => {},
  ratio: 1 / 1,
  cropBoxResizable: true,
  src: null,
  rotatable: false,
};

export default CropImage;
