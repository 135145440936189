// @flow 
import React from 'react';
import style from './ProfileSection.module.scss';
import { useQuery } from "@apollo/client";
import { GET_LOGGED_USER } from "../../../graphql/users";

export const ProfileSection = ({ image, accountName, accountJob, accountSite, rank }) => {
	const { loading, error, data } = useQuery(
		GET_LOGGED_USER
	);
	
	const queryData = data;
	console.log(queryData)

	try {
		return (
			<div className={style.profileContainer}>
				<img className={style.imgContainer} src={image} />
				<p className={style.mediumText}>Welcome {data.getUser.given_name}</p>
				<p className={style.regularText}>{data.getUser.jobs[0].title} @ {data.getUser.jobs[0].company}</p>
				{accountSite ? <p className={style.regularText}></p> : ''}
				<p className={style.mediumText}>Rank <div className={style.numberCircle}>{rank}</div></p>
			</div>
		);
	} catch (error) {
		return (
			<div className={style.profileContainer}>
				<img className={style.imgContainer} src={image} />
				<p className={style.mediumText}>Welcome </p>
				<p className={style.regularText}></p>
				{accountSite ? <p className={style.regularText}></p> : ''}
				<p className={style.mediumText}>Rank <div className={style.numberCircle}>{rank}</div></p>
			</div>
		);
	}

};

export default ProfileSection;