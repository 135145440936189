export const reasons = [
  {
    label: "Connect with other Professionals / Influencers",
    value: "connect",
  },
  {
    label: "Crowdfund Projects / Campaigns",
    value: "crowdfund",
  },
  {
    label: "List / Submit /Finance Entertainment Projects",
    value: "submitProjects",
  },
  {
    label: "Join Projects & Increase Deal Flow",
    value: "joinProjects",
  },
  {
    label: "Search Jobs / Post Jobs",
    value: "searchjobs",
  },
  {
    label: "I’m an Investor / Apply For Investor Status*",
    value: "investor",
  },
];
