import React, { memo, useRef, useLayoutEffect } from 'react';
import usePrevious from './usePrevious';
import { Input } from 'antd';
import styles from './SingleInput.module.scss';

const SingleOTPInputComponent = (props) => {
  const { error, focus, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      }
      if (focus && focus !== prevFocus) {
        inputRef.current.focus();
      }
    }
  }, [focus, prevFocus]);

  return <Input ref={inputRef} {...rest} className={styles.input} />;
};

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
