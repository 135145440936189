import React from 'react';
import { Col, Button, Input } from 'antd';

// Styles
import Styles from '../CreatePanel.module.scss';

// default profile image
import defaultProfile from '../img/default-profile.png';

const { TextArea } = Input;

const CampaignContentPanel = (props) => {

  return (
    <>
      <Col span={24}>
        <label className={Styles.campaignThumbnailLabel} htmlFor="campaign-thumbnail">CAMPAIGN THUMBNAIL</label>
        <Input className={Styles.campaignThumbnailInput} id="campaign-thumbnail"/>
        <Button className={Styles.campaignThumbnailButton} type="primary" shape="round">UPLOAD IMAGE</Button>
      </Col>
      <Col span={24}>
        <img className={Styles.postProfileImage} src={defaultProfile} alt="user profile"/>
        <TextArea placeholder="Share ideas recommendations and links with your contacts..." maxLength={420} rows={14} />
      </Col>
      <Col offset={21} span={3}>
        <Button block type="primary" shape="round">SHARE</Button>
      </Col>
    </>
  );
}

export default CampaignContentPanel;
