import React from "react";
import { Card, Avatar,Row, Col } from 'antd';
import styles from '../ManageJobs.module.scss'

const {Meta}= Card;

const CardJobs=(data)=>{

    return(
           data.map(job=>{
            return(
               
                <Card className={styles.card}
                hoverable={true}
                >
                  <Meta
                    avatar={<Avatar src={`${job.logo}`} />}
                    title={<h4 className={styles.jobTitle}>{job.title}</h4>}
                    description={
                        <div>
                        <span className={styles.company}>{job.company}</span><br/>
                        <span>{job.location}</span><br/>
                        <span>{`Vertical: ${job.vertical}`}</span>
                        </div>

                    }
                    />
                   
                </Card>
               
            )
        })
    )
}

export default CardJobs;