import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, message, Button } from 'antd';
import Styles from './ProfilePicture.module.scss';
import { getSignedURL, updateUserProfileImage } from '../../Register.thunks';
import UploadImage from '../../../../components/UploadImage/UploadImage';
import { uploadFileS3 } from '../../../../utils/uploadFile';

const ProfilePicture = ({ setCurrentStep, setStatus, isLoading, skipStep }) => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register);
  const [uploadImage, setUploadImage] = useState('');
  const [form] = Form.useForm();

  const externalData = localStorage.getItem('linkedinData')
    ? JSON.parse(localStorage.getItem('linkedinData'))
    : '';
  const profImageUrl = externalData
    ? externalData.ProfilePictures.Url_800_800
    : '';

  useEffect(() => {
    form.setFieldsValue({
      photo: uploadImage,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImage]);

  async function createFile(imgurl) {
    const response = await fetch(imgurl);
    const dataBlob = await response.blob();
    const metadata = {
      type: 'image/jpeg',
      lastModified: Date.now(),
    };
    const imgfile = new File([dataBlob], 'test.jpeg', metadata);

    return imgfile;
  }

  const onFinish = async (values) => {
    const { photo } = values;
    const otherimage = await createFile(profImageUrl);

    try {
      setStatus('pending');

      const urlResponse = await dispatch(getSignedURL()).unwrap();
      const signedURLJson = JSON.parse(urlResponse?.signedURL);

      uploadFileS3(signedURLJson, photo ? photo : otherimage);
      dispatch(updateUserProfileImage({ key: signedURLJson.fields?.Key }));

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      console.log(error);
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  return (
    <div className={Styles.ProfilePictureContainer}>
      <div className={Styles.intro}>
        <h1 className={Styles.stepTitle}>
          {`Your professional side has a face ${registerData?.accountDetails?.givenName}.`}
        </h1>
        <p className={Styles.stepIntro}>
          Adding a photo makes people recognize you.
        </p>
      </div>

      <Form name="profilePhoto" onFinish={onFinish} form={form}>
        <Form.Item
          validateTrigger="onBlur"
          name="photo"
          initialValue={uploadImage}
        >
          <Input type="hidden" />
        </Form.Item>
        <UploadImage externalHandler={setUploadImage} />
        <h4 className={Styles.userInformation}>
          {registerData?.accountDetails?.givenName} <br />
          {registerData?.background?.recentJob} at{' '}
          {registerData?.background?.recentCompany}
        </h4>

        <Button
          className="skipBtn"
          disabled={isLoading}
          type="primary"
          shape="round"
          onClick={() => skipStep()}
        >
          Skip
        </Button>
        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfilePicture;
