import React from 'react';
import style from './LoggedInHeader.module.scss';
import { Row, Col } from 'antd';
import logo from '../../img/LRLogoLight.png';
import Home from './img/Home.png';
import Network from './img/Network.png';
import Jobs from './img/Jobs.png';
import News from './img/News.png';
import Groups from './img/Groups.png';
import Companies from './img/Companies.png';
import hamburger from './img/menu-mobile.png';
import Notifications from '../../containers/Notifications/Notifications';
import ChevronDown from './img/ChevronDown.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Default from '../../img/no-img.png';

import { JobsModal, ProfileModal } from './Modals';

const LoggedInHeader = () => {
  const { userData } = useSelector((state) => state.session);

  let showhideJobs = false;
  let showhideProfile = false;

  const profilePic = !userData?.profileImage
    ? Default
    : userData?.profileImage?.split(', ');

  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');

  const showModals = (element) => {
    if (element === 'Jobs') {
      if (showhideJobs) {
        document.getElementById('jobsID').style.display = 'none';
        showhideJobs = false;
      } else {
        document.getElementById('jobsID').style.display = 'inline-block';
        showhideJobs = true;
      }
    } else if (element === 'Profile') {
      if (showhideProfile) {
        document.getElementById('profileID').style.display = 'none';
        showhideProfile = false;
      } else {
        document.getElementById('profileID').style.display = 'inline-block';
        showhideProfile = true;
      }
    }
  };

  return (
    <div className={style.loggedInContainer}>
      <div className={style.mainMenuWrapper}>
        <Row align="middle" justify="center">
          <Col
            className={style.logoContainer}
            xs={24}
            lg={{ span: 2, pull: 2 }}
            xl={{ span: 2, pull: 2 }}
          >
            <Link to="/">
              <img
                src={logo}
                className={`${style.logo}`}
                alt="linkrease logo"
              />
            </Link>
          </Col>
          <img className={style.hamburgerIcon} src={hamburger} alt="" />
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <Link to="/dashboard">
                <img className={style.headerIcon} src={Home} alt="" />
                <br />
                Home
              </Link>
            </div>
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <Link to="/network">
                <img className={style.headerIcon} src={Network} alt="" />
                <br />
                My Network
              </Link>
            </div>
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
            onClick={() => showModals('Jobs')}
          >
            <div className={style.iconHeading}>
              <img className={style.headerIcon} src={Jobs} alt="" />
              <br />
              Jobs
            </div>
            <JobsModal />
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <img className={style.headerIcon} src={News} alt="" />
              <br />
              News
            </div>
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <img className={style.headerIcon} src={Groups} alt="" />
              <br />
              Groups
            </div>
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <img className={style.headerIcon} src={Companies} alt="" />
              <br />
              Companies
            </div>
          </Col>
          <Col
            className={style.navLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <div className={style.iconHeading}>
              <Notifications />
            </div>
          </Col>
          {/* <Col className={style.navLink} xs={24} lg={{ span: 2}} xl={{ span: 2 }}>
						<Link to='/'>
							<img className={style.headerIcon} src={Network} />
							<br/>
							Welcome
						</Link>
					</Col> */}

          <Col
            className={style.profileLink}
            xs={24}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
            onClick={() => showModals('Profile')}
          >
            <div className={style.imgContainer}>
              <img src={smallImage} alt="header profile" />
              <img className={style.chevron} src={ChevronDown} alt="" />
            </div>
            <ProfileModal />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoggedInHeader;
