export default {
  REGION: process.env.REACT_APP_REGION,
  AUTHENTICATION_TYPE: process.env.REACT_APP_AUTHENTICATION_TYPE,
  GRAPHQL_API_URL: process.env.REACT_APP_GRAPHQL_API_URL,
  GRAPHQL_API_ID: process.env.REACT_APP_GRAPHQL_API_ID,
  GRAPHQL_API_KEY: process.env.REACT_APP_GRAPHQL_API_KEY,
  IMAGE_OPTIMIZATION_SETTINGS: {
    maxSizeMB: 5,
    maxWidthOrHeight: 1900,
    useWebWorker: true,
  },
};
