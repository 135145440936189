import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Divider } from 'antd';
import Styles from './PeopleIFollow.module.scss';
import { Icon, CheckOutlined } from '@ant-design/icons';
import infinite from './img/infinite.PNG';
const PeopleIFollow = (props) => {
  const [showInteraction, setVisible] = useState(true);
  const [buttonText, setButtonText] = useState('+ Follow');
  const [color, setColor] = useState('#0438fa');
  const [textColor, setTextColor] = useState('white');
  const [borderwidth, setborderwidth] = useState('0rem');
  const follow = (e) => {
    if (buttonText == '+ Follow') {
      setButtonText('Following');
      setTextColor('#0438fa');
      setColor('white');
      setborderwidth('0.1rem');
    } else {
      setButtonText('+ Follow');
      setTextColor('white');
      setColor('#0438fa');
    }
  };
  return (
    <Col>
      <Card className={Styles.card}>
        <Row>
          <Col span={3}>
            <img
              src={props.profilePic}
              className={Styles.profileBubble}
              alt="profile"
            />
          </Col>
          <Col span={21}>
            <Row>
              <Col span={14}>
                <h5 className={`${Styles.postTitle}`}>
                  {props.given_name} {props.family_name}
                </h5>
              </Col>
              <Col span={10}>
                <h5 className={`${Styles.postTitle}`}>
                  <Divider type="vertical" className={Styles.dividerVertical} />
                  Rank
                  <span className={Styles.rankBubble}>{props.rank}</span>
                </h5>
              </Col>
              <Col span={15}>
                <h6 className={Styles.jobPosition}>
                  {props.jobTitle} at {props.jobCompany}
                </h6>
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row>
              {/*<Col span={4}>
                  <img
                    src={infinite}
                    className={Styles.backingBubble}
                    alt="profile"
                  />
                </Col>
                <Col span={20}>
                  <h6 className={Styles.peopleReacting}>
                    2 shared connections Diego Garcia and Raul Argueta
                  </h6>
  </Col>*/}
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <span className={Styles.posts}>
                  {props.posts} posts this week
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <hr className={Styles.postDivider} />
            <span className={Styles.following}>
              <CheckOutlined className={Styles.check} /> Following
            </span>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default PeopleIFollow;
