import React from 'react';
import styles from './PostJob.module.scss';
import Forms from './components/Forms'
import logo from '../../img/LRLogoLight.png';
import {Row, Col, Button} from 'antd'

const { useState } = React

const PostJob = () =>{

    return(
        <Row
         className={styles.background}
         justify ='center'
         >
            <Col
            span={24}>
            <Row>
            <Col
            span={2}>
            <img src={logo} className={styles.logo} alt="linkrease logo" />
            </Col>
            <Col
            offset={18}>
            <Button className={styles.manageJobs}>Manage Jobs</Button>
            </Col>
            </Row>
            <Row>
                <Col
                span={16}
                offset={4}>
                    <Row
                    justify={'center'}>
                    <h2 style={{color:'lightgray', marginBottom:'5%', marginTop:'5%'}}>Hire from the best pool of entertaiment industry talent</h2>
                    </Row>
                </Col>
            </Row>
           <Row>
                <Forms/>
            </Row>
        </Col>  
        </Row>
    )
}

export default PostJob