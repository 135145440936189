import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import styles from './education.module.scss';
import Edit from './img/edit.svg';
import Plus from './img/plus.png';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SEARCH_UNIVERSITY } from '../../../graphql/universities';
import { CREATE_USER_EDUCATION } from '../../../graphql/users';
import { UPDATE_USER_EDUCATION } from '../../../graphql/users';
import { DELETE_USER_EDUCATION } from '../../../graphql/users';
import moment from 'moment';
import {
  Row,
  Col,
  AutoComplete,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Checkbox,
} from 'antd';

const Education = ({ educations = [{}], refetch }) => {
  const [userEducationMutation] = useMutation(CREATE_USER_EDUCATION);
  const [updateUserEducationMutation] = useMutation(UPDATE_USER_EDUCATION);
  const [deleteUserEducationMutation] = useMutation(DELETE_USER_EDUCATION);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [editEducation, setVisible4] = useState(false);
  const [newEducation, setVisible5] = useState(false);
  const [options, setOptions] = useState([]);
  const [result, setResult] = useState('');
  const [getUniversities, { loading, error, data }] =
    useLazyQuery(SEARCH_UNIVERSITY);
  const [recentSchool, setrecentSchool] = useState('');
  const [degree, setdegree] = useState('');
  const [educationDate_picker, seteducationDate_picker] = useState([]);
  const [fieldOfStudy, setfieldOfStudy] = useState('');
  const [userEducationId, setuserEducationId] = useState('');
  const dateFormat = 'YYYY/MM/DD';

  const createEducation = async () => {
    try {
      const result = await userEducationMutation({
        variables: {
          input: {
            startDate: educationDate_picker[0].format(
              'YYYY-MM-DDThh:mm:ss.sssZ'
            ),
            endDate: educationDate_picker[1].format('YYYY-MM-DDThh:mm:ss.sssZ'),
            school: recentSchool,
            fieldOfStudy: fieldOfStudy,
            degree: degree,
          },
        },
      });
      setVisible5(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateEducation = async () => {
    try {
      console.log(userEducationId);
      console.log(moment(educationDate_picker[1], 'YYYY-MM-DDThh:mm:ss.sssZ'));
      console.log(moment(educationDate_picker[0], 'YYYY-MM-DDThh:mm:ss.sssZ'));
      console.log(recentSchool);
      console.log(fieldOfStudy);
      const result = await updateUserEducationMutation({
        variables: {
          input: {
            userEducationId: userEducationId,
            startDate: moment(
              educationDate_picker[0],
              'YYYY-MM-DDThh:mm:ss.sssZ'
            ),
            endDate: moment(
              educationDate_picker[1],
              'YYYY-MM-DDThh:mm:ss.sssZ'
            ),
            school: recentSchool,
            fieldOfStudy: fieldOfStudy,
            degree: degree,
          },
        },
      });
      setVisible4(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteEducation = async () => {
    try {
      console.log(userEducationId);
      console.log(moment(educationDate_picker[1], 'YYYY-MM-DDThh:mm:ss.sssZ'));
      console.log(moment(educationDate_picker[0], 'YYYY-MM-DDThh:mm:ss.sssZ'));
      console.log(recentSchool);
      console.log(fieldOfStudy);
      const result = await deleteUserEducationMutation({
        variables: {
          input: {
            userEducationId: userEducationId,
          },
        },
      });
      setVisible4(false);
      refetch();
      console.log('result:', result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSearch = (changeText) => {
    try {
      let res = [];
      if (
        typeof (
          data &&
          data.searchUniversity &&
          data.searchUniversity.universities
        ) !== 'undefined'
      ) {
        setResult(
          (
            data &&
            data.searchUniversity &&
            data.searchUniversity.universities
          ).substring(
            1,
            (
              data &&
              data.searchUniversity &&
              data.searchUniversity.universities
            ).length - 1
          )
        );
        let nose = result.replace(/"/g, '');
        res = nose.split(',');
      }
      setOptions(!changeText ? [] : res);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelect = (data) => {
    console.log('onSelect', data);
  };

  const onChange = (data) => {
    setrecentSchool(data);
    const searchUniversityInput = {
      name: data,
    };
    getUniversities({ variables: { input: searchUniversityInput } });
  };

  const onClickEdit = (educationEdit) => {
    educations.map((education) => {
      if (education.userEducationId === educationEdit) {
        setrecentSchool(education.school);
        setfieldOfStudy(education.fieldOfStudy);
        seteducationDate_picker([education.startDate, education.endDate]);
        setuserEducationId(educationEdit);
        setdegree(education.degree);
      }
    });
    setVisible4(true);
  };

  if (loading) {
    console.log('loading');
  }

  if (error) {
    console.log('error: ', error.message);
  }
  console.log(educations);

  return (
    <div className={styles.Basic}>
      <img
        src={Plus}
        className={styles.plus}
        onClick={() => setVisible5(true)}
        alt=""
      />
      <div className={styles.title}>
        <p>Education</p>
      </div>
      <div>
        {educations.map((education) => (
          <Row key={education.userEducationId}>
            <Col xs={8} sm={12} md={12} lg={12} className={styles.description}>
              <h3>{education.school}</h3>
              <h4>
                {education.fieldOfStudy} - {education.degree}
              </h4>
              <h5>
                {moment(education.startDate).format('YYYY')} -{' '}
                {moment(education.endDate).format('YYYY')}
              </h5>
            </Col>
            <div className={styles.editContainer}>
              <img
                src={Edit}
                className={styles.edit}
                onClick={() => onClickEdit(education.userEducationId)}
                alt=""
              />
            </div>
          </Row>
        ))}
      </div>
      <Modal
        title="New education"
        centered
        visible={newEducation}
        onOk={() => setVisible5(false)}
        onCancel={() => setVisible5(false)}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={() => createEducation()}>
            Save
          </Button>,
        ]}
      >
        <div className={styles.newModal}></div>
        <h4 style={{ marginBottom: '1rem' }}>School</h4>
        <AutoComplete
          value={recentSchool}
          style={{ marginBottom: '1rem', width: '32rem', height: '20%' }}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="University"
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </AutoComplete>
        <h4 style={{ marginBottom: '1rem' }}>Degree</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem', padding: '0' }}
          onChange={(e) => setdegree(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Field of study</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setfieldOfStudy(e.target.value)}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>
          Start date and End date (or expected)
        </h4>
        <Space direction="vertical" size={12}>
          <RangePicker
            picker="month"
            size="large"
            onChange={(e) => seteducationDate_picker(e)}
          />
        </Space>
      </Modal>
      <Modal
        title="Edit education"
        centered
        className="educationModal"
        visible={editEducation}
        onOk={() => setVisible4(false)}
        onCancel={() => setVisible4(false)}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={() => updateEducation()}>
            Update
          </Button>,
          <Button key="back" type="primary" onClick={() => deleteEducation()}>
            Delete
          </Button>,
        ]}
      >
        <h4 style={{ marginBottom: '1rem' }}>School</h4>
        <AutoComplete
          value={recentSchool}
          style={{ marginBottom: '1rem', width: '32rem', height: '20%' }}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="University"
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </AutoComplete>
        <h4 style={{ marginBottom: '1rem' }}>Degree</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem', padding: '0' }}
          onChange={(e) => setdegree(e.target.value)}
          value={degree}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>Field of study</h4>
        <Input
          style={{ marginBottom: '1rem', width: '32rem' }}
          onChange={(e) => setfieldOfStudy(e.target.value)}
          value={fieldOfStudy}
        ></Input>
        <h4 style={{ marginBottom: '1rem' }}>
          Start date and End date (or expected)
        </h4>
        <Space direction="vertical" size={12}>
          <RangePicker
            picker="month"
            size="large"
            defaultValue={[
              moment(educationDate_picker[0], dateFormat),
              moment(educationDate_picker[1], dateFormat),
            ]}
            format={dateFormat}
            onChange={(e) => seteducationDate_picker(e)}
          />
        </Space>
      </Modal>
    </div>
  );
};

export default Education;
