import React from 'react';
import styles from './Disclamer.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import CorpNav from '../CorpNav/CorpNav';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';
const Disclamer = () => {
  return (
    <div className={styles.div}>
      <CorpNav />
      <div className={styles.banner__headline_container}>
        <div className={styles.banner__position}>
          <h1 className={styles.banner__headline_t_64}>Disclaimer</h1>
        </div>
      </div>
      <div className={styles.content}>
        <p>
          This disclaimer (&quot;Disclaimer&quot;) sets forth the general
          guidelines, disclosures, and terms of your use of the
          <a target="_blank" rel="nofollow" href="https://www.linkrease.com">
            {' '}
            linkrease.com
          </a>{' '}
          website (&quot;Website&quot;), &quot;Linkrease&quot; mobile
          application (&quot;Mobile Application&quot;) and any of their related
          products and services (collectively, &quot;Services&quot;). This
          Disclaimer is a legally binding agreement between you
          (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and Linkrease
          Technologies, LLC (&quot;Linkrease Technologies, LLC&quot;,
          &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). By accessing and
          using the Services, you acknowledge that you have read, understood,
          and agree to be bound by the terms of this Disclaimer. If you are
          entering into this Disclaimer on behalf of a business or other legal
          entity, you represent that you have the authority to bind such entity
          to this Disclaimer, in which case the terms &quot;User&quot;,
          &quot;you&quot; or &quot;your&quot; shall refer to such entity. If you
          do not have such authority, or if you do not agree with the terms of
          this Disclaimer, you must not accept this Disclaimer and may not
          access and use the Services. You acknowledge that this Disclaimer is a
          contract between you and Linkrease Technologies, LLC, even though it
          is electronic and is not physically signed by you, and it governs your
          use of the Services.
        </p>
        <h2 className={styles.h2}>Representation</h2>
        <p>
          Any views or opinions represented on the Services belong solely to the
          content creators and do not represent those of people, institutions or
          organizations that Linkrease Technologies, LLC or creators may or may
          not be associated with in professional or personal capacity, unless
          explicitly stated. Any views or opinions are not intended to malign
          any religion, ethnic group, club, organization, company, or
          individual.
        </p>
        <h2 className={styles.h2}>Content and postings</h2>
        <p>
          You may not modify, print or copy any part of the Services. Inclusion
          of any part of the Services in another work, whether in printed or
          electronic or another form or inclusion of any part of the Services on
          another resource by embedding, framing or otherwise without the
          express permission of Linkrease Technologies, LLC is prohibited.{' '}
        </p>
        <p>
          You may submit new content and comment on the existing content on the
          Services. By uploading or otherwise making available any information
          to Linkrease Technologies, LLC, you grant Linkrease Technologies, LLC
          the unlimited, perpetual right to distribute, display, publish,
          reproduce, reuse and copy the information contained therein. You may
          not impersonate any other person through the Services. You may not
          post content that is defamatory, fraudulent, obscene, threatening,
          invasive of another person's privacy rights or that is otherwise
          unlawful. You may not post content that infringes on the intellectual
          property rights of any other person or entity. You may not post any
          content that includes any computer virus or other code designed to
          disrupt, damage, or limit the functioning of any computer software or
          hardware. By submitting or posting content on the Services, you grant
          Linkrease Technologies, LLC the right to edit and, if necessary,
          remove any content at any time and for any reason.
        </p>
        <h2 className={styles.h2}>Compensation and sponsorship</h2>
        <p>
          The Services may contain forms of advertising, sponsorship, paid
          insertions or other forms of compensation. On certain occasions
          Linkrease Technologies, LLC may be compensated to provide opinions on
          products, services, or various other topics. Even though Linkrease
          Technologies, LLC receives compensation for advertisements, the
          opinions, findings, beliefs, or experiences on those topics or
          products are honest and not influenced by the advertiser or sponsor.
          The views and opinions expressed on the Services are purely of
          Linkrease Technologies, LLC. Any product claims, statistics, quotes or
          other representations about products or services should be verified
          with the manufacturer, provider or the party in question. Sponsored
          content and advertising space will always be identified as such. Some
          of the links on the Services may be &quot;affiliate links&quot;. This
          means if you click on the link and purchase an item, Linkrease
          Technologies, LLC will receive an affiliate commission.
        </p>
        <h2 className={styles.h2}>Reviews and testimonials</h2>
        <p>
          Testimonials are received in various forms through a variety of
          submission methods. The testimonials are not necessarily
          representative of all of those who will use Services, and Linkrease
          Technologies, LLC is not responsible for the opinions or comments
          available on the Services, and does not necessarily share them. People
          providing testimonials on the Services may have been compensated with
          free products or discounts for use of their experiences. All opinions
          expressed are strictly the views of the reviewers.
        </p>
        <p>
          The testimonials displayed are given verbatim except for grammatical
          or typing error corrections. Some testimonials may have been edited
          for clarity, or shortened in cases where the original testimonial
          included extraneous information of no relevance to the general public.
          Testimonials may be reviewed for authenticity before they are
          available for public viewing.
        </p>
        <h2 className={styles.h2}>Indemnification and warranties</h2>
        <p>
          While we have made every attempt to ensure that the information
          contained on the Services is correct, Linkrease Technologies, LLC is
          not responsible for any errors or omissions, or for the results
          obtained from the use of this information. All information on the
          Services is provided &quot;as is&quot;, with no guarantee of
          completeness, accuracy, timeliness or of the results obtained from the
          use of this information, and without warranty of any kind, express or
          implied. In no event will Linkrease Technologies, LLC, or its
          partners, employees or agents, be liable to you or anyone else for any
          decision made or action taken in reliance on the information on the
          Services, or for any consequential, special or similar damages, even
          if advised of the possibility of such damages. Information on the
          Services is for general information purposes only and is not intended
          to provide any type of professional advice. Please seek professional
          assistance should you require it. Information contained on the
          Services are subject to change at any time and without warning.
        </p>
        <h2 className={styles.h2}>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Disclaimer or its terms relating
          to the Services at any time, effective upon posting of an updated
          version of this Disclaimer on the Services. When we do, we will send
          you an email to notify you. Continued use of the Services after any
          such changes shall constitute your consent to such changes.
        </p>
        <h2 className={styles.h2}>Acceptance of this disclaimer</h2>
        <p>
          You acknowledge that you have read this Disclaimer and agree to all
          its terms and conditions. By accessing and using the Services you
          agree to be bound by this Disclaimer. If you do not agree to abide by
          the terms of this Disclaimer, you are not authorized to access or use
          the Services.
        </p>
        <h2 className={styles.h2}>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this
          Disclaimer or wish to contact us concerning any matter relating to it,
          you may send an email to
          &#115;u&#112;por&#116;&#64;&#108;i&#110;kreas&#101;.c&#111;&#109;.
        </p>
        <p>This document was last updated on June 2, 2021</p>
      </div>
      <div className={styles.landingFooterContainer}>
        <LandingFooter />
        <PrivacyTermsFooter />
      </div>
    </div>
  );
};

export default Disclamer;
