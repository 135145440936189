import React from 'react';
import style from './HeadingComponents.module.scss';
import PurchasesIconSVG from '../img/purchases.svg';

const PurchasesHeading = () => {
	return (
		<div>
			<div className={style.contentHeader}>
				<img src={PurchasesIconSVG}/>
				<p className={style.titleText}>Purchases</p>
			</div>
			<p className={style.uppercaseText}></p>
		</div>
	);
};

export default PurchasesHeading;