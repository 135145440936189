import React from 'react';
import style from './HeadingComponents.module.scss';
import NotificationsIconSVG from '../img/notifications.svg';

const NotificationsHeading = () => {
	return (
		<div>
			<div className={style.contentHeader}>
				<img src={NotificationsIconSVG}/>
				<p className={style.titleText}>Notifications</p>
			</div>
			<p className={style.uppercaseText}>Network updates​</p>
		</div>
	);
};

export default NotificationsHeading;