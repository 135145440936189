import React, { useState, Fragment } from 'react';
import { Input, Button, Alert } from 'antd';
import Styles from './UploadImage.module.scss';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as CameraIcon } from './img/camera-icon.svg';
import { ReactComponent as PlusIcon } from './img/plus-icon.svg';


const UploadImage = (props) => {
  const externalData = localStorage.getItem('linkedinData')?JSON.parse(localStorage.getItem('linkedinData')):''
  const profImageUrl= externalData.ProfilePictures?externalData.ProfilePictures.Url_800_800:''
  const [uploadedImage, setUploadedImage] = useState(profImageUrl?profImageUrl:'');
  const [imageError, setimageError] = useState(false);
  
   
  const onChangeHandler = async (event) => {
    const image = event.target.files[0];
    const fileReader = new FileReader();
    const testImage = new Image();
    
    await fileReader.addEventListener('load', () => {
    const uploadImage = fileReader.result;

      setUploadedImage(uploadImage);
      testImage.src = image;

      if (props.externalHandler) {
        props.externalHandler(image);
      }
    });
    
    fileReader.readAsDataURL(image);
    setimageError('');

  
  };
  
  const removeImageHandler = () => {
    setUploadedImage('');

    if (props.externalHandler) {
      props.externalHandler('');
    }
  };

  const renderInput = () => {
    return (
      <Fragment>
        <Icon className={Styles.cameraIcon} component={CameraIcon} />
        <p className={Styles.photoRecommendations}>
          Max. 5 MB
          <strong>JPG, JPEG OR PNG</strong>
          Recommended size:
          <br />
          1000px x 1000px
        </p>
        <Input
          className={Styles.uploadInput}
          type="file"
          name="profile-photo"
          id="profile-photo"
          accept=".jpg, .jpeg, .png"
          onChange={onChangeHandler}
        />
      </Fragment>
    );
  };

  const renderPreview = () => {
    return (
      <Fragment>
        <Button className={Styles.closeButton} onClick={removeImageHandler}>
          <CloseCircleOutlined />
        </Button>
        <img
          src={uploadedImage}
          className={Styles.uploadedImagePreview}
          alt="preview"
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className={Styles.UploadImageComponent}>
        <Icon className={Styles.plusIcon} component={PlusIcon} />
        {!uploadedImage && renderInput()}
        {uploadedImage && renderPreview()}
      </div>
      {imageError.length && (
        <Alert
          key="alert"
          className={Styles.alertMessage}
          type="error"
          message={imageError}
        />
      )}
      
    </Fragment>
  );
};

export default UploadImage;
