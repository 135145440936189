import React from 'react';
import styles from './RefundPolicy.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import CorpNav from '../CorpNav/CorpNav';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';
const RefundPolicy = () => {
  return (
    <div className={styles.div}>
      <CorpNav />
      <div className={styles.banner__headline_container}>
        <div className={styles.banner__position}>
          <h1 className={styles.banner__headline_t_64}>Refund Policy</h1>
        </div>
      </div>

      <div className={styles.content}>
        <p>
          Since the Service offers non-tangible, irrevocable goods we do not
          provide refunds after the product is purchased, which you acknowledge
          prior to purchasing any product on the Services.
        </p>
        <p>
          You may however sign up for a free 30 day trial and try the service
          before making a purchase.
        </p>
        <h2 className={styles.h2}>Contacting us</h2>
        <p>
          If you would like to contact us concerning any matter relating to this
          Refund Policy, you may send an email to
          &#115;&#117;p&#112;o&#114;t&#64;&#108;in&#107;r&#101;&#97;s&#101;.co&#109;
        </p>
        <p>This document was last updated on June 2, 2021</p>
      </div>
      <div className={styles.landingFooterContainer}>
        <LandingFooter />
        <PrivacyTermsFooter />
      </div>
    </div>
  );
};

export default RefundPolicy;
