import React from 'react';
import style from './Ads.module.scss';

const Ads = () => {
    return (
        <div className={style.adsContainer}>
            
        </div>
    );
};

export default Ads;