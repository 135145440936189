import React,{useState, useEffect} from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Col, Row, Button } from 'antd'



const  Location =({goNextStep, formRef, given_name, userLocation})=>{
const {country, state, postalCode } = userLocation

console.log(userLocation)
    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
     
    span={20}
    >    
    <Row justify='center'>
        
        <h2 className={styles.title}>Welcome, {`${given_name}`}</h2>
          
        </Row>

    <Row justify='center'>
        <Col
        span={20}
        >

    <Form
      name="region"
      initialValues={{
        remember: true,
        country:country,
        state:state,
        postalCode:postalCode,
      }}
      layout='vertical'
      ref={formRef}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    >
      <Form.Item
      validateTrigger="onBlur"
        label="Country / Region"
        name="country"
        rules={[{ required: true, message: 'Please input your Country!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="City"
        name="state"
        rules={[{ required: true, message: 'Please input City!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Postal code"
        name="postalCode"
        rules={[{ required: true, message: 'Please input your Postal Code' },
        {
          message: 'Only numbers can be entered',
          pattern: /^[0-9]+$/,
        },
        {
          max: 5,
          message: 'Cannot be greater than 5 characters',
        },]}
      >
        <Input/>
      </Form.Item>

     

      <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        onClick={(e)=>goNextStep(e)}>
         CONTINUE
        </Button>
      </Form.Item>
    </Form>
        </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default Location