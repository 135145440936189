import { gql } from '@apollo/client';

export const CREATE_USER_POST = gql`
  mutation CREATE_USER_POST($input: CreatePostInput!) {
    createPost(input: $input) {
      content
      media
      visibility
      listofusers
    }
  }
`;

export const GET_POST = gql`
  query GET_POST($input: GetPostInput!) {
    getPost(input: $input) {
      Post
    }
  }
`;

export const LIST_POSTS = gql`
  query LIST_POSTS($input: ListPostsInput!) {
    listPosts(input: $input) {
      items {
        user_id
        post_id
        content
        visibility
        listofusers
        media
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
