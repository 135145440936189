import React from 'react';
import { Skeleton, Divider } from 'antd';

export default function CustomSkeleton() {
  return (
    <div className="container" style={{ paddingTop: '50px' }}>
      <Skeleton active paragraph={{ rows: 1 }} />
      <Divider />

      <Skeleton active paragraph={{ rows: 4 }} />
      <Divider />

      <div style={{ marginTop: '100px' }}>
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>

      <Divider />

      <Skeleton active paragraph={{ rows: 4 }} />
      <Divider />
    </div>
  );
}
