import React from 'react';
import PropTypes from 'prop-types';
import styles from './FileUpload.module.scss';

// IN PROGRESS

export default function FileUpload({
  labelName,
  className,
  id,
  onFileChange,
  accept,
}) {
  const onChange = (e) => {
    if (e.target.files.length > 0) {
      onFileChange(e.target.files[0]);
    }
  };

  return (
    <div className={styles.FileUpload}>
      <input
        data-testid={`uploadField-${id}`}
        type="file"
        className={styles.inputfile}
        id={id}
        onChange={onChange}
        accept={accept}
      />
      <label className={className} htmlFor={id}>
        {labelName}
      </label>
    </div>
  );
}

FileUpload.propTypes = {
  className: PropTypes.string,
  labelName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
};

FileUpload.defaultProps = {
  className: '',
  labelName: 'Upload Something',
  accept: '',
};
