import { gql } from '@apollo/client';

const SESSION_SELECT = `
    session {
        idToken
        accessToken
        refreshToken
        expiresIn
    }
`;

const RESET_PASS_SELECT = `
    message
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($input: SignInInput!) {
    signIn(input: $input) {
      ${SESSION_SELECT}
    }
  }
`;

export const SIGN_UP = gql`
  mutation SIGN_UP($input: SignUpInput!) {
    signUp(input: $input) {
      ${SESSION_SELECT}
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      ${RESET_PASS_SELECT}
    }
  }
`;

export const CONFIRM_RESET_PASSWORD = gql`
  mutation CONFIRM_RESET_PASSWORD($input: ConfirmResetPasswordInput!) {
    confirmResetPassword(input: $input) {
      ${RESET_PASS_SELECT}
    }
  }
`;

export const SUBMIT_EMAIL_VERIFICATION = gql`
  mutation SUBMIT_EMAIL_VERIFICATION(
    $input: SubmitEmailVerificationCodeInput!
  ) {
    submitEmailVerificationCode(input: $input) {
      message
    }
  }
`;
