import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './RegisterPath.scss';
import { Radio, Row, Col, Button } from 'antd';
import aspirationalSVG from './img/aspirational.svg';
import professionalSVG from './img/professional.svg';
import { setCareerPath } from '../../Register.slice';
import RegisterHeader from '../../components/RegisterOuterWrapper/RegisterHeader/RegisterHeader';
import SquareElementsWithTitle from '../../components/SquareElementsWithTitle/SquareElementsWithTitle';
import CompactFooter from '../../../../components/PrivacyTermsFooter/PrivacyTermsFooter';

export default function RegisterPath() {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register);
  const { vertical, careerPath } = registerData;

  const onChange = (e) => {
    dispatch(setCareerPath(e.target.value));
  };

  if (!vertical) {
    window.location = '/register-vertical';
  } else {
    return (
      <>
        <RegisterHeader />
        <div className="RegisterPath">
          <div className="container">
            <div className="intro">
              <h1 className="stepTitle">
                Your entertainment career starts here
              </h1>
              <p className="stepIntro">Tell us which defines you best</p>
            </div>
            <Radio.Group
              className="radioGroup"
              onChange={onChange}
              defaultValue="a"
            >
              <Row align="middle" justify="center">
                <Col xs={24} sm={10}>
                  <Radio.Button className="button" value="aspirational">
                    <SquareElementsWithTitle text="I’m aspirational talent. I want to break into the entertainment industry.">
                      <img
                        src={aspirationalSVG}
                        alt="aspirationalSVG"
                        className="icon"
                      />
                    </SquareElementsWithTitle>
                  </Radio.Button>
                </Col>

                <Col xs={24} sm={10}>
                  <Radio.Button className="button" value="professional">
                    <SquareElementsWithTitle text="I already work in the industry. I’m an active entertainment industry professional. ">
                      <img
                        src={professionalSVG}
                        alt="professionalSVG"
                        className="icon"
                      />
                    </SquareElementsWithTitle>
                  </Radio.Button>
                </Col>
              </Row>
            </Radio.Group>

            <Link to="/register-flow">
              <Button
                disabled={!careerPath}
                className="continueBtn"
                htmlType="submit"
                type="primary"
                shape="round"
              >
                Continue
              </Button>
            </Link>
          </div>
        </div>
        <CompactFooter />
      </>
    );
  }
}
