import React from 'react'
import styles from '../PostJob.module.scss'
import { useCallback, useState, useEffect } from 'react';
import { Input, Row, Col, Form, Button } from 'antd';
import SingleInput from '../../../components/OTPInput/SingleInput';

const CodeVerification =({goNextStep, formRef, otpValue, given_name, email})=>{
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(['', '', '', '', '', '']);
  const [pasted, setPasted] = useState(null);
  const [changed, setchanged] = useState(null);


  useEffect(() => {
    if (pasted || changed) {
      otpValue(otpValues);
    }
  }, [pasted, changed]);
  // Helper to return value with the right type: 'text' or 'number'
  const getOnlyNumber = useCallback((str) => {
    return !str || /\d/.test(str) ? str : '';
  }, []);

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(6 - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [6]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
    },
    [activeInput, otpValues]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = getOnlyNumber(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }

      changeCodeAtFocus(val);
      setchanged(otpValues);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getOnlyNumber]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
            focusPrevInput();
          } else {
            focusPrevInput();
          }
          break;
        case 'Delete':
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusNextInput();
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          focusPrevInput();
          break;
        case 'ArrowRight':
          e.preventDefault();
          focusNextInput();
          break;
        case ' ':
          e.preventDefault();
          break;
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, 6 - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getOnlyNumber(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setPasted(updatedOTPValues);
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, 6 - 1));
      }
    },
    [activeInput, getOnlyNumber, otpValues]
  );

  return(
<div className={styles.formsbox}>
<div className={styles.intro}>
        <h1
          //className={styles.stepTitle}
        >{`Check your email ${given_name}!`}</h1>
        <p className={styles.stepIntro}>
          {` We’ve sent a 6-digit confirmation code to `}
          <span>{`${email}`}</span>
          {` – It will expire shortly so enter it soon`}
        </p>
      </div>
  <Form
  ref={formRef}
  name="emailVerification"
  className={styles.formWrapper}
  onFinish={goNextStep}
>
  <Row gutter={16} justify="center"  className={styles.codeBoxes}>
    
    <Col span={3}>
      <SingleInput
        focus={activeInput === 0}
        value={otpValues[0]}
        onFocus={handleOnFocus(0)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        
      />
    </Col>
    <Col span={3}>
      <SingleInput
        focus={activeInput === 1}
        value={otpValues[1]}
        onFocus={handleOnFocus(1)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
                
      />
    </Col>
    <Col span={3}>
      <SingleInput
        focus={activeInput === 2}
        value={otpValues[2]}
        onFocus={handleOnFocus(2)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        
      />
    </Col>
    <Col span={2}>
      <div className={styles.hyphen} />
    </Col>
    <Col span={3}>
      <SingleInput
        focus={activeInput === 3}
        value={otpValues[3]}
        onFocus={handleOnFocus(3)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        
      />
    </Col>
    <Col span={3}>
      <SingleInput
        focus={activeInput === 4}
        value={otpValues[4]}
        onFocus={handleOnFocus(4)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        
      />
    </Col>
    <Col span={3}>
      <SingleInput
        focus={activeInput === 5}
        value={otpValues[5]}
        onFocus={handleOnFocus(5)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        
      />
    </Col>
  </Row>
  <Row justify='center' style={{marginTop:'15%'}}>
    <Col span={20}>
  <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
       // onClick={()=>console.log('aqui si ',updatedOTPValues)}
        >
         CONTINUE
        </Button>
      </Form.Item>
  </Col>
  </Row>
</Form>
</div>
  )
}

export default CodeVerification