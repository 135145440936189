import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DescriberModal.scss';
import {
  Input,
  Modal,
  Tabs,
  Checkbox,
  Form,
  Badge,
  message,
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fields } from './data';
import ResultItem from '../../../../components/ResultItem/ResultItem';
import CircularSpinner from '../../../../components/Common/Spinners/CircularSpinner';
import { saveUserRoles } from '../../Register.thunks';

const { TabPane } = Tabs;

const DescriberModal = ({
  setCurrentStep,
  setStatus,
  isLoading,
  skipStep,
  setSectionStep,
}) => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const [isDescriberModalVisible, setIsDescriberModalVisible] = useState(false);
  const [describeValues, setDescribeValues] = useState({});

  function callback(key) {
    console.log(key);
  }

  const onCheckboxChange = (index, checkedValues) => {
    const key = fields[index].category;
    setDescribeValues({ ...describeValues, [key]: checkedValues });
  };

  const onDeleteItemClick = (item) => {
    const filtered = describeValues[item.category].filter(
      (current) => current !== item.description
    );

    setDescribeValues({ ...describeValues, [item.category]: filtered });
  };

  const onFinish = async () => {
    let roles = [];
    Object.keys(describeValues).map((key) =>
      describeValues[key].map((description) => roles.push(description))
    );
    try {
      setStatus('pending');

      await dispatch(saveUserRoles({ roles })).unwrap();

      setSectionStep((prev) => prev + 1);
      setCurrentStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  const generateTabs = (data) => {
    return data.map((element, index) => {
      return (
        <TabPane
          tab={
            <span>
              {element.category}
              <Badge
                count={describeValues[index]?.length}
                className="count-badge"
              />
            </span>
          }
          key={index}
        >
          <Form.Item
            rules={[{ required: true, message: 'Please select at least one!' }]}
            name="none"
          >
            <Checkbox.Group
              options={element.items}
              onChange={(value) => onCheckboxChange(index, value)}
            />
          </Form.Item>
        </TabPane>
      );
    });
  };

  const generateDescriptionsItems = (describeValues) => {
    return Object.keys(describeValues).map((key) =>
      describeValues[key].map((description) => (
        <ResultItem
          key={description}
          item={{ category: key, description }}
          onActionClick={onDeleteItemClick}
        />
      ))
    );
  };
  return (
    <div className="DescriberModal">
      <div className="maxWidth">
        <div className="intro">
          <h1>
            {`${registerData?.givenName}, become a verified entertainment`}
            <br />
            {`industry professional`}
          </h1>
          <p>
            Verifying increases your chances of ranking higher within our
            system. The higher your rank, <br />
            the more relevant your connectivity, collaboration, and funding
            opportunities become.
          </p>
        </div>
      </div>
      <h1 className="label">
        Select the Fields That Best Describes Your Entertainment Career
      </h1>
      <Form
        name="describerModal"
        layout="vertical"
        onFinish={onFinish}
        className="maxWidth"
      >
        <Input
          className="selectionInput"
          addonAfter={<PlusOutlined />}
          placeholder="Click to select all that apply"
          onClick={() => setIsDescriberModalVisible(true)}
        />

        <Modal
          title="Click to select all that apply"
          visible={isDescriberModalVisible}
          className="carreerModal"
          onOk={() => setIsDescriberModalVisible(false)}
          onCancel={() => setIsDescriberModalVisible(false)}
        >
          <Tabs defaultActiveKey="1" onChange={callback}>
            {generateTabs(fields)}
          </Tabs>
        </Modal>

        <Button
          className="skipBtn"
          disabled={isLoading}
          type="primary"
          shape="round"
          onClick={() => skipStep()}
        >
          Skip
        </Button>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <div className="results-wrapper">
        {generateDescriptionsItems(describeValues)}
      </div>
      <span className="spinnerContainer">
        <CircularSpinner isShowing={isLoading} />
      </span>
    </div>
  );
};

export default DescriberModal;
