import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './LandingHeader.module.scss';

const LandingHeader = ({ headerText, text, headerStyle, plainTextStyle }) => {
  const { t } = useTranslation('landing');

  return (
    <div className={`${style.borderLeft}`}>
      <h4 className={`${headerStyle || style.accentText} ${style.paddingLeft}`}>
        {t(headerText)}
      </h4>
      {text ? (
        <h5
          className={`${plainTextStyle || style.plainText} ${
            style.paddingLeft
          }`}
        >
          {t(text)}
        </h5>
      ) : (
        ''
      )}
    </div>
  );
};

export default LandingHeader;
