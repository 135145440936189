import React from "react";
import { Form, Input, Select } from "antd";
import { Tooltip } from "antd";
import style from "./ContentComponents.module.scss";
import { Option } from "antd/lib/mentions";
import { useQuery } from "@apollo/client";
import { GET_LOGGED_USER } from "../../../../graphql/users";

export const PasswordField = () => {
  return (
	<Form name="passwordUpdate" className={style.formWrapper}>
	  {/* <label className={`${style.label} formLabel`} htmlFor="password">
		Current Password
	  </label>
	  <Form.Item
		validateTrigger="onBlur"
		name="password"
		rules={[
		  { required: true, message: "Please input your password!" },
		  {
			min: 8,
			message: "Cannot be less than 8 characters",
		  },
		  {
			message:
			  "Your password must contain lower and uppercase letters, digits, and special characters.",
			pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
		  },
		]}
	  >
		<Input.Password allowClear />
	  </Form.Item> */}
	  <div>
		<Tooltip title="This is the password you log in with. Please be sure it contains at least 6 characters, including lower- and upper-case letters, digits and special characters (such as %,*, &).">
		  <span className={style.passTip}>8 or more characters</span>
		</Tooltip>
	  </div>

	  <label htmlFor="confirmPassword" className="formLabel">
		New Password
	  </label>
	  <Form.Item
		validateTrigger="onBlur"
		name="confirmPassword"
		rules={[
		  {
			required: true,
			message: "Please enter your new password!",
		  },
		  ({ getFieldValue }) => ({
			validator(rule, value) {
			  if (!value || getFieldValue("password") === value) {
				return Promise.resolve();
			  }
			  return Promise.reject(
				"The two passwords that you entered do not match!"
			  );
			},
		  }),
		]}
	  >
		<Input.Password allowClear />
	  </Form.Item>
	  <label htmlFor="confirmPassword" className="formLabel">
		Confirm New Password
	  </label>
	  <Form.Item
		validateTrigger="onBlur"
		name="confirmPassword"
		rules={[
		  {
			required: true,
			message: "Please confirm your password!",
		  },
		  ({ getFieldValue }) => ({
			validator(rule, value) {
			  if (!value || getFieldValue("password") === value) {
				return Promise.resolve();
			  }
			  return Promise.reject(
				"The two passwords that you entered do not match!"
			  );
			},
		  }),
		]}
	  >
		<Input.Password allowClear />
	  </Form.Item>
	</Form>
  );
};

export const EmailField = () => {
	const { loading, error, data } = useQuery(
		GET_LOGGED_USER
	);
	if (loading) {
		console.log("loading");
	 }
	
	if (error) {
		console.log("error: ", error.message);
	}
  return (
	<>
		<label className={`${style.label} formLabel`}>
		Your Current email address
		</label>
		<p>{data.getUser.email}</p>
	  <Form name="emailUpdate" className={style.formWrapper}>
		<label className={`${style.label} formLabel`} htmlFor="email">
			Your new email address
		</label>
		<Form.Item
		  validateTrigger="onBlur"
		  name="email"
		  rules={[
			{ required: true, message: "Please input your password!" },
			{
			  min: 8,
			  message: "Cannot be less than 8 characters",
			},
			{
			  message:
				"Your password must contain lower and uppercase letters, digits, and special characters.",
			  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
			},
		  ]}
		>
		  <Input allowClear />
		</Form.Item>
	  </Form>
	</>
  );
};

export const MobileField = () => {
	const { loading, error, data } = useQuery(
		GET_LOGGED_USER
	);
	if (loading) {
		console.log("loading");
	 }
	
	if (error) {
		console.log("error: ", error.message);
	}
	return (
	  <>
		<label className={`${style.label} formLabel`}>
		  Your Current Mobile number
		</label>
		<p>{data.getUser.phones === null ? 'You currently don’t have a mobile number on file. ' : data.getUser.phones.number}</p>
		<Form name="emailUpdate" className={style.formWrapper}>
		  <label className={`${style.label} formLabel`} htmlFor="email">
		  Your mobile number​
		  </label>
		  <Form.Item
			validateTrigger="onBlur"
			name="mobile"
			rules={[
			  { required: true, message: "Please input your mobile number!" },
			  {
				message:
				  "Your mobile number can only contain digits.",
				pattern: /^(?=.*\d)/,
			  },
			]}
		  >
			<Input allowClear />
		  </Form.Item>
		</Form>
	  </>
	);
  };

  export const LanguageField = () => {
	const { loading, error, data } = useQuery(
		GET_LOGGED_USER
	);
	if (loading) {
		console.log("loading");
	 }
	
	if (error) {
		console.log("error: ", error.message);
	}
	return (
	  <>
		  <label className={`${style.label} formLabel`}>
		  Your Current Language
		  </label>
		  <p>{data.getUser.settings.language === 'en-US' ? 'English': 'Language not recognized'}</p>
		<Form name="emailUpdate" className={style.formWrapper}>
		  <label className={`${style.label} formLabel`} htmlFor="email">
		  I would like to use Linkrease in
		  </label>
		  
			<Select defaultValue="English">
        		<Select.Option value="English">English</Select.Option>
        		<Select.Option value="Spanish">Spanish</Select.Option>
      		</Select>
		  
		</Form>
	  </>
	);
  };