import React from 'react';
import { Row, Col, Button } from 'antd';
import Icon from '@ant-design/icons';
import Styles from './DisplayCategory.module.scss';

// Icons
import { ReactComponent as PlusIcon } from './img/plus.svg';
import { HeatMapOutlined } from '@ant-design/icons';

const DisplayCategory = (props) => {

	return (
		<div className={Styles.displayCategoryContainer}>
      <Row align="middle" justify="space-between">
        <Col>
          <h4 className={Styles.title}>{props.title}</h4>
        </Col>
        <Col>
          <Button
            type="primary"
            shape="circle"
            className={Styles.plusButton}
          >
            <Icon component={PlusIcon} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className={Styles.groupIcon}>
          <HeatMapOutlined /><span className={Styles.green}>&nbsp;&nbsp;●  </span>
        </Col>
        <Col>
          Sample Team 1
        </Col>
        <Col>
          
        </Col>
      </Row>
    </div>
	);
};

export default DisplayCategory;