import React from 'react';
import { Row, Col, Button, Input, Menu, Dropdown } from 'antd';
import Icon from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';

import SearchBar from '../../components/Dashboard/SearchBar/SearchBar';
import LandingFooter from '../../components/Landing/Footer/Footer';
import LoggedInHeader from '../../components/LoggedInHeader/LoggedInHeader';
import styles from './Network.module.scss';
import { ReactComponent as usersIconSVG } from './img/users-icon.svg';
import Connections from '../../components/Connections/Connections';
import PeopleIFollow from '../../components/PeopleIFollow/PeopleIFollow';
import gilberto from './img/gilberto.jpg';
import diegoGarcia from './img/diegoGarcia.PNG';
import joseMartinez from './img/joseMartinez.PNG';
import Invitations from '../../components/Invitations/Invitations';
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a>Recently added</a>
    </Menu.Item>
  </Menu>
);

const { useState } = React;
const Network = (props) => {
  const [email, setemail] = useState('dante.grasso0007@gmail.com');
  const [showRight, setshowRight] = useState('connections');
  return (
    <div className={styles.Network}>
      <header className={styles.header}>
        <LoggedInHeader />
        <SearchBar />
      </header>
      <main>
        <Row>
          <Col span={8}>
            <Row>
              <Col span={12} offset={6}>
                <div className={styles.leftSection}>
                  <div className={styles.leftOptionsSection}>
                    <h3 className={styles.header}>
                      <Icon component={usersIconSVG} /> {''} My Network
                    </h3>
                    {showRight == 'connections' ? (
                      <>
                        <h3
                          className={`${styles.paddingSection} ${styles.sectionClicked}`}
                          onClick={() => setshowRight('connections')}
                        >
                          Connections{' '}
                          <span className={styles.fontBlue}>251</span>
                        </h3>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('peopleIfollow')}
                        >
                          People I Follow
                        </h3>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('invitations')}
                        >
                          Invitations
                          <span className={styles.fontBlue}>36</span>
                        </h3>
                      </>
                    ) : null}
                    {showRight == 'peopleIfollow' ? (
                      <>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('connections')}
                        >
                          Connections{' '}
                          <span className={styles.fontBlue}>251</span>
                        </h3>
                        <h3
                          className={`${styles.paddingSection} ${styles.sectionClicked}`}
                          onClick={() => setshowRight('peopleIfollow')}
                        >
                          People I Follow
                        </h3>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('invitations')}
                        >
                          Invitations
                          <span className={styles.fontBlue}>36</span>
                        </h3>
                      </>
                    ) : null}
                    {showRight == 'invitations' ? (
                      <>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('connections')}
                        >
                          Connections{' '}
                          <span className={styles.fontBlue}>251</span>
                        </h3>
                        <h3
                          className={styles.paddingSection}
                          onClick={() => setshowRight('peopleIfollow')}
                        >
                          People I Follow
                        </h3>
                        <h3
                          className={`${styles.paddingSection} ${styles.sectionClicked}`}
                          onClick={() => setshowRight('invitations')}
                        >
                          Invitations
                          <span className={styles.fontBlue}>36</span>
                        </h3>
                      </>
                    ) : null}
                  </div>
                  <Col span={24}>
                    <hr className={styles.postDivider} />
                  </Col>
                  <h3 className={styles.headerTwo}>Add Contacts</h3>
                  <p>
                    Import your contacts to help you and others connect. You can
                    choose who to invite or connect.{' '}
                    <a className={styles.learnMore} href="/">
                      {''} Learn More
                    </a>
                  </p>
                  <Input
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    className={styles.searchInput}
                    size="large"
                    placeholder="Email"
                  />
                  <div className={styles.divBtn}>
                    <Button
                      ghost
                      type="primary"
                      shape="round"
                      size={'large'}
                      className={styles.Btn}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <div className={styles.rightSection}>
              <div className={styles.rightdiv}>
                <Row className={styles.righInnerdiv}>
                  <Col>
                    {showRight == 'connections' ? (
                      <h3>251 Connections</h3>
                    ) : null}
                    {showRight == 'peopleIfollow' ? (
                      <h3>People I Follow</h3>
                    ) : null}
                    {showRight == 'invitations' ? (
                      <h3>36 Invitations</h3>
                    ) : null}
                    <Row>
                      <pre>Sort by: </pre>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Recently added <DownOutlined />
                        </a>
                      </Dropdown>
                    </Row>
                  </Col>
                  <Input
                    className={styles.searchInputName}
                    size="small"
                    placeholder="Search by name"
                  />
                </Row>
                <Col span={24}>
                  <hr className={styles.postDivider} />
                </Col>
                <Col span={24}>
                  {showRight == 'connections' ? (
                    <div>
                      <Connections
                        picture={gilberto}
                        name={'Gilberto Medina'}
                        job={'Content Acquisition Manager at Netflix'}
                        connected={'today'}
                        rank={1}
                      />
                      <Connections
                        picture={diegoGarcia}
                        name={'Diego Garcia'}
                        job={'Distributor EVP at Linkrease Productions'}
                        connected={'4 days ago'}
                        rank={2}
                      />
                      <Connections
                        picture={joseMartinez}
                        name={'Jose Martinez'}
                        job={'Vice-President of Content EVP at Linkrease'}
                        connected={'4 days ago'}
                        rank={2}
                      />
                    </div>
                  ) : null}
                  {showRight == 'peopleIfollow' ? (
                    <div className={styles.PeopleIFollow}>
                      <PeopleIFollow
                        given_name={'Gilberto'}
                        family_name={'Medina'}
                        posts={'32'}
                        rank={'2'}
                        jobTitle={'Director'}
                        jobCompany={'Linkrease Studios'}
                        profilePic={gilberto}
                      />
                      <PeopleIFollow
                        given_name={'Jose'}
                        family_name={'Martinez'}
                        posts={'21'}
                        rank={'2'}
                        jobTitle={'Vice-President of content'}
                        jobCompany={'Linkrease'}
                        profilePic={joseMartinez}
                      />
                    </div>
                  ) : null}
                  {showRight == 'invitations' ? (
                    <div>
                      <Invitations
                        picture={joseMartinez}
                        name={'Jose Martinez'}
                        job={'Vice-President of Content EVP at Linkrease'}
                        rank={2}
                      />
                    </div>
                  ) : null}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </main>
      <footer
        className={styles.landingFooterContainer}
        style={{ marginTop: '5rem' }}
      >
        <LandingFooter />
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <div style={{ width: '18rem', height: '11rem' }} className="logo" />
          </div>
          <div className={styles.logoContainer}>
            <div>COPYRIGHT © 2020 LINKREASE</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Network;
