import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Background.module.scss';
import {
  Form,
  Select,
  DatePicker,
  Input,
  AutoComplete,
  Checkbox,
  Button,
  message,
} from 'antd';
import { useLazyQuery } from '@apollo/client';
import { setBackground } from '../../Register.slice';
import { saveBackground } from '../../Register.thunks';
import { SEARCH_JOB_TITLE } from '../../../../graphql/jobTitles/index';
import moment from 'moment';

export default function Background({ setCurrentStep, setStatus, isLoading }) {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register?.accountDetails);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  const [value, setValue] = useState('');
  const [getJobs, { loading, error, data }] = useLazyQuery(SEARCH_JOB_TITLE);
  const [options, setOptions] = useState([]);
  const [result, setResult] = useState('');

  const [disabled, setDisabled] = useState(false);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }
  const externalData = localStorage.getItem('linkedinData')
    ? JSON.parse(localStorage.getItem('linkedinData'))
    : '';
  const apiJob = externalData.CurrentJob ? externalData.CurrentJob : '';

  const onSearch = (changeText) => {
    try {
      let res = [];
      if (
        typeof (
          data &&
          data.searchJobTitle &&
          data.searchJobTitle.jobTitles
        ) !== 'undefined'
      ) {
        setResult(
          (
            data &&
            data.searchJobTitle &&
            data.searchJobTitle.jobTitles
          ).substring(
            1,
            (data && data.searchJobTitle && data.searchJobTitle.jobTitles)
              .length - 1
          )
        );
        let nose = result.replace(/"/g, '');
        res = nose.split(',');
      }
      setOptions(!changeText ? [] : res);
    } catch (error) {
      console.log(error);
    }
  };
  const onChange = (changeText) => {
    setValue(changeText);
    const searchJobTitleInput = {
      name: changeText,
    };

    try {
      getJobs({
        variables: { input: searchJobTitleInput },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const currentlyWorking = (changed) => {
    if (disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    console.log(disabled);
  };

  const onFinish = async (values) => {
    const { recentCompany, recentJob } = values;
    try {
      setStatus('pending');

      dispatch(setBackground({ recentCompany, recentJob }));
      await dispatch(saveBackground(values)).unwrap();

      setCurrentStep((prev) => prev + 1);
      setStatus('done');
    } catch (error) {
      setStatus('error');
      message.error('Something went wrong!');
    }
  };

  if (loading) {
    console.log('loading');
  }

  if (error) {
    console.log('error: ', error.message);
  }

  return (
    <div className={styles.Background}>
      <h1 className="stepTitle">
        {registerData?.givenName}, become a verified entertainment industry
        professional
      </h1>
      <p className="stepIntro">
        Verifying increases your chances of ranking higher within our system.
        The higher your rank, the more relevant your connectivity,
        collaboration, and funding opportunities become.
      </p>
      <Form
        name="backgroundForm"
        onFinish={onFinish}
        className={styles.formWrapper}
      >
        <label htmlFor="recentCompany" className="formLabel">
          MOST RECENT COMPANY
        </label>
        <Form.Item
          initialValue={apiJob.Company}
          rules={[
            { required: true, message: 'Please input your recent company!' },
          ]}
          name="recentCompany"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <label htmlFor="recentJob" className="formLabel">
          MOST RECENT JOB
        </label>
        <Form.Item
          initialValue={apiJob.Title}
          rules={[{ required: true, message: 'Please input your recent job!' }]}
          name="recentJob"
        >
          <AutoComplete
            value={value}
            style={{ marginBottom: '1rem', width: '100%', height: '20%' }}
            onSearch={onSearch}
            onChange={onChange}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            placeholder="Job title"
          >
            {options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <label htmlFor="employmentType" className="formLabel">
          Employment type
        </label>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your type of employment!',
            },
          ]}
          name="employmentType"
        >
          <Select
            name="type"
            id="type"
            style={{ marginBottom: '1rem', width: '100%' }}
          >
            <Option value="Full time">Full time</Option>
            <Option value="Part time">Part time</Option>
            <Option value="Self-employed">Self-employed</Option>
            <Option value="Freelance">Freelance</Option>
            <Option value="Contract">Contract</Option>
            <Option value="Internship">Internship</Option>
            <Option value="Apprenticeship">Apprenticeship</Option>
            <Option value="Seasonal">Seasonal</Option>
          </Select>
        </Form.Item>
        <label htmlFor="location" className="formLabel">
          Location
        </label>
        <Form.Item
          rules={[
            { required: true, message: 'Please input your recent location!' },
          ]}
          name="location"
        >
          <Input style={{ marginBottom: '1rem', width: '100%' }}></Input>
        </Form.Item>
        <Form.Item rules={[{ required: false }]} name="currentlyWorking">
          <Checkbox
            onChange={currentlyWorking}
            style={{ marginBottom: '1rem', width: '100%' }}
          >
            I am currently working in this role
          </Checkbox>
        </Form.Item>
        <label htmlFor="dates" className="formLabel">
          Start date / End date
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input the dates!' }]}
          name="dates"
        >
          <RangePicker
            size="large"
            style={{ marginBottom: '1rem', width: '100%' }}
            disabled={[false, disabled]}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <label htmlFor="description" className="formLabel">
          Description
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Please input description!' }]}
          name="description"
        >
          <TextArea rows={5} style={{ marginBottom: '1rem', width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button
            className="continueBtn"
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            shape="round"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
