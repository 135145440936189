import { combineReducers } from 'redux';
import { jobsReducer as jobs } from './containers/Jobs';
import { registerReducer as register } from './containers/Register';
import { sessionReducer as session } from './containers/Session';

const rootReducer = {
  jobs,
  register,
  session,
};

export default combineReducers(rootReducer);
