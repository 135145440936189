import React from 'react'
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

export default function EnterEmail({formRef}) {
  const { t } = useTranslation('logIn');
    return (
      <div>
        <Form labelCol={{ span: 24 }} ref={formRef} name="enterEmail">
          <label className="formLabel">{t('Your Email')}</label>
          <Form.Item rules={[{ required: true, message: 'Please input your email!' }]}  name="email">	
            <Input />
          </Form.Item>
        </Form>
      </div>
    )
}
