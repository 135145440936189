import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Menu, Dropdown } from 'antd';
import style from './Footer.module.scss';
import FooterColumn from '../../FooterColumn/FooterColumn';
import {
  generateCompanyColumn,
  generateBusinessSolutionColumn,
  generateCommunityColumn,
  generatePartnersColumn,
  generateProductColumn,
} from './generateColumns';
//import LRLogo from '../../../img/LRLogoLight.png';

const LandingFooter = () => {
  const { t } = useTranslation('compactFooter');
  const menu = (
    <Menu>
      <Menu.Item>
        <span key="0">English</span>
      </Menu.Item>
      <Menu.Item>
        <span key="1">Español</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={style.container}>
      <Row className={style.innerContainer} justify="center">
        <Col xs={24} sm={8} lg={4}>
          {/* <div className={style.logoColumn}>
            <img src={LRLogo} />
          </div> */}
          <div className={style.language}>
            <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
              <button type="button" className={style.languageButton}>
                {t('language')}
              </button>
            </Dropdown>
          </div>
        </Col>
        <Col className={style.footerColumn} xs={8} sm={8} lg={4}>
          <FooterColumn columnTitle="company" links={generateCompanyColumn()} />
        </Col>
        <Col className={style.footerColumn} xs={8} sm={8} lg={4}>
          <FooterColumn
            columnTitle="community"
            links={generateCommunityColumn()}
          />
        </Col>
        <Col className={style.footerColumn} xs={8} sm={8} lg={4}>
          <FooterColumn
            columnTitle="businessSolutions"
            links={generateBusinessSolutionColumn()}
          />
        </Col>
        <Col className={style.footerColumn} xs={12} sm={8} lg={4}>
          <FooterColumn columnTitle="product" links={generateProductColumn()} />
        </Col>
        <Col className={style.footerColumn} xs={12} sm={8} lg={4}>
          <FooterColumn
            columnTitle="partners"
            links={generatePartnersColumn()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LandingFooter;
