import React from 'react';
import styles from './ResultItem.module.scss';
import closeIcon from '../../img/close-x.svg';

export default function ResultItem({ item, onActionClick }) {
  return (
    <div className={styles.ResultItem}>
      <div className={styles.shape}></div>
      <span>{item.description}</span>
      <img
        onClick={() => onActionClick(item)}
        className={styles.closeIcon}
        src={closeIcon}
        alt=""
      />
    </div>
  );
}
