import React from 'react';
import style from './popUp.module.scss';

function PopUp(props) {
  return props.trigger ? (
    <div className={style.popup}>
      <div className={style.popupInner}>
        <button
          className={style.closeBtn}
          onClick={() => props.setTrigger(false)}
        >
          Got it!
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ''
  );
}

export default PopUp;
