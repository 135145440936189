import { createAsyncThunk } from '@reduxjs/toolkit';
import { SUBMIT_EMAIL_VERIFICATION } from '../../graphql/identity';
import { GET_SIGNED_URL } from '../../graphql/signedUrl';
import {
  CREATE_USER_ADDRESS,
  UPDATE_USER_REASON,
  CREATE_USER_JOB,
  CREATE_USER_EDUCATION,
  CREATE_USER_AWARD,
  UPDATE_USER_ROLES,
  UPDATE_USER,
  UPDATE_USER_PORTAFOLIO,
} from '../../graphql/users';
import i18next from 'i18next';
import client from '../../graphql/client';

export const saveAddress = createAsyncThunk(
  'register/saveAddress',
  async ({ state, country, postalCode }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: CREATE_USER_ADDRESS,
        variables: {
          input: {
            city: state,
            country: country,
            postalCode: postalCode,
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const validateEmailVerificationCode = createAsyncThunk(
  'register/validateEmailVerificationCode',
  async ({ digits, tokens }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: SUBMIT_EMAIL_VERIFICATION,
        variables: {
          input: {
            principal: {
              accessToken: tokens?.accessToken,
              idToken: tokens?.IdToken,
              refreshToken: tokens?.refreshToken,
            },
            code: digits.join(''),
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.submitEmailVerificationCode;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const saveUserJoiningReasons = createAsyncThunk(
  'register/saveUserJoiningReasons',
  async ({ reasons }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_USER_REASON,
        variables: {
          input: {
            joiningReasons: reasons,
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const saveBackground = createAsyncThunk(
  'register/saveBackground',
  async (
    {
      recentCompany,
      recentJob,
      dates,
      currentlyWorking,
      employmentType,
      location,
      description,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: CREATE_USER_JOB,
        variables: {
          input: {
            company: recentCompany,
            startDate: dates[0].format('YYYY-MM-DDThh:mm:ss.sssZ'),
            endDate: currentlyWorking
              ? ''
              : dates[1].format('YYYY-MM-DDThh:mm:ss.sssZ'),
            title: recentJob,
            description: description,
            location: location,
            employment: employmentType,
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.createUserJob;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const saveEducation = createAsyncThunk(
  'register/saveEducation',
  async (
    { recentSchool, fieldOfStudy, degree, educationDates },
    { rejectWithValue }
  ) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: CREATE_USER_EDUCATION,
        variables: {
          input: {
            school: recentSchool,
            fieldOfStudy: fieldOfStudy,
            degree: degree,
            startDate: educationDates[0].format('YYYY-MM-DDThh:mm:ss.sssZ'),
            endDate: educationDates[1].format('YYYY-MM-DDThh:mm:ss.sssZ'),
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const saveAward = createAsyncThunk(
  'register/saveAward',
  async (
    { awardOrganization, award, website, awardDate },
    { rejectWithValue }
  ) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: CREATE_USER_AWARD,
        variables: {
          input: {
            title: awardOrganization,
            description: award,
            website: website,
            date: awardDate.format('YYYY-MM-DDThh:mm:ss.sssZ'),
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const saveUserRoles = createAsyncThunk(
  'register/saveUserRoles',
  async ({ roles }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_USER_ROLES,
        variables: {
          input: {
            roles,
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const getSignedURL = createAsyncThunk(
  'register/getSignedURL',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: GET_SIGNED_URL,
        variables: {
          input: {
            type: 'image',
            mime: 'image/jpeg',
          },
        },
      });

      if (errors?.length) {
        return rejectWithValue('Something went wrong!');
      }

      return data?.getSignedURL;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const updateUserProfileImage = createAsyncThunk(
  'register/updateUserProfileImage',
  async ({ key }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            profileImage: key,
          },
        },
      });

      if (errors?.length) {
        console.log('errors', errors);
        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);

export const savePortfolio = createAsyncThunk(
  'register/savePortfolio',
  async ({ portofolios }, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_USER_PORTAFOLIO,
        variables: {
          input: {
            portofolios,
          },
        },
      });

      if (errors?.length) {
        console.log('errors', errors);

        return rejectWithValue('Something went wrong!');
      }

      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(i18next.t('LOAD_PROFILE_ERROR'));
    }
  }
);
