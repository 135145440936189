import React from 'react';
import style from './LoggedInHeader.module.scss';
import { handleLogout } from '../../utils';
import SignOut from './img/SignOut.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Default from '../../img/no-img.png';

export const JobsModal = () => {
  return (
    <div id="jobsID" className={style.JobsModal}>
      <p className={style.header}>Search Jobs & Casting Calls</p>
      <div className={style.middle}>
        <Link to="/post-job">
          <p>Post a Job</p>
        </Link>
        <p>Post a Casting Call</p>
        <button>Upload Your Resume</button>
      </div>
      <hr />
      <div className={style.footer}>
        <p>Manage Jobs</p>
        <p>Manage Casting Calls</p>
      </div>
    </div>
  );
};
export const ProfileModal = () => {
  const { status, userData } = useSelector((state) => state.session);

  if (status !== 'done') {
    return 'loading';
  }

  const jobTitle = userData?.jobs[0]?.title;
  const jobCompany = userData?.jobs[0]?.company;

  const profilePic = !userData?.profileImage
    ? Default
    : userData?.profileImage?.split(', ');

  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');

  const singOutHandle = (e) => {
    handleLogout();
  };

  return (
    <div id="profileID" className={style.ProfileModal}>
      <div className={style.userInfo}>
        <div className={style.imgsContainer}>
          <img src={smallImage} alt="" />
          <p>
            {userData?.given_name} {userData?.family_name}
          </p>
        </div>
      </div>
      {jobTitle || jobCompany ? (
        <p className={style.header}>
          {jobTitle} &nbsp;
          {jobTitle && jobCompany ? 'at' : ''} &nbsp;
          {jobCompany}
        </p>
      ) : (
        ''
      )}

      <p className={style.header}>
        Rank <div className={style.numberCircle}>1</div>
      </p>
      <div className={style.middle}>
        <Link to={`/profile/${userData?.userId}`}>
          <button>View Profile</button>
        </Link>
        <p className={style.header}>Account</p>
        <p>
          <Link to="/settings">Settings &amp; Privacy</Link>
        </p>
        <p>
          <a>Invoices &amp; Subscriptions</a>
        </p>
        <p>
          <a>Help</a>
        </p>
      </div>
      <hr />
      <div className={style.footer} onClick={singOutHandle}>
        <img src={SignOut}></img>
        <p>Sign Out</p>
      </div>
    </div>
  );
};
