import React from 'react';
import styles from './CookiePolicy.module.scss';
import LandingFooter from '../Landing/Footer/Footer';
import CorpNav from '../CorpNav/CorpNav';
import PrivacyTermsFooter from '../PrivacyTermsFooter/PrivacyTermsFooter';
const CookiePolicy = () => {
  return (
    <div className={styles.div}>
      <CorpNav />
      <div className={styles.banner__headline_container}>
        <div className={styles.banner__position}>
          <h1 className={styles.banner__headline_t_64}>Cookie Policy</h1>
        </div>
      </div>

      <div className={styles.content}>
        <p>
          This cookie policy (&quot;Policy&quot;) describes what cookies are and
          how and they&#039;re being used by the{' '}
          <a target="_blank" rel="nofollow" href="https://www.linkrease.com">
            linkrease.com
          </a>{' '}
          website (&quot;Website&quot; or &quot;Service&quot;) and any of its
          related products and services (collectively, &quot;Services&quot;).
          This Policy is a legally binding agreement between you
          (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and Linkrease
          Technologies, LLC (&quot;Linkrease Technologies, LLC&quot;,
          &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). You should read
          this Policy so you can understand the types of cookies we use, the
          information we collect using cookies and how that information is used.
          It also describes the choices available to you regarding accepting or
          declining the use of cookies. For further information on how we use,
          store and keep your personal data secure, see our{' '}
          <a
            target="_blank"
            rel="nofollow"
            href="https://www.linkrease.com/privacy"
          >
            privacy policy
          </a>
          .
        </p>
        <h2 className={styles.h2}>What are cookies?</h2>
        <p>
          Cookies are small pieces of data stored in text files that are saved
          on your computer or other devices when websites are loaded in a
          browser. They are widely used to remember you and your preferences,
          either for a single visit (through a &quot;session cookie&quot;) or
          for multiple repeat visits (using a &quot;persistent cookie&quot;).
        </p>
        <p>
          Session cookies are temporary cookies that are used during the course
          of your visit to the Website, and they expire when you close the web
          browser.
        </p>
        <p>
          Persistent cookies are used to remember your preferences within our
          Website and remain on your desktop or mobile device even after you
          close your browser or restart your computer. They ensure a consistent
          and efficient experience for you while visiting the Website and
          Services.
        </p>
        <p>
          Cookies may be set by the Website (&quot;first-party cookies&quot;),
          or by third parties, such as those who serve content or provide
          advertising or analytics services on the Website (&quot;third party
          cookies&quot;). These third parties can recognize you when you visit
          our website and also when you visit certain other websites. You may
          learn more about cookies and how they work in this{' '}
          <a
            target="_blank"
            href="https://www.websitepolicies.com/blog/cookies"
          >
            guide
          </a>
          .
        </p>
        <h2 className={styles.h2}>What type of cookies do we use?</h2>
        <h3>Necessary cookies</h3>
        <p>
          Necessary cookies allow us to offer you the best possible experience
          when accessing and navigating through our Website and using its
          features. For example, these cookies let us recognize that you have
          created an account and have logged into that account to access the
          content.
        </p>
        <h3>Functionality cookies</h3>
        <p>
          Functionality cookies let us operate the Website and Services in
          accordance with the choices you make. For example, we will recognize
          your username and remember how you customized the Website and Services
          during future visits.
        </p>
        <h3>Analytical cookies</h3>
        <p>
          These cookies enable us and third party services to collect aggregated
          data for statistical purposes on how our visitors use the Website.
          These cookies do not contain personal information such as names and
          email addresses and are used to help us improve your user experience
          of the Website.
        </p>
        <h3>Advertising cookies</h3>
        <p>
          Advertising cookies allow us and third parties serve relevant ads to
          you more effectively and help us collect aggregated audit data,
          research, and performance reporting for advertisers. They also enable
          us to understand and improve the delivery of ads to you and know when
          certain ads have been shown to you.
        </p>
        <p>
          Your web browser may request advertisements directly from ad network
          servers, these networks can view, edit, or set their own cookies, just
          as if you had requested a web page from their website.
        </p>
        <p>
          Although we do not use cookies to create a profile of your browsing
          behavior on third party websites, we do use aggregate data from third
          parties to show you relevant, interest-based advertising. We do not
          provide any personal information that we collect to advertisers.
        </p>
        <h3>Social media cookies</h3>
        <p>
          Third party cookies from social media sites (such as Facebook,
          Twitter, etc) let us track social network users when they visit or use
          the Website and Services, or share content, by using a tagging
          mechanism provided by those social networks.
        </p>
        <p>
          These cookies are also used for event tracking and remarketing
          purposes. Any data collected with these tags will be used in
          accordance with our and social networks’ privacy policies. We will not
          collect or share any personally identifiable information from the
          user.
        </p>
        <h2 className={styles.h2}>Do we use web beacons or tracking pixels?</h2>
        <p>
          Our emails may contain a &quot;web beacon&quot; (or &quot;tracking
          pixel&quot;) to tell us whether our emails are opened and verify any
          clicks through to links or advertisements within the email.
        </p>
        <p>
          We may use this information for purposes including determining which
          of our emails are more interesting to users and to query whether users
          who do not open our emails wish to continue receiving them.
        </p>
        <p>
          The pixel will be deleted when you delete the email. If you do not
          wish the pixel to be downloaded to your device, you should read the
          email in plain text view or with images disabled.
        </p>
        <h2 className={styles.h2}>What are your cookie options?</h2>
        <p>
          If you don't like the idea of cookies or certain types of cookies, you
          can change your browser's settings to delete cookies that have already
          been set and to not accept new cookies. Visit{' '}
          <a
            target="_blank"
            href="https://www.internetcookies.com"
            rel="noopener"
          >
            internetcookies.com
          </a>{' '}
          to learn more about how to do this.
        </p>
        <p>
          Please note, however, that if you delete cookies or do not accept
          them, you might not be able to use all of the features the Website and
          Services offer.
        </p>
        <h2 className={styles.h2}>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Policy or its terms relating to
          the Website and Services at any time, effective upon posting of an
          updated version of this Policy on the Website. When we do, we will
          send you an email to notify you. Continued use of the Website and
          Services after any such changes shall constitute your consent to such
          changes.
        </p>
        <h2 className={styles.h2}>Acceptance of this policy</h2>
        <p>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services.
        </p>
        <h2 className={styles.h2}>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to our use of
          cookies, you may send an email to
          s&#117;p&#112;&#111;&#114;t&#64;li&#110;kr&#101;&#97;&#115;e&#46;&#99;&#111;&#109;.
        </p>
        <p>This document was last updated on June 2, 2021</p>
      </div>
      <div className={styles.landingFooterContainer}>
        <LandingFooter />
        <PrivacyTermsFooter />
      </div>
    </div>
  );
};

export default CookiePolicy;
