import React from 'react';
import RegisterHeader from './RegisterHeader/RegisterHeader';
import CompactFooter from '../../../../components/PrivacyTermsFooter/PrivacyTermsFooter';
import Register from '../../Register';

const RegisterOuterWrapper = () => (
  <div className="outerWrapper">
    <RegisterHeader />
    <Register />
    <CompactFooter />
  </div>
);

export default RegisterOuterWrapper;
