import React from 'react'
import styles from '../PostJob.module.scss'
import { Form, Input, Checkbox, AutoComplete, Col, Row, Button } from 'antd'
//import { IS_USER_EMAIL_TAKEN } from '../../../graphql/users';

const Account =({goNextStep, formRef})=>{

  const onFinish=()=>{
   
  }

  // const { data, loading, error, refetch } = useQuery(IS_USER_EMAIL_TAKEN, {
  //   variables: { input: IsUserEmailTakenInput },
  //   ssr: true,
  // });

  const verifyIfEmailIsTaken = async (value) => {
    return true
    // setIsUserEmailTakenInput({
    //   email: value,
    // });
    // try {
    //   console.log(IsUserEmailTakenInput);
    //   refetch({
    //     variables: { input: IsUserEmailTakenInput },
    //   });
    //   return data;
    // } catch (error) {
    //   console.log(error.message);
    // }ue;
  };

    return(
    <div className={styles.formsbox}>
    <Row justify='center'>
    <Col
     span={20}
    // offset={4}
    >    
        <Row justify='center'>
        
        <h2 className={styles.title}>Great, let's set up your free employeer account</h2>
          
        </Row>
    <Row justify='center'>
    <Col
    span={20}
    >
    
    <Form
      name="employeer"
      initialValues={{ remember: true }}
      layout='vertical'
      ref={formRef}
      onFinish={goNextStep}
    
    >
      <Form.Item
        label="Email Address"
        name="email"
        rules={[{ required: true, message: 'Please input a valid email' },
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        ({ getFieldValue }) => ({
          async validator(rule, value) {
            let isEmailTaken = null;
            isEmailTaken = await verifyIfEmailIsTaken(value);

            if (isEmailTaken) {
              return Promise.resolve();
            }
            return Promise.reject('The email is already taken!');
          },
        })]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' },
        {
          min: 8,
          message: 'Cannot be less than 8 characters',
        },
        {
          message:
            'Your password must contain lower and uppercase letters, digits, and special characters.',
          pattern:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        },]}
      >
        <Input.Password/>
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirm"
        rules={[{ required: true, message: 'Please input your password!' },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              'The two passwords that you entered do not match!'
            );
          },
        })
      ]}
      >
        <Input.Password/>
      </Form.Item>

    
      <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
         >
         CONTINUE
        </Button>
      </Form.Item>
    </Form>
    </Col>
    </Row>
    </Col>
    </Row>

       
    </div>
    )
}

export default Account