import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import './SquareElementsWithTitle.scss';

const SquareElementsWithTitle = (Props) => (
  <div className="containerElement squaredContent">
    {Props.children ? (
      <div className="squaredElement">{Props.children}</div>
    ) : (
      ''
    )}
    <div className="squaredElement">{Props.text}</div>
    <div className="checkedElement">
      <CheckCircleFilled className="checkedIconElement" />
    </div>
  </div>
);

export default SquareElementsWithTitle;
