import React, {useState} from "react";
import styles from "../PostJobDetails.module.scss"
import ResultItem from "../../../components/ResultItem/ResultItem"
import { fields } from './data';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Col, Row, Button, Tabs, Badge, Checkbox, AutoComplete } from "antd";

const typeJob=[{value:'Full-time'},
                {value:'Part-time'},
                {value:'Contract'},
                {value:'Temporary'},
                {value:'Volunter'},
                {value:'Intership'},
              ]
const verticalOpt=[
                {value:'FILM'},
                {value:'SPORTS'},
                {value:'MUSIC'},
                {value:'GAMING'},
                {value:'ALL ENTERTAIMENT VERTICALS'},
              ]
const PstJobPage1 = ({goNextPage}) => {

  const [skillsModalVisible, setSkillsModalVisible ] = useState(false);
  const [skillValues, setSkillValues] = useState({});
  const { TabPane } = Tabs;
  const formValues = localStorage.getItem("formInfo")
    ? JSON.parse(localStorage.getItem("formInfo"))
    : "";
  const {company, jobtitle, country, vertical} = formValues;

  const onCheckboxChange = (index, checkedValues) => {
    const key = fields[index].category;
    setSkillValues({ ...skillValues, [key]: checkedValues });
  };

  function callback(key) {
    console.log(key);
  }
  
  const onDeleteItemClick = (item) => {
    const filtered =skillValues[item.category].filter(
      (current) => current !== item.description
    );
    setSkillValues({ ...skillValues, [item.category]: filtered });
  }
  const generateSkillItems = (skillValues) => {
    return Object.keys(skillValues).map((key) =>
      skillValues[key].map((description) => (
        <ResultItem
          key={description}
          item={{ category: key, description }}
          onActionClick={onDeleteItemClick}
        />
      ))
    );
  };


  const generateTabs = (data) => {
    return data.map((element, index) => {
      return (
        <TabPane
          tab={
            <span>
              {element.category}
              <Badge
                count={skillValues[index]?.length}
                className="count-badge"
              />
            </span>
          }
          key={index}
        >
          <Checkbox.Group
            options={element.items}
            onChange={(value) => onCheckboxChange(index, value)}
          />
        </TabPane>
      );
    });
  };

  return (
    
      <Form layout={"vertical"}>
        <Row>
        <Col
        span={10}>
          <label htmlFor='company' className={styles.pageTitles}>
            Company
          </label>
          <Form.Item  name="company" initialValue={company}
          className={styles.pageTitles}>
            <Input />
          </Form.Item>

          <label htmlFor='job' className={styles.pageTitles}>
            Job Title
          </label>
          <Form.Item  name="job" initialValue={jobtitle}>
            <Input />
          </Form.Item>

          <label htmlFor='vertical' className={styles.pageTitles}>
          Candidate primary entertaiment vertical
          </label>
          <Form.Item
            name="vertical"
            initialValue={vertical}
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <AutoComplete
        options={verticalOpt}>
        </AutoComplete>
          </Form.Item>
          </Col>

          <Col
          span={10}
          offset={1}>
            <label htmlFor='location' className={styles.pageTitles} initialValue={country}>
            Location
          </label>
          <Form.Item
           
            name="location"
            initialValue={country}
          >
            <Input />
          </Form.Item>

          <label htmlFor='type' className={styles.pageTitles}>
          Employment Type?
          </label>
          <Form.Item
           
            name="type"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <AutoComplete
            options={typeJob}>
              </AutoComplete>
            {/* <Input /> */}
          </Form.Item>

          <label htmlFor='rank' className={styles.pageTitles}>
          Rank Target(optional)
          </label>
          <Form.Item
            
            name="rank"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          </Col>
        </Row>

        <Row
        gutter={[0,64]} >
          <Col
          span={21}>

          <label htmlFor='description' className={styles.pageTitles}>
          Job Description
          </label>
        <Form.Item
          
          name="description"
         
        >
          <Input.TextArea 
          autoSize={{minRows:3,maxRows:8}}
          />
        </Form.Item>
        <div
        className={styles.box}
       >
          <label htmlFor='skillSelection' className={styles.pageTitles}>
          Skills
          </label>
          <div className="results-wrapper">
        {generateSkillItems(skillValues)}
      </div>
        <Form.Item
        name='skillSelection'>
        <Input
          className="selectionInput"
          addonAfter={<PlusOutlined />}
          placeholder="Select a desire skill"
          onClick={() => setSkillsModalVisible(true)}
        />
        <Modal
          title="Select a set of skills"
          visible={skillsModalVisible}
          onOk={() => setSkillsModalVisible(false)}
          onCancel={() => setSkillsModalVisible(false)}
        >
           <Tabs defaultActiveKey="1" onChange={callback}>
            {generateTabs(fields)}
          </Tabs> 
        </Modal>
          </Form.Item>
        </div>
        </Col>
        <Col
        offset={15}
        span={5}>
        <Form.Item>
        <Button type="primary" shape="round" size="large" htmlType="submit" style={{width:'100%'}}
        onClick={()=>goNextPage()}
        >
         CONTINUE
        </Button>
      </Form.Item>
      </Col>      
        </Row>
      </Form>


      
    
  );
};

export default PstJobPage1;
