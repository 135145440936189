import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../img/LRLogoDark.png';
import { useTranslation } from 'react-i18next';
import styles from './ResetPassword.module.scss';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import CircularSpinner from '../../components/Common/Spinners/CircularSpinner';

import EnterEmail from './EnterEmail/EnterEmail';
import EnterVerification from './EnterVerification/EnterVerification';

import { useMutation } from '@apollo/client';
import { RESET_PASSWORD, CONFIRM_RESET_PASSWORD } from '../../graphql/identity';

const stepsText = [
  {
    title: 'Forgot Your Password?',
    desc:
      'No problem, we are on it! Just enter the email address you use to sign in to Linkrease.',
  },
  {
    title: 'Check Your Email',
    desc:
      'We have sent a 6-digit confirmation code to <strong>Test@email.com</strong>',
  },
];

const ResetPassword = () => {
  const { t } = useTranslation('logIn');
  const history = useHistory();
  const formRef = useRef(null);
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [submitError, setSubmitError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);
  const [confirmResetPasswordMutation] = useMutation(CONFIRM_RESET_PASSWORD);

  const steps = [
    <EnterEmail formRef={formRef} />,
    <EnterVerification formRef={formRef} />,
  ];

  const sendResetCode = async (values) => {
    try {
      const result = await resetPasswordMutation({
        variables: {
          input: {
            email: values.email,
          },
        },
      });
      console.log(result)

      setStep(step + 1);
    } catch (error) {
      console.error(error.message);
      setSubmitError(error.message);
    }
  };

  const submitNewPassword = async (values) => {
    setIsLoading(true);

    try {
      const result = await confirmResetPasswordMutation({
        variables: {
          input: {
            code: values.code,
            password: values.password,
            email: formValues.email,
          },
        },
      });
      console.log(result)
      message.success('Your password was successfully updated!');
      setIsLoading(false);
      history.push('/login');
    } catch (error) {
      setIsLoading(false);
      setSubmitError(error.message);
    }
  };

  const goNextStep = (step) => {
    const stepForm = formRef.current;
    const { __INTERNAL__ } = stepForm;

    let errors = [];
    stepForm.submit();

    setTimeout(() => {
      errors = stepForm.getFieldsError();
      errors = getFlattenErrors(errors);

      if (errors.length <= 0) {
        console.log(stepForm.getFieldsValue());
        setStepValues(stepForm.getFieldsValue());

        if (__INTERNAL__.name === 'enterEmail') {
          return sendResetCode(stepForm.getFieldsValue());
        }
        if (__INTERNAL__.name === 'enterVerification') {
          return submitNewPassword(stepForm.getFieldsValue());
        }
        setStep(step + 1);
      }
    }, 500);
  };

  const getFlattenErrors = (errors) => {
    const result = errors.map((item) => {
      return item.errors;
    });
    let flattenErrors = result.flat();
    return flattenErrors
  };

  const setStepValues = (values) => {
    setFormValues({ ...formValues, ...values });
  };

  return (
    <div className={styles.ResetPassword}>
      <div className={styles.whiteBox}>
        <div className={styles.withPadding}>
          <div className={styles.heading}>
            <img src={logo} alt="Linkerease Logo" className={styles.logo} />
            <h1>{stepsText[step].title}</h1>
            <p>{stepsText[step].desc}</p>
          </div>
          {submitError && (
            <span className={styles.submitError}>{submitError}</span>
          )}

          <hr className={styles.divider} />

          {steps[step]}

          <Button
            type="primary"
            onClick={() => goNextStep(step)}
            shape="round"
            className={`${styles.button} continueBtn`}
          >
            {t('Continue')}
          </Button>
        </div>

        <div className={styles.footer}>
          <p>
            New to Linkrease? <Link to="/register-vertical">Join for free</Link>
          </p>
        </div>
      </div>
      <span className={styles.spinnerContainer}>
        <CircularSpinner isShowing={isLoading} />
      </span>
    </div>
  );
};

export default ResetPassword;
