export const uploadFileS3 = (presignedPostData, file)=>{
  const formData = new FormData();
  Object.keys(presignedPostData.fields).forEach(key => {
    formData.append(key, presignedPostData.fields[key]);
   
  });
  formData.append("file", file);
  const xhr = new XMLHttpRequest();
  xhr.open("POST", presignedPostData.url, true);
  xhr.send(formData);
  
  console.log("formdata: ",formData)
  console.log("file: ", file)
  
}