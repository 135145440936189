import React, { useState } from 'react';
import { Col, Button, Input, message } from 'antd';
import Default from '../../../../img/no-img.png';
import { Link } from 'react-router-dom';
// Styles
import Styles from '../CreatePanel.module.scss';

// default profile image
import defaultProfile from '../img/default-profile.png';
import { useQuery, useMutation } from '@apollo/client';
import { GET_LOGGED_USER } from '../../../../graphql/users';
import { CREATE_USER_POST } from '../../../../graphql/posts';
const { TextArea } = Input;

const PostContentPanel = (props) => {
  const [userPostMutation] = useMutation(CREATE_USER_POST);
  const { loading, error, data } = useQuery(GET_LOGGED_USER);
  const [postContent, setPostContent] = useState('');
  const [postVisibility, setPostVisibility] = useState(2);
  const [postMedia, setPostMedia] = useState({});
  const [postListOfUsers, setPostListOfUsers] = useState({});
  const profilePic =
    data?.getUser?.profileImage == null
      ? Default
      : data?.getUser?.profileImage?.split(', ');
  const smallImage =
    profilePic === Default ? profilePic : profilePic[0]?.replace('[', ' ');

  const createPost = async () => {
    try {
      const result = await userPostMutation({
        variables: {
          input: {
            content: postContent,
            visibility: postVisibility,
          },
        },
      });
      console.log('result:', result);
      setPostContent('');
      message.success('Your Post Was Successfully Shared!');
      window.location.reload(false);
    } catch (error) {
      console.log(error.message);
      message.error('Unsuccessfully Shared!');
    }
  };

  if (loading) {
    console.log('loading');
  }

  if (error) {
    console.log('error: ', error.message);
  }
  return (
    <>
      <Col span={24}>
        <div className={Styles.imgContainer}>
          <img src={smallImage} alt="user profile" />
        </div>
        <TextArea
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          placeholder="Share ideas recommendations and links with your contacts..."
          maxLength={420}
          rows={50}
          showCount
        />
      </Col>
      <Col offset={21} span={3}>
        <Button block type="primary" shape="round" onClick={() => createPost()}>
          <Link to="/dashboard">SHARE</Link>
        </Button>
      </Col>
    </>
  );
};

export default PostContentPanel;
